import { styled } from '@mui/material/styles';
import ClassNames from 'classnames';

const PREFIX = 'CommonButtonIconText';

const classes = {
  root: `${PREFIX}-root`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    '&.MuiButton-contained': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      backgroundColor: 'rgb(0, 86, 178)',
    },
  },
});

const CommonButtonIconText = props => {


  const { text, variant, style, className, on_click, module_type } = props;

  return (
    (<Root>
      <span
        data-cy={props['data-cy']}
        onClick={() => on_click && module_type && on_click(module_type)}
        className={ClassNames([classes.root, className])}
        variant={variant}
        style={style}>
        {text}
      </span>
    </Root>)
  );
};

export default CommonButtonIconText;
