import { useMediaQuery } from '@mui/material';

const useMaterialMediaQuery = () => {
  const small = useMediaQuery('(max-width: 766px)');
  const medium = useMediaQuery('(min-width: 768px) and (max-width: 991px)');
  const chart = useMediaQuery('(min-width: 993px) and (max-width: 1152px)');
  const large = useMediaQuery('(min-width: 992px)');

  return [small, medium, large, chart];
};

export default useMaterialMediaQuery;
