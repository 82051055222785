import Section from '../Section';
import { styled } from '@mui/material/styles';

import {
  Grid2 as Grid,
  Chip,
  InputAdornment,
  Typography,
  TextField,
  Box,
  Button,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useEffect, useRef } from 'react';
const PREFIX = 'LocalityFilter';

const classes = {
  chipItem: `${PREFIX}-chipItem`,
  searchIcon: `${PREFIX}-searchIcon`,
  scrollContainer: `${PREFIX}-scrollContainer`,
  searchBox: `${PREFIX}-searchBox`
};

const StyledSection = styled(Section)((
  {
    theme
  }
) => ({
  [`& .${classes.chipItem}`]: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '0.3rem',
    textTransform: 'capitalize',
  },

  [`& .${classes.searchIcon}`]: {
    marginLeft: '-7px',
  },

  [`& .${classes.scrollContainer}`]: {
    padding: '0.3rem 0',
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '0.2rem',
  },

  [`& .${classes.searchBox}`]: {
    backgroundColor: '#fff',
  }
}));

function LocalityFilter(props) {
  const { rootClass, data = [], onDelete, onSearchClick } = props;

  const apiRef = useRef();
  useEffect(() => {
    const lastItem = data.slice(-1)[0];
    const itemId = `${lastItem?.name}-${lastItem?.type}-${lastItem?.id}`;
    apiRef.current?.scrollToItem?.(
      apiRef.current?.getItemElementById(itemId),
      'smooth'
    );
  }, []);
  return (
    (<StyledSection title="Locality" variant="secondary" rootClass={rootClass}>
      <Grid container spacing={1}>
        {/* Search Field */}
        <Grid size={12} >
          <TextField
            sx={{
              marginTop: '0px',
              '& .MuiOutlinedInput-root': {
                fontFamily: 'Open Sans, sans-serif!important',
                height: '42px',
                '& fieldset': {
                  padding: '8px 14px!important',
                },
              },
            }}
            fullWidth
            variant="outlined"
            margin="dense"
            placeholder="Search Locality, Property, Builder"
            onChange
            onClick={e =>
              onSearchClick &&
              typeof onSearchClick === 'function' &&
              onSearchClick(e)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Horizontal Scrollable Chips */}
        {data.length > 0 && (
          <Grid size={12}>
            <Box display="flex" alignItems="center">
              {/* Left Scroll Button */}
              <Button
                style={{
                  display: 'none',
                }}
                onClick={() => scroll(-200)}
                aria-label="Scroll left"
                className={classes.scrollButton}
              >
                <ChevronLeft />
              </Button>

              {/* Scrollable Container */}
              <Box
                ref={apiRef}
                sx={{
                  display: 'flex',
                  overflowX: 'auto',
                  scrollbarWidth: 'none', // Hide scrollbar for Firefox
                  '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome/Safari
                  scrollBehavior: 'smooth',
                  padding: '4px 0',
                }}
                className={classes.scrollContainer}
              >
                {data.map((locality) => (
                  <Chip
                    data-item-id={`${locality.name}-${locality.type}-${locality.id}`}
                    key={`${locality.name}-${locality.id}`}
                    className={classes.chipItem}
                    label={
                      <Typography variant="body2" noWrap>
                        {locality.name}
                      </Typography>
                    }
                    deleteIcon={<ClearIcon />}
                    onDelete={() => {
                      onDelete && typeof onDelete === 'function' && onDelete(locality);
                    }}
                    sx={{ marginRight: 1 }}
                  />
                ))}
              </Box>

              {/* Right Scroll Button */}
              <Button
                style={{
                  display: 'none',
                }}
                onClick={() => scroll(200)}
                aria-label="Scroll right"
                className={classes.scrollButton}
              >
                <ChevronRight />
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </StyledSection>)
  );
}

export default LocalityFilter;
