import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
const PREFIX = 'useForm';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    '& .MuiFormControl-root': {
      width: '80%',
      margin: theme.spacing(1),
    },
  }
}));

export function useForm(initialFValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const [checked, setChecked] = React.useState(false);
  const handleInputChange = e => {
    e.preventDefault();
    setChecked(e.target.checked);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
    checked,
  };
}

export function Form(props) {

  const { ...other } = props;
  return (
    (<Root className={classes.root} autoComplete="off" {...other}>
      {props.children}
    </Root>)
  );
}
