import Modal from 'react-modal';
import CancelIcon from '@mui/icons-material/Cancel';

const CommonModal = props => {
  const {
    modal_top,
    modal_left,
    modal_width,
    modalContent,
    handleClose,
    open,
  } = props;

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        contentLabel="Modal"
        ariaHideApp={false}
        width="50%"
        style={{
          content: {
            top: modal_top,
            left: modal_left,
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            zIndex: '1025',
            outline: 0,
            transition: 'opacity .15s linear',
            width: modal_width,
            minWidth: modal_width,
            overflow: 'hidden',
            backgroundColor: '#fff',
            border: '1px solid #0056B233',
            borderRadius: '20px',
            padding: '0',
            height: 'fit-content',
          },
        }}>
        <span onClick={handleClose} className="closeIcon">
          <CancelIcon />
        </span>
        {modalContent}
      </Modal>
    </>
  );
};

export default CommonModal;
