import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
// import { makeStyles } from '@mui/styles';
import ImageGallery from 'react-image-gallery';
import classes from './pd_gallery.module.scss';

// const useStyles = makeStyles({
//   root: {},
//   body: {
//     marginTop: '0px !Important',
//   },

//   // to-check
//   // heightbox: {
//   //   height: '514px!important',
//   // },
//   projectImageGallery: {
//     '& .image-gallery-slides': {
//       '& img': {
//         minHeight: '100vh',
//       },
//       // height: "513px",
//       // width:"855px",
//       // margin:"0px auto"
//     },
//     '& .image-gallery-icon': {
//       bottom: '30px !important',
//     },
//   },
//   tabRoot: {
//     '&.MuiTab-root': {
//       minWidth: 'fit-content !important',
//       margin: '0 10px',
//       textTransform: 'capitalize',
//       paddingTop: '10px',
//     },
//   },
// });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PropertyDetailsGallery = props => {
  const {
    gallery_key,
    street_view,
    video_url,
    project_images,
    amenities,
    sample_flat_images,
    unit_floor_plans,
    project_name,
    open,
    handleClose,
    startIndex,
  } = props;
  const [value, setValue] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(0);
  // const classes = useStyles();
  const left = <polyline points="15 18 9 12 15 6" />;
  const right = <polyline points="9 18 15 12 9 6" />;
  const maximize = (
    <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
  );
  const minimize = (
    <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
  );

  const iconMapper = {
    left,
    right,
    maximize,
    minimize,
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let galleryKey = gallery_key;
    if (galleryKey == 'PROJECT_IMAGES_VIEW') {
      project_images && project_images.length > 0
        ? setValue(0)
        : (galleryKey = 'ANY');
    }

    if ((galleryKey == 'STREET_VIEW' || galleryKey == 'ANY') && street_view) {
      setValue(1);
    } else if (
      (galleryKey == 'VIDEO_VIEW' || galleryKey == 'ANY') &&
      video_url
    ) {
      setValue(2);
    } else if (
      (galleryKey == 'AMENITIES_VIEW' || galleryKey == 'ANY') &&
      amenities &&
      amenities.length > 0
    ) {
      setValue(3);
    } else if (
      (galleryKey == 'SAMPLE_FLAT_IMAGE_VIEW' || galleryKey == 'ANY') &&
      sample_flat_images &&
      sample_flat_images.length > 0
    ) {
      setValue(4);
    } else if (
      (galleryKey == 'UNIT_FLOOR_PLANS_VIEW' || galleryKey == 'ANY') &&
      unit_floor_plans &&
      unit_floor_plans.length > 0
    ) {
      setValue(5);
    } else {
      setValue(0);
    }
  }, [
    gallery_key,
    amenities,
    project_images,
    sample_flat_images,
    street_view,
    unit_floor_plans,
    video_url,
  ]);
  const goTonextTab = (currentIndexTab, projectLength, newvalue) => {
    if (currentIndexTab == projectLength) {
      setValue(newvalue);
      setcurrentIndex(0);
    }
  };
  const goToprevTab = (currentIndexTab, projectLength, newvalue) => {
    if (currentIndexTab == 0) {
      setValue(newvalue);
      setcurrentIndex(0);
    }
  };
  const custombuttontogonextPrevTab = newvalue => {
    setValue(newvalue);
    setcurrentIndex(0);
  };
  return (
    <>
      <Modal className="tabs" open={open} onClose={handleClose}>
        <Box className="viewmodal">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  className={classes.tabRoot}
                  label="Project Images"
                  {...a11yProps(0)}
                  style={{
                    display:
                      project_images && project_images.length > 0
                        ? 'block'
                        : 'none',
                  }}
                />
                <Tab
                  className={classes.tabRoot}
                  label="Street View"
                  {...a11yProps(1)}
                  style={{
                    display: street_view ? 'block' : 'none',
                    padding: street_view ? '12px 16px' : '0px'
                  }}
                />

                <Tab
                  className={classes.tabRoot}
                  label="Video Walkthrough"
                  {...a11yProps(2)}
                  style={{ display: video_url ? 'block' : 'none' }}
                />

                <Tab
                  className={classes.tabRoot}
                  label="Amenities"
                  {...a11yProps(3)}
                  style={{
                    display:
                      amenities && amenities.length > 0 ? 'block' : 'none',
                  }}
                />

                <Tab
                  className={classes.tabRoot}
                  label="Sample Flat Image"
                  {...a11yProps(4)}
                  style={{
                    display:
                      sample_flat_images && sample_flat_images.length > 0
                        ? 'block'
                        : 'none',
                  }}
                />
                <Tab
                  className={classes.tabRoot}
                  label="Unit Floor Plans"
                  {...a11yProps(5)}
                  style={{
                    display:
                      unit_floor_plans && unit_floor_plans.length > 0
                        ? 'block'
                        : 'none',
                  }}
                />
              </Tabs>
            </Box>

            {project_images && project_images.length > 0 && (
              <TabPanel value={value} index={0}>
                <div className={classes.projectImageGallery}>
                  <ImageGallery
                    items={project_images}
                    showPlayButton={false}
                    showFullscreenButton={true}
                    showNav={true}
                    showBullets={true}
                    showThumbnails={false}
                    showIndex={true}
                    lazyLoad={true}
                    originalAlt={project_name}
                    originalTitle={project_name}
                    onSlide={Index => setcurrentIndex(Index)}
                    renderFullscreenButton={(onClick, isFullscreen) => {
                      return (
                        <button
                          type="button"
                          className="image-gallery-icon image-gallery-fullscreen-button"
                          onClick={onClick}
                          aria-label="Open Fullscreen">
                          <svg
                            className="image-gallery-svg"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox={'0 0 24 24'}
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {iconMapper[isFullscreen ? 'minimize' : 'maximize']}
                          </svg>
                        </button>
                      );
                    }}
                    renderRightNav={(onClick, disabled) => (
                      <button
                        type="button"
                        className="image-gallery-icon image-gallery-right-nav"
                        disabled={disabled}
                        onClick={() => {
                          goTonextTab(
                            currentIndex,
                            project_images.length - 1,
                            street_view
                              ? 1
                              : video_url
                              ? 2
                              : amenities && amenities.length > 0
                              ? 3
                              : sample_flat_images &&
                                sample_flat_images.length > 0
                              ? 4
                              : unit_floor_plans && unit_floor_plans.length > 0
                              ? 5
                              : 0
                          );
                          onClick();
                        }}
                        aria-label="Next Slide">
                        <svg
                          className="image-gallery-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={'6 0 12 24'}
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          {iconMapper['right']}
                        </svg>
                      </button>
                    )}
                    renderLeftNav={(onClick, disabled) => (
                      <button
                        type="button"
                        className="image-gallery-icon image-gallery-left-nav"
                        disabled={disabled}
                        aria-label="Previous Slide"
                        onClick={() => {
                          goToprevTab(
                            currentIndex,
                            project_images.length,
                            unit_floor_plans && unit_floor_plans.length > 0
                              ? 5
                              : sample_flat_images &&
                                sample_flat_images.length > 0
                              ? 4
                              : amenities && amenities.length > 0
                              ? 3
                              : video_url
                              ? 2
                              : street_view
                              ? 1
                              : 0
                          );
                        }}>
                        <svg
                          className="image-gallery-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={'6 0 12 24'}
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          {iconMapper['left']}
                        </svg>
                      </button>
                    )}
                  />
                  {project_images.length == 1 ? (
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-right-nav"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          street_view
                            ? 1
                            : video_url
                            ? 2
                            : amenities && amenities.length > 0
                            ? 3
                            : sample_flat_images &&
                              sample_flat_images.length > 0
                            ? 4
                            : unit_floor_plans && unit_floor_plans.length > 0
                            ? 5
                            : 0
                        );
                      }}
                      aria-label="Next Slide">
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['right']}
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                  {project_images.length == 1 ? (
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-left-nav"
                      aria-label="Previous Slide"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          unit_floor_plans && unit_floor_plans.length > 0
                            ? 5
                            : sample_flat_images &&
                              sample_flat_images.length > 0
                            ? 4
                            : amenities && amenities.length > 0
                            ? 3
                            : video_url
                            ? 2
                            : street_view
                            ? 1
                            : 0
                        );
                      }}>
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['left']}
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </TabPanel>
            )}
            {street_view && (
              <TabPanel value={value} index={1}>
                <Box className={classes.body}>
                  <div className="streetheight">
                    <iframe
                      src={street_view}
                      width="100%"
                      allowFullScreen={true}
                    />
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-right-nav"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          video_url
                            ? 2
                            : amenities && amenities.length > 0
                            ? 3
                            : sample_flat_images &&
                              sample_flat_images.length > 0
                            ? 4
                            : unit_floor_plans && unit_floor_plans.length > 0
                            ? 5
                            : project_images && project_images.length > 0
                            ? 0
                            : 1
                        );
                      }}
                      aria-label="Next Slide">
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['right']}
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-left-nav"
                      aria-label="Previous Slide"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          project_images && project_images.length > 0
                            ? 0
                            : unit_floor_plans && unit_floor_plans.length > 0
                            ? 5
                            : sample_flat_images &&
                              sample_flat_images.length > 0
                            ? 4
                            : amenities && amenities.length > 0
                            ? 3
                            : video_url
                            ? 2
                            : 1
                        );
                      }}>
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['left']}
                      </svg>
                    </button>
                  </div>
                </Box>
              </TabPanel>
            )}
            {video_url && (
              <TabPanel value={value} index={2}>
                <div className="videoheight">
                  <video width="100%" controls>
                    <source src={video_url} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                  <button
                    type="button"
                    className="image-gallery-icon image-gallery-right-nav"
                    onClick={() => {
                      custombuttontogonextPrevTab(
                        amenities && amenities.length > 0
                          ? 3
                          : sample_flat_images && sample_flat_images.length > 0
                          ? 4
                          : unit_floor_plans && unit_floor_plans.length > 0
                          ? 5
                          : project_images && project_images.length > 0
                          ? 0
                          : street_view
                          ? 1
                          : 2
                      );
                    }}
                    aria-label="Next Slide">
                    <svg
                      className="image-gallery-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={'6 0 12 24'}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      {iconMapper['right']}
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="image-gallery-icon image-gallery-left-nav"
                    // onClick={onClick}
                    aria-label="Previous Slide"
                    onClick={() => {
                      custombuttontogonextPrevTab(
                        street_view
                          ? 1
                          : project_images && project_images.length > 0
                          ? 0
                          : unit_floor_plans && unit_floor_plans.length > 0
                          ? 5
                          : sample_flat_images && sample_flat_images.length > 0
                          ? 4
                          : amenities && amenities.length > 0
                          ? 3
                          : 2
                      );
                    }}>
                    <svg
                      className="image-gallery-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={'6 0 12 24'}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      {iconMapper['left']}
                    </svg>
                  </button>
                </div>
              </TabPanel>
            )}

            {amenities && amenities.length > 0 && (
              <TabPanel value={value} index={3}>
                <div className={classes.projectImageGallery}>
                  <ImageGallery
                    items={amenities}
                    showPlayButton={false}
                    showFullscreenButton={true}
                    showNav={true}
                    showBullets={true}
                    showThumbnails={false}
                    showIndex={true}
                    lazyLoad={true}
                    alt={project_name}
                    title={project_name}
                    onSlide={Index => setcurrentIndex(Index)}
                    renderRightNav={(onClick, disabled) => (
                      <button
                        type="button"
                        className="image-gallery-icon image-gallery-right-nav"
                        disabled={disabled}
                        onClick={() => {
                          goTonextTab(
                            currentIndex,
                            amenities.length - 1,
                            sample_flat_images && sample_flat_images.length > 0
                              ? 4
                              : unit_floor_plans && unit_floor_plans.length > 0
                              ? 5
                              : project_images && project_images.length > 0
                              ? 0
                              : street_view
                              ? 1
                              : video_url
                              ? 2
                              : 3
                          );
                          onClick();
                        }}
                        aria-label="Next Slide">
                        <svg
                          className="image-gallery-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={'6 0 12 24'}
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          {iconMapper['right']}
                        </svg>
                      </button>
                    )}
                    renderLeftNav={(onClick, disabled) => (
                      <button
                        type="button"
                        className="image-gallery-icon image-gallery-left-nav"
                        disabled={disabled}
                        aria-label="Previous Slide"
                        onClick={() => {
                          goToprevTab(
                            currentIndex,
                            amenities.length + 1,
                            video_url
                              ? 2
                              : street_view
                              ? 1
                              : project_images && project_images.length > 0
                              ? 0
                              : unit_floor_plans && unit_floor_plans.length > 0
                              ? 5
                              : sample_flat_images &&
                                sample_flat_images.length > 0
                              ? 4
                              : 3
                          );
                          onClick();
                        }}>
                        <svg
                          className="image-gallery-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={'6 0 12 24'}
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          {iconMapper['left']}
                        </svg>
                      </button>
                    )}
                  />
                  {amenities.length == 1 ? (
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-right-nav"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          sample_flat_images && sample_flat_images.length > 0
                            ? 4
                            : unit_floor_plans && unit_floor_plans.length > 0
                            ? 5
                            : project_images && project_images.length > 0
                            ? 0
                            : street_view
                            ? 1
                            : video_url
                            ? 2
                            : 3
                        );
                      }}
                      aria-label="Next Slide">
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['right']}
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                  {amenities.length == 1 ? (
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-left-nav"
                      // onClick={onClick}
                      aria-label="Previous Slide"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          video_url
                            ? 2
                            : street_view
                            ? 1
                            : project_images && project_images.length > 0
                            ? 0
                            : unit_floor_plans && unit_floor_plans.length > 0
                            ? 5
                            : sample_flat_images &&
                              sample_flat_images.length > 0
                            ? 4
                            : 3
                        );
                      }}>
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['left']}
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </TabPanel>
            )}
            {sample_flat_images && sample_flat_images.length > 0 && (
              <TabPanel value={value} index={4}>
                <div className={classes.projectImageGallery}>
                  <ImageGallery
                    items={sample_flat_images}
                    showPlayButton={false}
                    showFullscreenButton={true}
                    showNav={true}
                    showBullets={true}
                    showThumbnails={false}
                    showIndex={true}
                    lazyLoad={true}
                    alt={project_name}
                    title={project_name}
                    onSlide={Index => setcurrentIndex(Index)}
                    renderRightNav={(onClick, disabled) => (
                      <button
                        type="button"
                        className="image-gallery-icon image-gallery-right-nav"
                        disabled={disabled}
                        onClick={() => {
                          goTonextTab(
                            currentIndex,
                            sample_flat_images.length - 1,
                            unit_floor_plans && unit_floor_plans.length > 0
                              ? 5
                              : project_images && project_images.length > 0
                              ? 0
                              : street_view
                              ? 1
                              : video_url
                              ? 2
                              : amenities && amenities.length > 0
                              ? 3
                              : 4
                          );
                          onClick();
                        }}
                        aria-label="Next Slide">
                        <svg
                          className="image-gallery-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={'6 0 12 24'}
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          {iconMapper['right']}
                        </svg>
                      </button>
                    )}
                    renderLeftNav={(onClick, disabled) => (
                      <button
                        type="button"
                        className="image-gallery-icon image-gallery-left-nav"
                        disabled={disabled}
                        aria-label="Previous Slide"
                        onClick={() => {
                          goToprevTab(
                            currentIndex,
                            sample_flat_images.length + 1,
                            amenities && amenities.length > 0
                              ? 3
                              : video_url
                              ? 2
                              : street_view
                              ? 1
                              : project_images && project_images.length > 0
                              ? 0
                              : unit_floor_plans && unit_floor_plans.length > 0
                              ? 5
                              : 4
                          );
                          onClick();
                        }}>
                        <svg
                          className="image-gallery-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={'6 0 12 24'}
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          {iconMapper['left']}
                        </svg>
                      </button>
                    )}
                  />

                  {sample_flat_images.length == 1 ? (
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-right-nav"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          unit_floor_plans && unit_floor_plans.length > 0
                            ? 5
                            : project_images && project_images.length > 0
                            ? 0
                            : street_view
                            ? 1
                            : video_url
                            ? 2
                            : amenities && amenities.length > 0
                            ? 3
                            : 4
                        );
                      }}
                      aria-label="Next Slide">
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['right']}
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                  {sample_flat_images.length == 1 ? (
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-left-nav"
                      // onClick={onClick}s
                      aria-label="Previous Slide"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          amenities && amenities.length > 0
                            ? 3
                            : video_url
                            ? 2
                            : street_view
                            ? 1
                            : project_images && project_images.length > 0
                            ? 0
                            : unit_floor_plans && unit_floor_plans.length > 0
                            ? 5
                            : 4
                        );
                      }}>
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['left']}
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </TabPanel>
            )}
            {unit_floor_plans && unit_floor_plans.length > 0 && (
              <TabPanel value={value} index={5}>
                <div className="projectImageGalleryFloor">
                  <ImageGallery
                    style={{
                      marginTop: '-40px',
                    }}
                    startIndex={startIndex || 0}
                    items={unit_floor_plans}
                    showPlayButton={false}
                    showFullscreenButton={true}
                    showNav={true}
                    showBullets={true}
                    showThumbnails={false}
                    showIndex={true}
                    lazyLoad={true}
                    alt={project_name}
                    title={project_name}
                    onSlide={Index => setcurrentIndex(Index)}
                    renderRightNav={(onClick, disabled) => (
                      <button
                        type="button"
                        className="image-gallery-icon image-gallery-right-nav"
                        disabled={disabled}
                        onClick={() => {
                          goTonextTab(
                            currentIndex,
                            unit_floor_plans.length - 1,
                            project_images && project_images.length > 0
                              ? 0
                              : street_view
                              ? 1
                              : video_url
                              ? 2
                              : amenities && amenities.length > 0
                              ? 3
                              : sample_flat_images &&
                                sample_flat_images.length > 0
                              ? 4
                              : 5
                          );
                          onClick();
                        }}
                        aria-label="Next Slide">
                        <svg
                          className="image-gallery-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={'6 0 12 24'}
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          {iconMapper['right']}
                        </svg>
                      </button>
                    )}
                    renderLeftNav={(onClick, disabled) => (
                      <button
                        type="button"
                        className="image-gallery-icon image-gallery-left-nav"
                        disabled={disabled}
                        aria-label="Previous Slide"
                        onClick={() => {
                          goToprevTab(
                            currentIndex,
                            unit_floor_plans.length + 1,
                            sample_flat_images && sample_flat_images.length > 0
                              ? 4
                              : amenities && amenities.length > 0
                              ? 3
                              : video_url
                              ? 2
                              : street_view
                              ? 1
                              : project_images && project_images.length > 0
                              ? 0
                              : 5
                          );
                          onClick();
                        }}>
                        <svg
                          className="image-gallery-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox={'6 0 12 24'}
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round">
                          {iconMapper['left']}
                        </svg>
                      </button>
                    )}
                  />
                  {unit_floor_plans.length == 1 ? (
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-right-nav"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          project_images && project_images.length > 0
                            ? 0
                            : street_view
                            ? 1
                            : video_url
                            ? 2
                            : amenities && amenities.length > 0
                            ? 3
                            : sample_flat_images &&
                              sample_flat_images.length > 0
                            ? 4
                            : 5
                        );
                      }}
                      aria-label="Next Slide">
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['right']}
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                  {unit_floor_plans.length == 1 ? (
                    <button
                      type="button"
                      className="image-gallery-icon image-gallery-left-nav"
                      // onClick={onClick}
                      aria-label="Previous Slide"
                      onClick={() => {
                        custombuttontogonextPrevTab(
                          sample_flat_images && sample_flat_images.length > 0
                            ? 4
                            : amenities && amenities.length > 0
                            ? 3
                            : video_url
                            ? 2
                            : street_view
                            ? 1
                            : project_images && project_images.length > 0
                            ? 0
                            : 5
                        );
                      }}>
                      <svg
                        className="image-gallery-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={'6 0 12 24'}
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {iconMapper['left']}
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </TabPanel>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PropertyDetailsGallery;
