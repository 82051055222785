import Section from '../Section';
import ChipGroup from '../ChipGroup';

function SaleTypeFilter(props) {
  const { rootClass, loading, defaultSelected, data, onChange } = props;
  const itemMap = new Map();
  data.forEach(({ label, value }) => itemMap.set(label, value));

  return (
    <Section title="Sales Type" rootClass={rootClass} variant="borderB">
      {loading && <p>Loading...</p>}
      {!loading && (
        <ChipGroup
          items={[...itemMap.keys()]}
          variant="radio"
          defaultSelection={
            data.find(item => item.value === defaultSelected)?.label
          }
          onChange={selected =>
            onChange &&
            typeof onChange === 'function' &&
            onChange(itemMap.get(selected))
          }
        />
      )}
    </Section>
  );
}

export default SaleTypeFilter;
