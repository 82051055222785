import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
const PREFIX = 'TopBar';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  changeBtn: `${PREFIX}-changeBtn`,
  closeIcon: `${PREFIX}-closeIcon`
};

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.root}`]: {
    borderBottom: '1px solid #D2DEEB',
    '--Paper-shadow': 'none!important', // Custom CSS property
    boxShadow: 'var(--Paper-shadow)', // Apply the custom property
  },

  [`& .${classes.title}`]: {
    fontSize: 12,
  },

  [`& .${classes.toolbar}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.changeBtn}`]: {
    textTransform: 'capitalize',
    padding: 0,
    fontSize: 11,
    fontWeight: 'bold',
    marginLeft: 'auto',
  },

  [`& .${classes.closeIcon}`]: {
    padding: '0 0.35rem 0 1rem',
  }
}));

function TopBar({ currentCity, onChangeClick, onClose, closeIcon }) {

  const handleClose = () => {
    if (onClose) onClose();
  };
  const handleChange = () => {
    if (onChangeClick) onChangeClick();
  };
  return (
    (<StyledAppBar className={classes.root} position="sticky" color="default">
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.closeIcon}
          edge="start"
          color="inherit"
          disableRipple={true}
          onClick={handleClose}
          aria-label="menu">
          {closeIcon ?? <CloseIcon />}
        </IconButton>
        <Typography className={classes.title} variant="body2">
          You are currently searching in{' '}
          <b style={{ textTransform: 'capitalize' }}>{currentCity ?? ''}</b>
        </Typography>
        <Button
          color="primary"
          disableRipple={true}
          className={classes.changeBtn}
          onClick={handleChange}>
          Change
        </Button>
      </Toolbar>
    </StyledAppBar>)
  );
}

export default TopBar;
