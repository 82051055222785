import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import ClassNames from 'classnames';

const PREFIX = 'HBTextField';

const classes = {
  root: `${PREFIX}-root`,
  noLeftBorder: `${PREFIX}-noLeftBorder`,
  noRightBorderRadius: `${PREFIX}-noRightBorderRadius`,
  textInput: `${PREFIX}-textInput`,
  textInput1: `${PREFIX}-textInput1`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    '.Mui-focused': {
      backgroundColor: '#000',
    },

    input: {
      '& :selected': {
        backgroundColor: '#fff!important',
      },
    },
  },

  [`& .${classes.noLeftBorder}`]: {
    '& .MuiTextField-root': {
    // '& .MuiFilledInput-inputMarginDense': {
      borderBottomLeftRadius: '0px !important',
      borderTopLeftRadius: '0px !important',
    },
  },
  [`& .${classes.noRightBorderRadius}`]: {
    '& .MuiTextField-root': {
    // '& .MuiFilledInput-inputMarginDense': {
      borderBottomRightRadius: '0px !important',
      borderTopRightRadius: '0px !important',
      borderRight: "none!important",
    },
  },
  [`& .${classes.textInput}`]: {

    margin: '0 0 8px 0',
    backgroundColor: '#fff',
    '& .Mui-disabled': {
      WebkitTextFillColor: '#131313',
    },

    '& .MuiFormLabel-root': {
      fontFamily: 'Open Sans, sans-serif!important',
    },

    '& .MuiInputLabel-filled': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#333333',
      top: '5px',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: '#fff',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#333333',
      top: '5px',
    },
    '& .MuiTextField-root': {
    // '& .MuiFilledInput-inputMarginDense': {
      backgroundColor: '#fff',
      border: '1px solid #99A2AC',
      borderRadius: '7px',
      paddingTop: '6px',
      paddingBottom: '10px',
    },
    '& .MuiInputBase-root': {
      fontSize: '18px',
      fontWeight: '700',
      color: '#171717',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none !important',
    },
    '& .MuiInputLabel-shrink': {
      fontWeight: '400!important',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
  },

  [`& .${classes.textInput1}`]: {
    margin: '0 0 8px 0',
    backgroundColor: '#fff',

    '& .MuiFormLabel-root': {
      fontFamily: 'Open Sans, sans-serif!important',
    },

    '& .MuiInputLabel-filled': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#333333',
      top: '5px',
    },

    '& .MuiInputLabel-filled::placeholder': {
      color: '#171717 !important',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: '#fff',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#333333',
      top: '5px',
    },
    '& .MuiTextField-root': {
    // '& .MuiFilledInput-inputMarginDense': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #D3E0ED',
      paddingBottom: '10px',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      paddingLeft: '0px',
      fontSize: '16px',
      fontWeight: '700',
    },

    '& .MuiFilledInput-inputMarginDense::placeholder': {
      fontSize: '16px',
      color: '#171717 !important',
    },
    '& .MuiInputBase-root': {
      fontSize: '18px',
      fontWeight: '700',
      color: '#171717',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: '0px',
      fontSize: '13px',
    },
    '& .Mui-disabled': {
      WebkitTextFillColor: '#131313',
    },
  }
}));

export default function HBTextField(props) {

  const {
    name,
    id,
    label,
    isDisabled,
    varient,
    className,
    size,
    value,
    onChange,
    error,
    placeholder,
    helperText,
    style,
    noLeftBorder,
    noRightBorderRadius,
    ...otherParams
  } = props;
  return (
    (<Root>
      <div className={ClassNames([classes.textInput,noLeftBorder ? classes.noLeftBorder : className, noRightBorderRadius && classes.noRightBorderRadius])}>
        <TextField
          name={name}
          type="text"
          id={id}
          label={label}
          disabled={isDisabled}
          variant={varient || 'outlined'}
          sx={style}
          value={value}
          onChange={onChange}
          error={error}
          helperText={error}
          placeholder={placeholder}
          size={size || 'small'}
          autoComplete="on"
          {...otherParams}
        />
      </div>
    </Root>)
  );
}
