import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Chip, Grid2 as Grid, Typography } from '@mui/material';
import Section from '../Section';
import HBImage from '../../../packages/hbimage';

import BottomBar from '../BottomBar';
const PREFIX = 'PopularCitiesFilter';

const classes = {
  root: `${PREFIX}-root`,
  cityChip: `${PREFIX}-cityChip`,
  bottomAction: `${PREFIX}-bottomAction`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    marginTop: '0.5rem',
  },

  [`& .${classes.cityChip}`]: {
    minWidth: '100%',
    borderRadius: '0.3rem',
    height: 'auto',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },

  [`& .${classes.bottomAction}`]: {
    margin: '0 auto',
    textTransform: 'none',
  }
}));

function PopularCitiesFilter(props) {
  const { rootClass, cities, defaultCitySelected, onNextClick } = props;

  const [selectedCity, setSelectedCity] = useState(() => {
    return cities.find(({ id }) => id === defaultCitySelected);
  });

  return (
    (<Root style={{
      height: '100%'
    }}>
      <Section
        title="Popular cities"
        rootClass={rootClass}
        childRootStyle={{
          width: '100%',
        }}
        icon={
          <HBImage
            src={'/static/images/search-filter/city.svg'}
            alt="city icon"
            width={24}
            height={24}
          />
        }>
        <Grid className={classes.root} container spacing={3}>
          {cities.map(city => (
            <Grid key={city.id} size={6}>
              <Chip
                label={<Typography variant="body2">{city.name}</Typography>}
                className={classes.cityChip}
                variant={
                  selectedCity && selectedCity.id === city.id
                    ? 'default'
                    : 'outlined'
                }
                color={
                  selectedCity && selectedCity.id === city.id
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  setSelectedCity(city);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Section>
      <BottomBar>
        <Button
          className={classes.bottomAction}
          variant="contained"
          color="primary"
          disableElevation={true}
          onClick={() =>
            onNextClick &&
            typeof onNextClick === 'function' &&
            onNextClick(selectedCity)
          }>
          Next
        </Button>
      </BottomBar>
    </Root>)
  );
}

export default PopularCitiesFilter;
