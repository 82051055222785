import { AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
const PREFIX = 'BottomBar';

const classes = {
  root: `${PREFIX}-root`
};

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.root}`]: {
    bottom: 0,
    top: 'auto',
    backgroundColor: '#F8F8FF',
    padding: '0.4rem 0',
    borderTop: '1px solid #D2DEEB',
  }
}));

function BottomBar(props) {
  const { position = 'sticky' } = props;

  return (
    (<StyledAppBar className={classes.root} position={position} color="default">
      <Toolbar>{props.children}</Toolbar>
    </StyledAppBar>)
  );
}

export default BottomBar;
