import { React, useState } from 'react';
import { styled } from '@mui/material/styles';
import ClassNames from 'classnames';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import CommonHeaderText from '../../../packages/common-header-text';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ForgotPasswordViaEmail from './ForgotPasswordViaEmail';
import ForgePasswordViaPhn from './ForgotPasswordViaPhn';
import Variables from 'styles/variables.module.scss';

const PREFIX = 'ForgotPassword';

const classes = {
  root: `${PREFIX}-root`,
  textInput: `${PREFIX}-textInput`,
  formSubmit: `${PREFIX}-formSubmit`,
  formSignupText: `${PREFIX}-formSignupText`,
  tabSwitch: `${PREFIX}-tabSwitch`,
  tabRoot: `${PREFIX}-tabRoot`,
  countrySelect: `${PREFIX}-countrySelect`,
  phoneInput: `${PREFIX}-phoneInput`,
  socialMuiButton: `${PREFIX}-socialMuiButton`,
  loginFormpd: `${PREFIX}-loginFormpd`,
  specialText: `${PREFIX}-specialText`,
  specialText1: `${PREFIX}-specialText1`,
  loadingButtonWrapper: `${PREFIX}-loadingButtonWrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  lineContainer: `${PREFIX}-lineContainer`,
  horizontalLine: `${PREFIX}-horizontalLine`,
  lineText: `${PREFIX}-lineText`,
  errorMessage: `${PREFIX}-errorMessage`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    background: '#fff',
    fontFamily: 'Open Sans, sans-serif',
    borderRadius: '10px',
    '& .Mui-checked': {
      color: '#707070 !important',
    },
  },

  [`& .${classes.textInput}`]: {
    margin: '0 0 16px 0',
    display: 'block',
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      height: '42px',
      color: Variables.darkColor,
    },
    '& .MuiOutlinedInput-input': {
      padding: '20px',
    },
    '& .MuiInputLabel-formControl': {
      top: '-5px',
    },
  },

  [`& .${classes.formSubmit}`]: {
    width: '100%',
    fontSize: '16px',
    backgroundColor: Variables.primaryColor,
    color: '#fff',
    textTransform: 'capitalize',
    // transition: 'all 0.3s ease-in-out',
    margin: '32px 0 20px 0',
    borderRadius: '7px',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: Variables.primaryColor,
      color: '#FFFFFF',
    },
  },

  [`& .${classes.formSignupText}`]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: '11px',
  },

  [`& .${classes.tabSwitch}`]: {
    border: '1px solid #999999',
    borderRadius: '30px',
    height: '45px',
    margin: '0 0 10px 0',
  },

  [`& .${classes.tabRoot}`]: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#EE6A28',
      color: '#fff',
      boxShadow: 'none',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    '& .MuiButtonBase-root': {
      border: '30px',
      backgroundColor: '#fff',
    },
  },

  [`& .${classes.countrySelect}`]: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px 0 0 10px',
    },
  },

  [`& .${classes.phoneInput}`]: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0 10px 10px 0',
    },
  },

  [`& .${classes.socialMuiButton}`]: {
    '&.MuiButton-contained': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      boxShadow: '0px 6px 20px #0056b240 !important',
    },
  },

  [`& .${classes.loginFormpd}`]: {
    padding: '32px 0 0 0',
  },

  [`& .${classes.specialText}`]: {
    cursor: 'pointer',
    color: '#001AC0',
  },

  [`& .${classes.specialText1}`]: {
    cursor: 'pointer',
    color: Variables.primaryColor,
  },

  [`& .${classes.loadingButtonWrapper}`]: {
    position: 'relative',
  },

  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -4,
    marginLeft: -12,
  },

  [`& .${classes.lineContainer}`]: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: '1rem 0',
  },

  [`& .${classes.horizontalLine}`]: {
    height: '1px',
    width: '100%',
    backgroundColor: '#D9D9D9',
    flexGrow: 1,
    flexShrink: 1,
  },

  [`& .${classes.lineText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    fontWeight: '700',
    padding: '0 1rem',
  },

  [`& .${classes.errorMessage}`]: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontFamily: 'sans-serif',
    marginLeft: '14px',
  }
}));

const ForgotPassword = props => {
  const {
    root_style,
    root_class,
    forgotPassViaMob,
    showResetPassword,
    setToken,
    closePopup,
  } = props;


  const [resetPasswordBy, setResetPasswordBy] = useState('Mobile');
  const [show_forgetPassword, setShowForgetPassword] = useState('');

  const handlechange = event => {
    setResetPasswordBy(event.target.value);
  };

  const closeModal = () => {
    setShowForgetPassword(false);
  };

  let passwordResetData;
  if (resetPasswordBy === 'Email') {
    passwordResetData = (
      <ForgotPasswordViaEmail
        onChange={() => closeModal()}
        closePopup={closePopup}
      />
    );
  } else {
    passwordResetData = (
      <ForgePasswordViaPhn
        showReset={showResetPassword}
        onChange={closeModal}
        setToken={setToken}
        hideBackBtn={forgotPassViaMob}
      />
    );
  }

  return (
    (<Root>
      <div
        className={ClassNames([classes.root, root_class])}
        style={root_style}>
        {show_forgetPassword || forgotPassViaMob ? (
          passwordResetData
        ) : (
          <Grid container style={{ padding: '52px' }}>
            <Grid size={12}>
              <CommonHeaderText
                text={`Forgot Password`}
                variant="h5"
                style={{
                  color: Variables.darkColor,
                  fontSize: '28px',
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: '400',
                  textAlign: 'left',
                }}
              />
              <CommonHeaderText
                text={<span>Reset password using Mobile or Email</span>}
                variant="h5"
                style={{
                  paddingTop: '12px',
                  color: Variables.darkColor,
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                  textAlign: 'left',
                }}
              />
              <form className={classes.loginFormpd}>
                <div
                  className={classes.textInput}
                  style={{ marginBottom: '0' }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={resetPasswordBy}
                      onChange={handlechange}>
                      <FormControlLabel
                        value="Mobile"
                        control={<Radio style={{ padding: '9px' }} />}
                        label="Mobile"
                      />
                      <FormControlLabel
                        value="Email"
                        control={<Radio style={{ padding: '9px' }} />}
                        label="Email"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div
                  className={classes.loadingButtonWrapper}
                  style={{
                    marginTop: '16px',
                  }}>
                  <Button
                    className={classes.formSubmit}
                    variant="contained"
                    onClick={() => setShowForgetPassword(true)}>
                    Submit
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        )}
      </div>
    </Root>)
  );
};

export default ForgotPassword;
