import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import style from './styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import HorizontalScroll from '../../packages/horizontal-scroll';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';
import static_footer_links_best_locality from '../../../data/static_footer_links_best_locality';
import React, { useRef, useState } from 'react';

export default function FooterPopulerLinks() {
  const [windowSize] = useState(7);
  const [place, setplace] = useState(0);
  const componentRef = useRef();
  const loadPrevProjects = () => {
    setplace(prev => prev - 1);
    componentRef.current.handleArrowClick();
  };
  const loadNextProjects = () => {
    setplace(prev => prev + 1);
    componentRef.current.handleArrowClickRight();
  };

  return (<>
    <Grid container alignItems="center">
      <Grid
        size={{
          xs: 12,
          sm: 9,
          md: 9,
          lg: 9
        }}>
        <Box sx={style.footerMainTitle}>
          <h4>Popular Localities in India</h4>
        </Box>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 3,
          md: 3,
          lg: 3
        }}>
        <div
          style={{
            paddingLeft: '15px',
            marginRight: '8px',
          }}>
          <HorizontalScrollButtons
            loadPrevProjects={loadPrevProjects}
            loadNextProjects={loadNextProjects}
            disableNext={
              static_footer_links_best_locality.length < place * windowSize
            }
            disablePrev={place === 0}
          />
        </div>
      </Grid>
    </Grid>
    <Grid container>
      <Grid size={12}>
        <HorizontalScroll
          itemClass="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-3 MuiGrid-grid-lg-3"
          componentRef={componentRef}
          data={
            static_footer_links_best_locality &&
            static_footer_links_best_locality.map((data, index) => {
              const {
                footerLocalityMainName,
                footerLocalityLinkPanvel,
                footerLocalityLinkPowai,
                footerLocalityLinkKharghar,
                footerLocalityLinkBorivali,
                footerLocalityLinkMulund,
                propertyinPanvel,
                propertyinPowai,
                propertyinKharghar,
                propertyinBorivali,
                propertyinMulund,
              } = data;
              return (
                (<Grid
                  key={index}
                  sx={style.item}
                  size={{
                    xs: 4,
                    sm: 4,
                    md: 4
                  }}>
                  <Box sx={style.footerSubTitle}>
                    <h5>{footerLocalityMainName}</h5>
                    <List sx={style.footerLinks}>
                      <ListItem>
                        <a
                          href={footerLocalityLinkPanvel}
                          target="_blank"
                          rel="noreferrer">
                          {propertyinPanvel}
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href={footerLocalityLinkPowai}
                          target="_blank"
                          rel="noreferrer">
                          {propertyinPowai}
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href={footerLocalityLinkKharghar}
                          target="_blank"
                          rel="noreferrer">
                          {propertyinKharghar}
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href={footerLocalityLinkBorivali}
                          target="_blank"
                          rel="noreferrer">
                          {propertyinBorivali}
                        </a>
                      </ListItem>
                      <ListItem>
                        <a
                          href={footerLocalityLinkMulund}
                          target="_blank"
                          rel="noreferrer">
                          {propertyinMulund}
                        </a>
                      </ListItem>
                    </List>
                  </Box>
                </Grid>)
              );
            })
          }
        />
        {/* <Grid item sx={style.item} xs={4} sm={4} md={4}>
                      <Box sx={style.footerSubTitle}>
                          <h5>Best Localities in Lonavala</h5>
                          <List sx={style.footerLinks}>
                          <ListItem>
                              <a
                              href="/buy/properties-in-kunegaon-lonavala"
                              target="_blank">
                              Property in Kunegaon
                              </a>
                          </ListItem>
                          <ListItem>
                              <a
                              href="/buy/properties-in-old-khandala-lonavala"
                              target="_blank">
                              Property in Old Khandala
                              </a>
                          </ListItem>
                          <ListItem>
                              <a href="/buy/properties-in-maval-lonavala" target="_blank">
                              Property in Maval
                              </a>
                          </ListItem>

                          <ListItem>
                              <a
                              href="/buy/properties-in-varsoli-lonavala"
                              target="_blank">
                              Property in Varsoli
                              </a>
                          </ListItem>
                          <ListItem>
                              <a
                              href="/buy/properties-in-waghranwadi-lonavala"
                              target="_blank">
                              Property in Waghranwadi
                              </a>
                          </ListItem>
                          </List>
                      </Box>
                  </Grid> */}
      </Grid>
    </Grid>
    <Divider sx={style.horizontalLine} />
  </>);
}
