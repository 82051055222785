import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  root: {
    position: 'absolute!important',
    left: '0!important',
    right: '0!important',
    top: '0!important',
    maxHeight: '100vh!important',
    overflowX: 'hidden!important',
    overflowY: 'auto!important',
    height: '100%!important',
    border: '0px!important',
    borderRadius: '0px!important',
    transform: 'translate(0%, 0%)!important',
    width: '100%!important',
  },
  bottomAction: {
    margin: '0 auto',
    textTransform: 'none',
    width: 'auto !important',
    borderRadius: '5px!important',
  },
  popularCitiesRoot: {
    minHeight: '100%',
    position: 'initial',
    backgroundColor: '#fff!important',
  },
  popularPartsRoot: {
    height: '100%',
    // height: 'calc(100vh - 112px)',
  },
  bottomFooter: {
    '& .MuiButtonBase-root': {
      maxWidth: '220px',
      height: '38px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '12px',
      marginBottom: '12px',
    },
  },
}));
