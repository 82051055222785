import Section from '../Section';
import ChipGroup from '../ChipGroup';

function FacilitiesFilter(props) {
  const { rootClass, loading, data, defaultSelected, onChange } = props;
  const itemMap = new Map();
  data.forEach(({ label, value }) => itemMap.set(label, value));

  return (
    <Section title="Facilities" rootClass={rootClass} variant="borderB">
      {loading && <p>Loading...</p>}
      {!loading && (
        <ChipGroup
          items={[...itemMap.keys()]}
          defaultSelection={defaultSelected.map(
            value => data.find(i => i.value === value)?.label
          )}
          onChange={selected =>
            onChange &&
            typeof onChange === 'function' &&
            onChange(selected.map(s => itemMap.get(s)))
          }
        />
      )}
    </Section>
  );
}

export default FacilitiesFilter;
