import React from 'react';
import { styled } from '@mui/material/styles';
import ClassNames from 'classnames';
import Grid from '@mui/material/Grid2';
import Skeleton from 'react-loading-skeleton';

const PREFIX = 'FallbackCard';

const classes = {
  root: `${PREFIX}-root`,
  cardTop: `${PREFIX}-cardTop`,
  cardTopIcons: `${PREFIX}-cardTopIcons`,
  cardPrice: `${PREFIX}-cardPrice`,
  cardIndex: `${PREFIX}-cardIndex`,
  cardIndexMain: `${PREFIX}-cardIndexMain`,
  scheduleTourButton: `${PREFIX}-scheduleTourButton`,
  viewDetailsButton: `${PREFIX}-viewDetailsButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    borderRadius: '20px',
    display: 'block',
    position: 'relative',
    border: '1px solid rgba(232, 232, 232, 0.7)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      border: '1px solid rgb(53 94 150 / 18%)',
    },
  },

  [`& .${classes.cardTop}`]: {
    position: 'relative',
    '& img': {
      borderRadius: '12px',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: '5px',
      right: 0,
      left: 0,
      display: 'block',
      borderRadius: '12px',
      zIndex: 1,
    },
  },

  [`& .${classes.cardTopIcons}`]: {
    zIndex: 2,
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    padding: '5px 10px',
  },

  [`& .${classes.cardPrice}`]: {
    display: 'flex',
    color: '#171717',
    fontSize: '20px',
    letterSpacing: '0px',
  },

  [`& .${classes.cardIndex}`]: {
    fontSize: '10px',
    backgroundColor: '#EE6A2A',
    borderRadius: '15px',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    padding: '0 4px',
    marginLeft: '6px',
    lineHeight: 2,
  },

  [`& .${classes.cardIndexMain}`]: {
    display: 'flex',
    '& > span': {
      alignSelf: 'flex-end',
      fontSize: '12px',
    },
  },

  [`& .${classes.scheduleTourButton}`]: {
    // transition: '0.3s ease-in-out all',
    fontFamily: 'Open Sans',
  },

  [`& .${classes.viewDetailsButton}`]: {
    // transition: '0.3s ease-in-out all',
  }
}));

export default function FallbackCard(props) {
  const { rootStyle } = props;


  return (
    (<Root>
      <Grid
        style={rootStyle}
        className={ClassNames([classes.root, 'property-card'])}>
        <div className={classes.cardTop}>
          <Skeleton animation="wave" variant="rect" width={325} height={150} />
          <Grid
            className={classes.cardTopIcons}
            container
            justifyContent="space-between">
            <Grid size={6}>
              {/*  */}
            </Grid>
            <Grid size={6}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid>
                  <a target="_blank" href="#">
                    <img
                      width="22px"
                      height="22px"
                      src="/static/images/awesome-street-view.svg"
                      alt="Street View"
                    />
                  </a>
                </Grid>
                <Grid>
                  <a target="_blank" href="">
                    <img
                      width="22px"
                      height="22px"
                      src="/static/images/material-play-circle-outline.svg"
                      alt="Video"
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Grid
          style={{ margin: '16px 0 0 0' }}
          container
          justifyContent="flex-start">
          <Grid>
            <div>
              <Skeleton
                animation="wave"
                variant="rect"
                width={320}
                height={10}
              />
            </div>
            <Skeleton animation="wave" variant="rect" width={320} height={10} />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          style={{ padding: '16px 0' }}>
          <Grid size={12}>
            <Skeleton animation="wave" variant="rect" width={320} height={10} />
          </Grid>
          <Grid size={6}>
            <Skeleton animation="wave" variant="rect" width={120} height={10} />
          </Grid>
          <Grid style={{ textAlign: 'right' }} size={6}>
            <Skeleton animation="wave" variant="rect" width={120} height={10} />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-start" spacing={4}>
          <Grid style={{ overflow: 'hidden' }}>
            <Skeleton animation="wave" variant="rect" width={320} height={10} />
          </Grid>
        </Grid>
        <Grid
          style={{ marginBottom: '20px' }}
          container
          justifyContent="space-between"
          spacing={4}>
          <Grid className={classes.cardIndexMain}>
            <Skeleton animation="wave" variant="rect" width={120} height={10} />
          </Grid>
          <Grid>
            <Grid className={classes.cardIndexMain}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={120}
                height={10}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid size={6}>
            <Skeleton animation="wave" variant="rect" width={150} height={40} />
          </Grid>
          <Grid size={6}>
            <Skeleton animation="wave" variant="rect" width={150} height={40} />
          </Grid>
        </Grid>
      </Grid>
    </Root>)
  );
}
