import React, { useRef, useState, useEffect } from 'react';
import {
  getRecentlyPostedProjects,
  nextRecentlyPostedProjects,
  addWishlist,
} from '../../../redux/actions/main';
import handleViewport from 'react-in-viewport';
import FallbackCard from '../Common/FallbackCard';
import { connect } from 'react-redux';
import ViewAll from '../Common/ViewAll';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
// import { makeStyles } from '@mui/styles';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';
import HorizontalScroll from '../../packages/horizontal-scroll';
import { slugify, gotoPropertyDetailsPage } from '../../util';
import _ from 'lodash';
import useAccessToken from '../../packages/use-access-token';
import useUserInfo from '../../packages/use-user-info';
import MoreDetailTooltip from '@/components/PageComponents/PropertyPage/components/Tooltip';
import PropertyCard from '../../packages/property-card';
import Variables from 'styles/variables.module.scss';
import classes from './recentlypost.module.scss';

// All items component
// Important! add unique key
export const Menu = (
  list,
  loading,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger
) =>
  list &&
  list.length > 0 &&
  list
    .filter(project => {
      if (project) {
        return true;
      }
    })
    .map((el, index) => {
      return (
        <MenuItem
          text={el}
          key={index}
          cardIndex={index}
          loading={loading}
          open_modal={open_modal}
          propertyDetailsClick={propertyDetailsClick}
          propertyDetailsGallery={propertyDetailsGallery}
          toggleWishlist={toggleWishlist}
          openLoginModal={openLoginModal}
          module_type={module_type}
          wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
          addwishlisttrigger={addwishlisttrigger}
          setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          deletecachedpropertytrigger={deletecachedpropertytrigger}
          cachedProject={cachedProject}
        />
      );
    });

const MenuItem = ({
  cardIndex,
  text,
  open_modal,
  propertyDetailsClick,
  propertyDetailsGallery,
  toggleWishlist,
  openLoginModal,
  module_type,
  cachedProject,
  wishlistpropertydeletedtrigger,
  addwishlisttrigger,
  setDeleteCachedPropertyTrigger,
  deletecachedpropertytrigger,
}) => {
  const {
    pId,
    projectImage,
    projectName,
    weRecommendCount,
    // developerName,
    maxPrice,
    minPrice,
    liveabilityIndexCount,
    localityName,
    cityName,
    bestForInvestmentCount,
    streetView,
    videoLink,
    minCarpetArea,
    maxCarpetArea,
    configurationTypeCount,
    wishlists,
    rentAmount,
    furnishingStatus,
    buildingName,
  } = text;
  const bhk_type_label = Object.keys(configurationTypeCount);
  const bhk_types = bhk_type_label
    .map(data => {
      return configurationTypeCount[data].toString() + ' ' + data;
    })
    .toString();
  return (
    (<PropertyCard
      cardIndex={cardIndex}
      property_id={pId}
      projectName={projectName}
      property_name={buildingName}
      // property_name={`
      // ${bhk_types
      //   .replace(/\s/g, '-')
      //   .toLowerCase()}-${maxCarpetArea
      //     .replace(/,/g, '')
      //     .toLowerCase()}-sq-ft-apartment-for-rent-${buildingName.replace(
      //       /\s/g,
      //       '-'
      //     )}`}
      propertyDetailsClick={propertyDetailsClick}
      propertyDetailsGallery={propertyDetailsGallery}
      scheduleTourClick={open_modal}
      livability_index={liveabilityIndexCount}
      investment_index={bestForInvestmentCount}
      location={localityName + ', ' + cityName}
      furnishingStatus={furnishingStatus}
      maxCarpetArea={maxCarpetArea}
      locationwithoutCityName={localityName}
      bhk_types={bhk_types}
      sqft={minCarpetArea + ` to ` + maxCarpetArea + ` SqFt`}
      rootStyle={{
        fontFamily: 'Open Sans',
        marginRight: '0px',
        position: 'relative',
        // bottom: '20px',
      }}
      images={projectImage ? projectImage : []}
      p_project_name={
        <div
          style={{
            color: '#171717',
            fontSize: '18px',
            fontWeight: 700,
            fontFamily: 'Open Sans',
          }}>
          {projectName.length > 31 ? (
            <MoreDetailTooltip
              title={projectName}
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    offset: {
                      enabled: true,
                      offset: '-80px, -15px',
                    },
                  },
                },
              }}
              arrow>
              <div
                style={{
                  cursor: 'pointer',
                  fontSize: '18px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: '95%',
                }}>
                {projectName}
              </div>
            </MoreDetailTooltip>
          ) : (
            <div
              style={{
                cursor: 'pointer',
                fontSize: '18px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
              {projectName}
            </div>
          )}
        </div>
      }
      developer_name={
        <CommonHeaderText
          text={buildingName}
          variant="h5"
          style={{
            marginTop: '5px',
            color: '#6F6F6F',
            fontSize: '14px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
          }}
        />
      }
      p_we_recommend_count={weRecommendCount}
      street_url={streetView}
      video_url={videoLink}
      price={
        <Grid container>
          <Grid>
            <CommonHeaderText
              text={
                <span style={{ paddingLeft: '3px' }}>
                  {'₹ '}
                  <span
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      paddingLeft: '9px',
                    }}>
                    {rentAmount}
                  </span>{' '}
                  <span style={{ fontSize: '14px', fontWeight: '400' }}>
                    {'Rent'}
                  </span>
                </span>
              }
              variant="h4"
              style={{
                color: '#171717',
                fontSize: '18px',
                fontWeight: 700,
                fontFamily: 'Open Sans',
              }}
            />
          </Grid>
        </Grid>
      }
      wishlists={wishlists}
      toggleWishlist={toggleWishlist}
      openLoginModal={openLoginModal}
      module_type={slugify(module_type)}
      cityName={slugify(cityName)}
      priceToshow={
        maxPrice !== minPrice ? minPrice + ' - ' + maxPrice : minPrice
      }
      maxPrice={module_type == 'buy' ? maxPrice : rentAmount}
      minPrice={module_type == 'buy' ? minPrice : rentAmount}
      setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
      deletecachedpropertytrigger={deletecachedpropertytrigger}
      wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
      addwishlisttrigge={addwishlisttrigger}
      cachedProject={cachedProject}
      minCarpetArea={minCarpetArea}
    />)
  );
};

// const useStyles = makeStyles({
//   highlightWord: {
//     color: Variables.darkorangeColor,
//     fontWeight: '700',
//     fontFamily: 'Open Sans !Important',
//   },
//   capitals: {
//     textTransform: 'uppercase',
//   },
//   developerCardGrid: {
//     height: '100%',
//     maxHeight: '450px',
//   },
// });

const RecentlyPostSection = props => {
  let { data, module_type } = props;
  const {
    forwardedRef,
    open_modal,
    propertyDetailsGallery,
    openLoginModal,
    city,
    cachedProject,
    callWishlistApi,
    wishlistPropertyDeletedCall,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
  } = props;

  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  module_type = module_type || 'buy';

  const { projects, loading, end } =
    props.apiData.projects.length > 0 ? props.apiData : data;
  // console.log('recommendedProjects', end)
  const componentRef = useRef();

  const propertyDetailsClick = (
    property_id,
    property_name,
    locationwithoutCityName
  ) => {
    gotoPropertyDetailsPage({
      module_type: module_type,
      city_name: city?.name,
      property_name: property_name,
      property_id: property_id,
      localityName: locationwithoutCityName,
    });
  };

  const toggleWishlist = propertyid => {
    props.addWishlist({
      data: {
        property_id: propertyid,
        user_id: user?.id,
        module_type: module_type,
      },
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
      callBack: callWishlistApi,
    });
    wishlistPropertyDeletedCall();
  };

  const firstmenu = Menu(
    projects,
    loading,
    open_modal,
    propertyDetailsClick,
    propertyDetailsGallery,
    toggleWishlist,
    openLoginModal,
    module_type,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger
  );

  // const classes = useStyles();

  const highlightedWord = word => {
    return <span className={classes.highlightWord}>{word}</span>;
  };

  const [limit] = useState(15);
  const [windowSize] = useState(3);
  const [places, setplaces] = useState(0);
  const [isJsLoad, setisJsLoad] = useState(false);
  useEffect(() => {
    setisJsLoad(true);
  }, []);
  const recommendedProjects = _.debounce(
    (get, moduletype, global_selected_city_id, skips, userId) => {
      const queryParams = {
        city_id: global_selected_city_id,
        skip: skips,
        limit: limit,
        module_type: moduletype,
        section: 'recent',
        user_id: userId,
      };
      // console.log('queryParams', queryParams)
      if (get && isJsLoad) {
        props.getRecentlyPostedProjects(queryParams);
      } else {
        props.nextRecentlyPostedProjects(queryParams);
      }
    }
  );

  useEffect(() => {
    let mounted = false;
    let userId = user?.id;
    if (!mounted) {
      setplaces(0);
      recommendedProjects(true, module_type, city?.id, 0, userId);
    }
    return () => {
      mounted = true;
    };
  }, [
    city,
    user,
    module_type,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
  ]);

  const loadPrevProjects = () => {
    setplaces(prev => prev - 1);
    componentRef.current.handleArrowClick();
  };

  const loadNextProjects = () => {
    setplaces(prev => prev + 1);
    if (!end) {
      recommendedProjects(false, module_type, city?.id, projects.length + 6);
    }
    componentRef.current.handleArrowClickRight();
  };

  if (projects && projects.length == 0) return null;

  return (
    (<section ref={forwardedRef} className="main-section-2">
      <div className="container">
        <Grid container>
          <Grid justifyContent="flex-start" container alignItems="center" size={9}>
            <CommonHeaderText
              text={
                <span style={{ fontWeight:'700'}}>
                  {highlightedWord('Recently Posted Properties')} In{' '}
                  {city?.name}
                </span>
              }
              variant="h1"
              style={{
                fontFamily: 'Open Sans !Important',
                fontWeight: '500',
                lineHeight: '1',
                fontSize: '28px',
                color: Variables.primaryColor,
                paddingRight: '18px',
                textTransform: 'capitalize',
                marginBottom: '20px',
              }}
            />
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginLeft: '-10px',
            }}
            size={3}>
            <div
              style={{
                paddingRight: '15px',
                borderRight: '2px solid #17171721',
              }}>
              <ViewAll
                text="View All"
                url={`/${module_type}/recommended-projects-in-${slugify(
                  city?.name || 'mumbai'
                )}`}
              />
            </div>
            <div
              style={{
                paddingLeft: '15px',
              }}>
              <HorizontalScrollButtons
                loadPrevProjects={loadPrevProjects}
                loadNextProjects={loadNextProjects}
                disableNext={projects.length <= (places + 1) * windowSize}
                disablePrev={places === 0}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container className="card-section" style={{marginTop:'-20px'}}>
          <Grid size={12}>
            {(loading && places == 0) || !projects || projects.length == 0 ? (
              <>
                <Grid
                  spacing={2}
                  container
                  justifyContent="center"
                  alignitems="center">
                  <Grid size={4}>
                    <FallbackCard />
                  </Grid>
                  <Grid size={4}>
                    <FallbackCard />
                  </Grid>
                  <Grid size={4}>
                    <FallbackCard />
                  </Grid>
                </Grid>
              </>
            ) : (
              <HorizontalScroll
                itemClass="property-card-item "
                componentRef={componentRef}
                data={firstmenu}
                dragging={false}
              />
            )}
          </Grid>
        </Grid>
        {/* )} */}
      </div>
    </section>)
  );
};

const RecentlyPost = handleViewport(RecentlyPostSection, {
  rootMargin: '-1.0px',
});

const mapStateToProps = state => ({
  apiData: state.recentlyPostedReducer,
  headerApiData: state.headerReducer,
});

const mapDispatchToProps = {
  nextRecentlyPostedProjects,
  getRecentlyPostedProjects,
  addWishlist,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyPost);
