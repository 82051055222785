import { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Chip, Grid2 as Grid, Typography } from '@mui/material';
const PREFIX = 'ChipGroup';

const classes = {
  chipItem: `${PREFIX}-chipItem`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.chipItem}`]: {
    borderRadius: '0.3rem',
    textTransform: 'capitalize',
    '&.MuiChip-colorPrimary': {
      border: '1px solid rgba(0,0,0,0)',
      color: '#FFF !important',
    },
  }
}));

const VARIANT_RADIO = 'radio';
const VARIANT_CHECK = 'check';
const VARIANT_BUTTON = 'button';

function ChipGroup(props) {
  const {
    items,
    defaultSelection,
    selectedItems = [],
    variant = VARIANT_CHECK,
    onChange,
    onClick,
  } = props;
  const [selection, setSelection] = useState(() => {
    if (Array.isArray(defaultSelection)) {
      return defaultSelection;
    } else if (defaultSelection) {
      return [defaultSelection];
    }
    return [];
  });

  const mutex = variant === VARIANT_RADIO;
  const noSelection = variant === VARIANT_BUTTON;

  const handleClick = (item, selected) => {
    if (noSelection) {
      if (onClick && typeof onClick === 'function') {
        onClick(item);
      }
      return;
    }
    const newSelection = [...selection];
    if (selected) {
      newSelection.splice(newSelection.indexOf(item), 1);
    } else {
      if (mutex) newSelection.splice(0, newSelection.length);
      newSelection.push(item);
    }
    setSelection(newSelection);
    if (onChange && typeof onChange === 'function') {
      onChange(mutex ? newSelection[0] : newSelection);
    }
  };

  return (
    (<StyledGrid container spacing={1}>
      {items.map(item => {
        let selected = false;
        if (variant === VARIANT_BUTTON) {
          selected =
            selectedItems.findIndex(i => {
              if (typeof item === 'string') {
                return item.name === i.name;
              } else {
                return item.name === i.name && item.id === i.id;
              }
            }) >= 0;
        } else {
          selected = selection.includes(item);
        }
        const key = typeof item === 'string' ? item : item.id;
        const label = typeof item === 'string' ? item : item.name;
        return (
          (<Grid key={key}>
            <Chip
              label={
                <Typography variant="body2" noWrap={true}>
                  {label}
                </Typography>
              }
              className={classes.chipItem}
              variant={selected ? 'default' : 'outlined'}
              color={selected ? 'primary' : 'default'}
              onClick={
                mutex && selected ? null : () => handleClick(item, selected)
              }
            />
          </Grid>)
        );
      })}
    </StyledGrid>)
  );
}

export default ChipGroup;
