import React, { useRef, useEffect } from 'react';
import { Box, ButtonBase, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'classnames';
import PropTypes from 'prop-types';

function ScrollButton({ icon, type, hidden, onClick, buttonRootStyles, buttonStyles }) {
  if (hidden) return null;

  return (
    <Box className={clsx(buttonRootStyles, type)}>
      <IconButton onClick={onClick} sx={buttonStyles} size="large">
        {icon}
      </IconButton>
    </Box>
  );
}

function LeftScrollButton({ scrollContainerRef, buttonRootStyles, buttonStyles, button }) {
  const [hidden, setHidden] = React.useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      setHidden(container.scrollLeft <= 0); // Hide if at the start
    };

    const container = scrollContainerRef.current;
    handleScroll();
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainerRef]);

  const clickHandler = () => {
    const container = scrollContainerRef.current;
    container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
  };

  return (
    <ScrollButton
      type="left"
      hidden={hidden}
      onClick={clickHandler}
      icon={button || <ChevronLeftIcon fontSize="large" />}
      buttonRootStyles={buttonRootStyles}
      buttonStyles={buttonStyles}
    />
  );
}

function RightScrollButton({ scrollContainerRef, buttonRootStyles, buttonStyles, button }) {
  const [hidden, setHidden] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      // Check if content is overflowing or scrolled to the end
      const isFullyVisible = container.scrollWidth <= container.clientWidth;
      const isAtEnd =
        container.scrollLeft + container.clientWidth >= container.scrollWidth;

      setHidden(isFullyVisible || isAtEnd);
    };

    const container = scrollContainerRef.current;
    handleScroll();
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainerRef]);
  const clickHandler = () => {
    const container = scrollContainerRef.current;
    container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
  };

  return (
    <ScrollButton
      type="right"
      hidden={hidden}
      onClick={clickHandler}
      icon={button || <ChevronRightIcon fontSize="large" />}
      buttonRootStyles={buttonRootStyles}
      buttonStyles={buttonStyles}
    />
  );
}

function MenuItem({ label, onClick, itemStyles }) {
  return (
    <ButtonBase onClick={onClick} sx={{ p: 2, ...itemStyles }}>
      {label}
    </ButtonBase>
  );
}

function HorizontalScrollMenu({
  menuitems = [],
  onItemClick = () => { },
  scrollButtons = true,
  itemStyles = {},
  rootStyles = {},
  wheelScroll = true,
  buttonRootStyles = '',
  leftButton,
  rightButton,
  leftBtnRootClass,
  rightBtnRootClass,
  Tab,
  children,
}) {
  let buttonStyles = {
    border: '1px solid #E3EAF5',
    cursor: 'pointer',
    padding: '0',
    fontSize: 0,
    userSelect: 'none',
    borderRadius: '100rem',
    backgroundColor: '#fff',
  };
  const scrollContainerRef = useRef(null);

  const handleWheel = (e) => {
    if (!wheelScroll) return;

    const container = scrollContainerRef.current;
    if (Math.abs(e.deltaX) !== 0 || Math.abs(e.deltaY) < 15) {
      e.stopPropagation();
    } else if (e.deltaY < 0) {
      container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
    } else if (e.deltaY > 0) {
      container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        ...rootStyles,
        display: 'flex',
        width: '100%',
      }}
    >
      {scrollButtons && (
        <LeftScrollButton
          scrollContainerRef={scrollContainerRef}
          buttonRootStyles={clsx(buttonRootStyles, leftBtnRootClass)}
          buttonStyles={buttonStyles}
          button={leftButton}
        />
      )}
      <Box
        ref={scrollContainerRef}
        onWheel={handleWheel}
        sx={{
          display: 'flex',
          overflowX: 'auto', // Enable scrolling
          scrollBehavior: 'smooth',
          whiteSpace: 'nowrap',
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Webkit browsers (Chrome, Safari)
          '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
          'scrollbar-width': 'none', // Hide scrollbar for Firefox
          '& > *': { display: 'inline-block' },
          ...(Tab === 'price-detail' ? { px: 3 } : {}),
        }}
      >
        {menuitems.map((item, i) =>
          typeof item === 'string' ? (
            <MenuItem
              itemStyles={itemStyles}
              key={item}
              label={item}
              onClick={() => onItemClick(item, i)}
            />
          ) : (
            <Box key={`menuitem-${i}`}>{item}</Box>
          )
        )}
        {!menuitems.length && children}
      </Box>
      {scrollButtons && (
        <RightScrollButton
          scrollContainerRef={scrollContainerRef}
          buttonRootStyles={clsx(buttonRootStyles, rightBtnRootClass)}
          buttonStyles={buttonStyles}
          button={rightButton}
        />
      )}
    </Box>
  );
}

HorizontalScrollMenu.propTypes = {
  menuitems: PropTypes.array,
  itemStyles: PropTypes.object,
  onItemClick: PropTypes.func,
  wheelScroll: PropTypes.bool,
};

export default HorizontalScrollMenu;
