import React, { useRef, useEffect, useState } from 'react';
import handleViewport from 'react-in-viewport';
import { getTestimonials } from '../../../redux/actions/main';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
import ClassNames from 'classnames';
import styles from './testimonial.module.scss';
import FallbackCardTestimonial from '../Common/FallbackCardTestimonial';
// import MobHorizontalScroll from '../../../components/packages/mob-horizontal-scroll';
import MenuItemViewTestimonials from './MenuItemViewTestimonials';

import HorizontalScroll from '../../packages/horizontal-scroll';
import MobHorizontalScrollNew from '@/components/packages/mob-horizontal-scroll-new';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';



// All items component
// Important! add unique key
export const Menu = (list, loading) =>
  list?.map((el, index) => {
    return <MenuItem text={el} key={index} loading={loading} />;
  });

const MenuItem = ({ text }) => {
  return <MenuItemViewTestimonials data={text} />;
};

const TestimonialsSection = (
  props = { is_desktop: false, inViewport: false }
) => {
  const { is_desktop, inViewport, forwardedRef } = props;
  const { testimonials, loading } = props.apiData;

  const firstmenu = Menu(testimonials, loading);
  const [limit] = useState(20);
  const [places, setplaces] = useState(0);
  const [windowSize] = useState(3);
  useEffect(() => {
    let mounted = false;
    if (inViewport && testimonials?.length == 0) {
      if (!mounted) {
        props.getTestimonials({
          skip: 0,
          limit: limit,
        });
      }
    }
    return () => {
      mounted = true;
    };
  }, [inViewport]);

  const highlightedWord = word => {
    return <span className={styles.highlightWord}>{word}</span>;
  };
  // const classes = useStyles();
  const componentRef = useRef();

  const loadPrevProjects = () => {
    setplaces(prev => prev - 1);
    componentRef.current.handleArrowClick();
  };

  const loadNextProjects = () => {
    setplaces(prev => prev + 1);
    componentRef.current.handleArrowClickRight();
  };

  return (
    (<section
      ref={forwardedRef}
      className={ClassNames([styles.mainTestimonisec, 'main-section'])}>
      <div className="container">
        <Grid container size={12}>
          {is_desktop ? (
            <>
              <Grid
                style={{ marginTop: '20px' }}
                className={styles.headingText}
                justifyContent="flex-start"
                container
                size={9}>
                <CommonHeaderText text={'Meet Our Customers'} variant="h3" />
                <CommonHeaderText
                  text={
                    <>
                      OVERWHELMING WORDS BY THEM
                      {/* {highlightedWord('WORDS BY THEM')} */}
                    </>
                  }
                />
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginLeft: '-8px',
                }}
                size={3}>
                {is_desktop && (
                  <div
                    style={{
                      paddingLeft: '15px',
                    }}>
                    <HorizontalScrollButtons
                      loadPrevProjects={loadPrevProjects}
                      loadNextProjects={loadNextProjects}
                      disableNext={
                        testimonials?.length < (places + 1) * windowSize
                      }
                      disablePrev={places === 0}
                    />
                  </div>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid className={styles.dealWeekTitle} size={12}>
                <CommonHeaderText
                  variant="h3"
                  text={<>Meet Our {highlightedWord('Customers')}</>}
                />
                <CommonHeaderText text={<>Overwhelming Words By Them</>} />
              </Grid>
            </>
          )}
        </Grid>

        <Grid
          container
          className={ClassNames([styles.contentslide, 'card-section'])}>
          <Grid style={{ marginTop: '0px' }} size={12}>
            {!testimonials || testimonials?.length == 0 ? (
              <>
                {is_desktop ? (
                  <Grid container justifyContent="center" alignitems="center">
                    <Grid size={4}>
                      <FallbackCardTestimonial />
                    </Grid>
                    <Grid size={4}>
                      <FallbackCardTestimonial />
                    </Grid>
                    <Grid size={4}>
                      <FallbackCardTestimonial />
                    </Grid>
                  </Grid>
                ) : (
                  <FallbackCardTestimonial />
                )}
              </>
            ) : is_desktop ? (
              <HorizontalScroll
                itemClass="testimonial-container"
                inertiaScrolling={true}
                inertiaScrollingSlowdown={0.5}
                componentRef={componentRef}
                data={firstmenu}
                dragging={is_desktop ? false : true}
                innerWrapperClass="testimonial-inner-wrapper"
              />
            ) : (
              <MobHorizontalScrollNew
                innerWrapperClass="testimonial-inner-wrapper"
                itemClass="testimonial-container"
                data={firstmenu}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </section>)
  );
};

const Testimonials = handleViewport(TestimonialsSection, {
  rootMargin: '-1.0px',
});

const mapStateToProps = state => ({
  apiData: state.testimonialsReducer,
  headersApiData: state.headerReducer,
});

const mapDispatchToProps = {
  getTestimonials,
};

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
