import { useState, useEffect } from 'react';
// import useStyles from './styles';
import MediaPreview, {
  MEDIA_TYPE_360,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
} from '../media-preview';
import {
  Close as CloseIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import clsx from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  '@global': {
    '@keyframes anim-slide-out-left': {
      '0%': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '50%': {
        opacity: 0.5,
        transform: 'translateX(-4rem)',
      },
      '100%': {
        opacity: 0,
        transform: 'translateX(-8rem)',
        visibility: 'hidden',
      },
    },
    '@keyframes anim-slide-out-right': {
      '0%': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '50%': {
        opacity: 0.5,
        transform: 'translateX(4rem)',
      },
      '100%': {
        opacity: 0,
        transform: 'translateX(8rem)',
        visibility: 'hidden',
      },
    },
    '@keyframes anim-fade-in': {
      '0%': {
        opacity: 0,
        visibility: 'visible',
        transform: 'translateX(0)',
      },
      '50%': {
        opacity: 0.5,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
  root: {
    fontFamily: 'Open Sans, sans-serif !important',
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    // to-check
    backgroundColor: '#555555E6',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginRight: '1rem',
    marginTop: '0.8rem',
    color: '#272727',
    backgroundColor: '#FFFFFF8E',
    border: '1px solid #707070',
    borderRadius: '5rem',
    padding: '0.2rem',
  },
  content: {
    display: 'flex',
    '&.portrait': {
      flexFlow: 'column nowrap',
      alignItems: 'center',
      gap: '1rem',
      padding: '0 1.5rem',
      width: '100%',
      height: '40%',
    },
    '&.landscape': {
      flexFlow: 'row nowrap',
      height: '100%',
      backgroundColor: '#151515',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  thumbnail: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& .corner-round': {
      borderRadius: '0.3rem',
    },
    '&.land': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        flexGrow: 1,
        flexShrink: 1,
      },
      '& img, video': {
        objectFit: 'contain',
      },
      '& .corner-round': {
        borderRadius: 0,
      },
    },
    '&.slide-out-left': {
      animation: '0.4s linear anim-slide-out-left 1',
    },
    '&.slide-out-right': {
      animation: '0.4s linear anim-slide-out-right 1',
    },
    '&.fade-in': {
      animation: '0.5s ease-in anim-fade-in 1;',
    },
  },
  infoText: {
    display: 'inline-block',
    padding: '0.35rem 1.5rem',
    fontSize: '0.7rem',
    fontWeight: '700',
    fontFamily: 'Open Sans, SemiBold, sans-serif',
    backgroundColor: '#FFFFFF8D',
    border: '1px solid #707070',
    borderRadius: '5rem',
    margin: '0 auto',
  },
  sliderAction: {
    width: '8rem',
    height: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&.left': {
      justifyContent: 'left',
    },
    '&.right': {
      justifyContent: 'right',
    },
    '& .action': {
      fontSize: '3.5rem',
      padding: '0.6rem 0',
      color: '#E3E3E3',
      backgroundColor: '#6A6A6A8E',
      transition: 'opacity linear 0.2s, visibility linear 0.2s',
      '&.prev': {
        marginLeft: '-0.6rem',
        borderTopRightRadius: '12rem',
        borderBottomRightRadius: '12rem',
      },
      '&.next': {
        marginRight: '-0.6rem',
        borderTopLeftRadius: '12rem',
        borderBottomLeftRadius: '12rem',
      },
      '&.hidden': {
        opacity: 0,
        visibility: 'hidden',
      },
    },
  },
});


const TRANSITION_DURATION = 400; // 0.4 secs

function PreviewOverlay(props) {
  const classes = useStyles();
  const { data, initialItemIndex, onClose } = props;
  const [deviceInLandscape, setDeviceInLandscape] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(initialItemIndex ?? 0);
  const [thumbnailAnimationClass, setThumbnailAnimationClass] =
    useState('fade-in');
  const showPrevious = () => setCurrentIndex(Math.max(currentIndex - 1, 0));
  const showNext = () =>
    setCurrentIndex(Math.min(currentIndex + 1, data.length - 1));

  const [sliding, setSliding] = useState(false);
  const handlePrevClick = () => {
    if (sliding) return;
    setThumbnailAnimationClass('slide-out-right');
    setSliding(true);
    setTimeout(() => {
      showPrevious();
      setThumbnailAnimationClass('fade-in');
      setSliding(false);
    }, TRANSITION_DURATION);
  };
  const handleNextClick = () => {
    if (sliding) return;
    setThumbnailAnimationClass('slide-out-left');
    setSliding(true);
    setTimeout(() => {
      showNext();
      setThumbnailAnimationClass('fade-in');
      setSliding(false);
    }, TRANSITION_DURATION);
  };

  useEffect(() => {
    window.onresize = () => {
      setDeviceInLandscape(hasLandscapeOrientation());
    };
  }, []);

  const mediaProps = {
    common: {
      className: !deviceInLandscape ? 'corner-round' : '',
    },
    [MEDIA_TYPE_IMAGE]: {
      layout: 'fill',
    },
    [MEDIA_TYPE_VIDEO]: {
      autoPlay: true,
      controls: true,
    },
    [MEDIA_TYPE_360]: {},
  };

  return (
    <div className={classes.root}>
      <ButtonBase
        onClick={onClose ?? (() => ({}))}
        className={classes.closeButton}
        aria-label="close preview">
        <CloseIcon fontSize="small" />
      </ButtonBase>
      <div
        className={clsx(
          classes.content,
          deviceInLandscape ? 'landscape' : 'portrait'
        )}>
        {deviceInLandscape && (
          <div className={clsx(classes.sliderAction, 'left')}>
            <ButtonBase
              className={clsx(
                'action',
                'prev',
                currentIndex > 0 ? '' : 'hidden'
              )}
              aria-label="previous image"
              onClick={handlePrevClick}>
              <ChevronLeftIcon fontSize="inherit" />
            </ButtonBase>
          </div>
        )}
        <div
          className={clsx(
            classes.thumbnail,
            deviceInLandscape ? `land ${thumbnailAnimationClass}` : ''
          )}>
          <MediaPreview
            {...mediaProps.common}
            {...mediaProps[data[currentIndex]?.type]}
            type={data[currentIndex]?.type}
            url={data[currentIndex]?.url}
            alt={data[currentIndex]?.subCategory}
          />
        </div>
        {deviceInLandscape && (
          <div className={clsx(classes.sliderAction, 'right')}>
            <ButtonBase
              className={clsx(
                'action',
                'next',
                currentIndex < data.length - 1 ? '' : 'hidden'
              )}
              aria-label="next image"
              onClick={handleNextClick}>
              <ChevronRightIcon fontSize="inherit" />
            </ButtonBase>
          </div>
        )}
        {!deviceInLandscape && (
          <p className={classes.infoText}>
            Turn the mobile to view full screen
          </p>
        )}
      </div>
    </div>
  );
}

function hasLandscapeOrientation() {
  return window.screen.width > window.screen.height;
}

export default PreviewOverlay;
