import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
const MoreDetailTooltip = withStyles({
  tooltip: {
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid #D3E0ED',
    marginBottom: '10px',
    width: 'auto',
    maxWidth: '300px',
    fontSize: '12px!important',
    padding: '10px !important',
    fontWeight: '500!important',
    fontFamily: 'Open Sans, sans-serif !important',
  },
  arrow: {
    '&:before': {
      border: '1px solid #D3E0ED',
    },
    transform: 'translate3d(30px, 0px, 0px)!important',
    color: '#fff !important',
  },
})(Tooltip);

export default MoreDetailTooltip;
