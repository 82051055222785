import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import style from './styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import HorizontalScroll from '../../packages/horizontal-scroll';
import HorizontalScrollButtons from '../Common/HorizontalScrollButtons';
import static_footer_links_india_property from '../../../data/static_footer_links_india_property';
import React, { useRef, useState } from 'react';

export default function FooterPropertyIndiaLinks() {
  const [windowSize] = useState(7);
  const [place, setplace] = useState(0);
  const componentRef = useRef();
  const loadPrevProjects = () => {
    setplace(prev => prev - 1);
    componentRef.current.handleArrowClick();
  };
  const loadNextProjects = () => {
    setplace(prev => prev + 1);
    componentRef.current.handleArrowClickRight();
  };

  return (<>
    <Grid container alignItems="center">
      <Grid
        size={{
          xs: 12,
          sm: 9,
          md: 9,
          lg: 9
        }}>
        <Box sx={style.footerMainTitle}>
          <h4>Properties in India</h4>
        </Box>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 3,
          md: 3,
          lg: 3
        }}>
        <div
          style={{
            paddingLeft: '15px',
            marginRight: '8px',
          }}>
          <HorizontalScrollButtons
            loadPrevProjects={loadPrevProjects}
            loadNextProjects={loadNextProjects}
            disableNext={
              static_footer_links_india_property.length < place * windowSize
            }
            disablePrev={place === 0}
          />
        </div>
      </Grid>
    </Grid>
    <Grid container>
      <Grid size={12}>
        <HorizontalScroll
          itemClass="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-4 MuiGrid-grid-lg-4"
          componentRef={componentRef}
          data={
            static_footer_links_india_property &&
            static_footer_links_india_property.map((data, index) => {
              const {
                footerLocalityMainName,
                footerLocalityLinkPanvel,
                footerLocalityLinkPowai,
                footerLocalityLinkKharghar,
                footerLocalityLinkBorivali,
                footerLocalityLinkMulund,
                footerLocalityLinkDombivali,
                propertyinPanvel,
                propertyinPowai,
                propertyinKharghar,
                propertyinBorivali,
                propertyinMulund,
                propertyinDombivali,
              } = data;
              return (
                (<Grid
                  key={index}
                  sx={style.item}
                  size={{
                    xs: 4,
                    sm: 4,
                    md: 4
                  }}>
                  <Box sx={style.footerSubTitle}>
                    <h5>{footerLocalityMainName}</h5>
                    <List sx={style.footerLinks}>
                      {footerLocalityLinkPanvel && (
                        <ListItem>
                          <a
                            href={footerLocalityLinkPanvel}
                            target="_blank"
                            rel="noreferrer">
                            {propertyinPanvel}
                          </a>
                        </ListItem>
                      )}
                      {footerLocalityLinkPowai && (
                        <ListItem>
                          <a
                            href={footerLocalityLinkPowai}
                            target="_blank"
                            rel="noreferrer">
                            {propertyinPowai}
                          </a>
                        </ListItem>
                      )}
                      {footerLocalityLinkKharghar && (
                        <ListItem>
                          <a
                            href={footerLocalityLinkKharghar}
                            target="_blank"
                            rel="noreferrer">
                            {propertyinKharghar}
                          </a>
                        </ListItem>
                      )}
                      {footerLocalityLinkBorivali && (
                        <ListItem>
                          <a
                            href={footerLocalityLinkBorivali}
                            target="_blank"
                            rel="noreferrer">
                            {propertyinBorivali}
                          </a>
                        </ListItem>
                      )}
                      {footerLocalityLinkMulund && (
                        <ListItem>
                          <a
                            href={footerLocalityLinkMulund}
                            target="_blank"
                            rel="noreferrer">
                            {propertyinMulund}
                          </a>
                        </ListItem>
                      )}
                      {footerLocalityLinkDombivali && (
                        <ListItem>
                          <a
                            href={footerLocalityLinkDombivali}
                            target="_blank"
                            rel="noreferrer">
                            {propertyinDombivali}
                          </a>
                        </ListItem>
                      )}
                    </List>
                  </Box>
                </Grid>)
              );
            })
          }
        />
      </Grid>
    </Grid>
    <Divider sx={style.horizontalLine} />
  </>);
}
