import BottomBar from '../BottomBar';
import { styled } from '@mui/material/styles';
import Section from '../Section';
import ChipGroup from '../ChipGroup';
import HBImage from '../../../packages/hbimage';
import { Button, TextField, InputAdornment, SvgIcon, Chip, Typography, Paper, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { useEffect, useRef, useState } from 'react';
import SearchSuggestions from './SearchSuggestions';
import PopularLocalitiesSuggestions from './PopularLocalitiesSuggestions';
import usePreviousValue from '@/hooks/usePreviousValue';
const PREFIX = 'PopularPartsFilter';

const classes = {
  bottomAction: `${PREFIX}-bottomAction`,
  searchSection: `${PREFIX}-searchSection`,
  searchBox: `${PREFIX}-searchBox`,
  suggestions: `${PREFIX}-suggestions`,
  chipItem: `${PREFIX}-chipItem`,
  scrollContainer: `${PREFIX}-scrollContainer`,
  popularRoot: `${PREFIX}-popularRoot`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.bottomAction}`]: {
    margin: '0 auto',
    textTransform: 'none',
  },

  [`& .${classes.searchSection}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.searchBox}`]: {
    margin: '0.4em 0',
    backgroundColor: '#ffffff',
    width: '100%',
    '& > .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },

  [`& .${classes.suggestions}`]: {
    minHeight: 'calc(100% - 56px)',
    padding: 0,
  },

  [`& .${classes.chipItem}`]: {
    margin: '0 0.2rem',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '0.3rem',
    textTransform: 'capitalize',
  },

  [`& .${classes.scrollContainer}`]: {
    padding: '0.3rem 0',
  },

  [`& .${classes.popularRoot}`]: {
    minHeight: '100%',
  }
}));

const SCREEN_POPULAR = 'popular_parts';
const SCREEN_LOCALITIES_SUGGESTIONS = 'localities_suggestions';
const SCREEN_KEYWORD_SUGGESTIONS = 'keyword_suggestions';

function PopularPartsFilter(props) {
  const {
    rootClass,
    city,
    parts,
    moduleType,
    localities,
    subLocalities,
    projects,
    onNext,
    onPropertyClick,
  } = props;
  const [selection, setSelection] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const inputRef = useRef();
  const rootRef = useRef();
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleKeywordInput = event => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
  };
  const handleSearchBarFocus = () => {
    setShowSuggestions(true);
  };

  const toggleSelection = item => {
    const itemIndex = selection.findIndex(
      i => i.id === item.id && i.name === item.name && i.type === item.type
    );
    if (itemIndex >= 0) {
      removeFromSelection(itemIndex);
    } else {
      setSelection([...selection, item]);
    }
  };
  const addToSelection = item => {
    const itemFound = selection.find(
      i => i.id === item.id && i.name === item.name && i.type === item.type
    );
    if (!itemFound) {
      setSelection([...selection, item]);
    }
  };
  const removeFromSelection = item => {
    const newSelection = [...selection];
    const itemIndex =
      typeof item === 'number'
        ? item
        : newSelection.findIndex(
            i =>
              i.id === item.id && i.name === item.name && i.type === item.type
          );
    if (itemIndex >= 0) {
      newSelection.splice(itemIndex, 1);
      setSelection(newSelection);
    }
  };

  const handleSuggestionClick = (id, name, type, localityName) => {
    if (type === 'properties') {
      if (onPropertyClick && typeof onPropertyClick === 'function') {
        onPropertyClick({ id, name, localityName });
      }
      return;
    }
    addToSelection({ id, name, type });
    setSearchKeyword('');
  };



  useEffect(() => {
    const onRootClick = event => {
      if (
        showSuggestions &&
        searchKeyword.length === 0 &&
        event.target !== inputRef.current
      ) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('click', onRootClick);
    return () => {
      document.removeEventListener('click', onRootClick);
    };
  }, [inputRef, rootRef, showSuggestions, searchKeyword]);

  const selectionPrev = usePreviousValue(selection);
  const apiRef = useRef();
  useEffect(() => {
    if (selection.length > selectionPrev?.length) {
      const lastItem = selection.slice(-1)[0];
      const itemId = `${lastItem?.type}-${lastItem?.id}`;
      apiRef.current?.scrollToItem?.(
        apiRef.current?.getItemElementById(itemId)
      );
    }
  }, [selection, selectionPrev]);

  // Selected popular parts
  const selectedPopularParts = selection.filter(i => i.type === 'popularPart');

  // Determine which screen to display
  let screen = SCREEN_POPULAR;
  if (showSuggestions && searchKeyword.length > 0) {
    screen = SCREEN_KEYWORD_SUGGESTIONS;
  } else if (
    showSuggestions &&
    searchKeyword.length === 0 &&
    selectedPopularParts.length > 0
  ) {
    screen = SCREEN_LOCALITIES_SUGGESTIONS;
  }

  return (
    (<Root className={rootClass} ref={rootRef}>
      <Section variant="secondary" rootClass={classes.searchSection}>
        <TextField
          autoFocus={true}
          inputRef={inputRef}
          className={classes.searchBox}
          placeholder="Search Locality, Property, Builder"
          value={searchKeyword}
          onInput={handleKeywordInput}
          variant="outlined"
          margin="dense"
          InputProps={{
            onFocusCapture: handleSearchBarFocus,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchKeyword.length === 0 && (
                  <SvgIcon viewBox="0 0 48 48">
                    <path
                      fill="#1E88E5"
                      d="M7,8v26c0,2.2,1.8,4,4,4h8l5,6l5-6h8c2.2,0,4-1.8,4-4V8c0-2.2-1.8-4-4-4H11C8.8,4,7,5.8,7,8"
                    />
                    <path
                      fill="#FFCC80"
                      d="M29,16c0,2.762-2.238,5-5,5s-5-2.238-5-5s2.238-5,5-5S29,13.238,29,16"
                    />
                    <path
                      fill="#90CAF9"
                      d="M33,28c0,0-2-5-9-5s-9,5-9,5v2h18V28z"
                    />
                  </SvgIcon>
                )}
                {searchKeyword.length > 0 && (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      setSearchKeyword('');
                      setShowSuggestions(false);
                    }}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        {/* <ScrollMenu
          scrollContainerClassName={classes.scrollContainer}
          apiRef={apiRef}>
          {selection.map(item => {
            return (
              <Chip
                itemId={`${item.type}-${item.id}`}
                key={`${item.type}-${item.id}`}
                className={classes.chipItem}
                label={
                  <Typography variant="body2" noWrap={true}>
                    {item.name}
                  </Typography>
                }
                deleteIcon={<ClearIcon />}
                onDelete={() => removeFromSelection(item)}
              />
            );
          })}
        </ScrollMenu>
      </Section> */}
       <Box
          // ref={scrollContainerRef}
          sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            p: 1,
          }}
          className={classes.scrollContainer}
        >
          {selection.map((item) => (
            <Chip
              key={`${item.type}-${item.id}`}
              data-id={`${item.type}-${item.id}`}
              className={classes.chipItem}
              label={<Typography variant="body2">{item.name}</Typography>}
              deleteIcon={<ClearIcon />}
              onDelete={() => removeFromSelection(item)}
            />
          ))}
        </Box>
      </Section>
      {screen === SCREEN_KEYWORD_SUGGESTIONS && (
        <SearchSuggestions
          rootClass={classes.suggestions}
          city={city}
          keyword={searchKeyword}
          exclusions={selection}
          onItemClick={handleSuggestionClick}
        />
      )}
      {screen === SCREEN_LOCALITIES_SUGGESTIONS && (
        <PopularLocalitiesSuggestions
          parts={selectedPopularParts}
          onItemClick={locality => {
            addToSelection({ ...locality, type: 'localities' });
          }}
        />
      )}
      {screen === SCREEN_POPULAR && (
        <Paper className={classes.popularRoot} elevation={0}>
          <Section
            variant='borderB' 
            title="Popular Parts"
            icon={
              <HBImage
                src={'/static/images/search-filter/map.svg'}
                alt="popular parts icon"
                width={24}
                height={24}
              />
            }>
            <ChipGroup
              variant="button"
              items={parts}
              selectedItems={selection.filter(i => i.type === 'popularPart')}
              onClick={item =>
                toggleSelection({ ...item, type: 'popularPart' })
              }
            />
          </Section>
          <Section
            variant='borderB'
            title="Popular Localities"
            icon={
              <HBImage
                src={'/static/images/search-filter/map-marker.svg'}
                alt="popular localities icon"
                width={24}
                height={24}
              />
            }>
            <ChipGroup
              variant="button"
              items={localities}
              selectedItems={selection.filter(i => i.type === 'localities')}
              onClick={item => toggleSelection({ ...item, type: 'localities' })}
            />
          </Section>
          <Section
            variant='borderB'
            title="Popular Sub Localities"
            icon={
              <HBImage
                src={'/static/images/search-filter/map-pin.svg'}
                alt="popular sub localities icon"
                width={24}
                height={24}
              />
            }>
            <ChipGroup
              variant="button"
              items={subLocalities}
              selectedItems={selection.filter(i => i.type === 'sub_localities')}
              onClick={item =>
                toggleSelection({ ...item, type: 'sub_localities' })
              }
            />
          </Section>
          {moduleType === 'buy' && (
            <Section
              variant='borderB'
              title="Popular Projects"
              icon={
                <HBImage
                  src={'/static/images/search-filter/buildings.svg'}
                  alt="popular projects icon"
                  width={24}
                  height={24}
                />
              }>
              <ChipGroup
                variant="button"
                items={projects}
                onClick={onPropertyClick}
              />
            </Section>
          )}
        </Paper>
      )}
      <BottomBar>
        <Button
          className={classes.bottomAction}
          variant="contained"
          color="primary"
          disableElevation={true}
          onClick={() =>
            onNext && typeof onNext === 'function' && onNext(selection)
          }>
          Next
        </Button>
      </BottomBar>
    </Root>)
  );
}

export default PopularPartsFilter;
