import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
import ClassNames from 'classnames';
import Paper from '@mui/material/Paper';
import classes from './homepage-searchbx.module.scss';

const HomepageSearchBox = props => {
  const {
    selectedCities,
    selectedDevelopers,
    popularPartData,
    localitiesData,
    sublocalitiesData,
    propertiesData,
    developersData,
    onSelectSearch,
    close_search,
    mobile,
    is_sticky,
    onSelectMultipleCity,
    propertyDetailsClick,
    keyword,
  } = props;
  const [hover_index, setHoverIndex] = useState(0);
  // const classes = useStyles();

  const props_obj = [
    {
      property_name: 'popularPartData',
      name: 'popularPartData',
      data: popularPartData,
      property_id: 'popular_part_id',
      title: 'POPULAR PARTS',
    },
    {
      property_name: 'localitiesData',
      name: 'localitiesData',
      data: localitiesData,
      property_id: 'location_id',
      title: 'POPULAR LOCALITIES',
    },
    {
      property_name: 'sublocalitiesData',
      name: 'sublocalitiesData',
      data: sublocalitiesData,
      property_id: 'sublocation_id',
      title: 'SUB LOCALITIES',
    },
    {
      property_name: 'propertiesData',
      name: 'propertiesData',
      data: propertiesData,
      property_id: 'properties_id',
      title: 'POPULAR PROJECTS',
    },
    {
      property_name: 'developersData',
      name: 'developersData',
      data: developersData,
      property_id: 'developer_id',
      title: 'POPULAR DEVELOPER',
    },
  ];

  const handleHover = index => {
    setHoverIndex(index);
  };
  return (
    <>
      <Paper
        className={ClassNames([
          popularPartData?.length == 0 &&
          localitiesData.length == 0 &&
          sublocalitiesData.length == 0 &&
          propertiesData.length == 0 &&
          developersData.length == 0
            ? classes.rootwithnocontaint
            : classes.root,
          popularPartData?.length == 0 &&
          localitiesData.length == 0 &&
          sublocalitiesData.length == 0 &&
          propertiesData.length == 0 &&
          developersData.length == 0
            ? 'header-search-box-with-no-containt'
            : 'header-search-box',
        ])}
        style={mobile ? { top: '40px !important' } : {}}>
        {is_sticky && (selectedCities || selectedDevelopers) && (
          <div>
            {selectedCities}
            {selectedDevelopers}
          </div>
        )}
        <ul className={classes.paperListRoot}>
          {popularPartData?.length == 0 &&
          localitiesData.length == 0 &&
          sublocalitiesData.length == 0 &&
          propertiesData.length == 0 &&
          developersData.length == 0 ? (
            <div>
              <span className={classes.noSearchfountText}>
                Sorry, no matching results were found
              </span>
            </div>
          ) : (
            props_obj.map((element, ind) => {
              return element.data && !!element.data.length ? (
                <div key={ind}>
                  {!keyword ? (
                    <li className={classes.heading}>{element.title}</li>
                  ) : null}
                  {element.data.map((info, index) => {
                    const { name, property_id, localityName, pCount, id } =
                      info;

                    const i = index + name;

                    return (
                      <div key={ind + index}>
                        <li
                          data-cy={'header-search-box-list-item'}
                          onMouseEnter={() => handleHover(i)}
                          onMouseLeave={() => handleHover(i)}
                          className={
                            hover_index === i
                              ? classes.active
                              : classes.inactive
                          }>
                          <div
                            onClick={
                              element.name === 'propertiesData'
                                ? () => {
                                    propertyDetailsClick(
                                      property_id ?? id,
                                      name.toLowerCase(),
                                      localityName,
                                      pCount
                                    );
                                    close_search();
                                  }
                                : () => {
                                    !is_sticky
                                      ? onSelectSearch(
                                          info,
                                          element?.id ?? element?.property_id
                                        )
                                      : onSelectMultipleCity(
                                          info,
                                          element?.id ?? element?.property_id
                                        );

                                    close_search();
                                  }
                            }
                            className={`${classes.listItem} row`}>
                            <span
                              className={
                                keyword
                                  ? 'col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6'
                                  : ''
                              }>
                              {name}
                            </span>
                            {keyword ? (
                              <span
                                className={`${classes.menuHeading} col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6`}>
                                {element.title}
                              </span>
                            ) : null}
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </div>
              ) : null;
            })
          )}
        </ul>
      </Paper>
    </>
  );
};

export default HomepageSearchBox;
