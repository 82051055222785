import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import HBImage from '../../packages/hbimage';
import ClassNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Skeleton from 'react-loading-skeleton';

const PREFIX = 'FallbackMobLivability';

const classes = {
  root: `${PREFIX}-root`,
  cardTop: `${PREFIX}-cardTop`,
  cardTopIcons: `${PREFIX}-cardTopIcons`,
  cardTopDetails: `${PREFIX}-cardTopDetails`,
  cardBody: `${PREFIX}-cardBody`,
  media: `${PREFIX}-media`,
  location: `${PREFIX}-location`,
  cardIndex: `${PREFIX}-cardIndex`,
  cardIndexMain: `${PREFIX}-cardIndexMain`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    borderRadius: '15px',
    border: '1px solid #D2DEEB',
    paddingBottom: '0px',
    marginRight: '15px',
    marginTop: '10px',
    boxShadow: 'none',
    '& .MuiCardContent-root': {
      padding: 0,
      '& img': {
        borderRadius: '15px',
      },
    },
  },
  [`& .${classes.cardTop}`]: {
    position: 'relative',
    '& img': {
      // borderRadius: "12px",
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: '5px',
      right: 0,
      left: 0,
      background:
        'linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 11%, rgba(0,0,0,0) 23%, rgba(0,0,0,0) 42%, rgba(0,0,0,0) 62%, rgba(0,0,0,0.6) 81%, rgba(0,0,0,0.8) 100%)',
      display: 'block',
      // borderRadius: "12px",
      zIndex: 1,
    },
  },
  [`& .${classes.cardTopIcons}`]: {
    zIndex: 2,
    position: 'absolute',
    top: '10px',
    left: '0px',
    padding: '0 10px',
  },
  [`& .${classes.cardTopDetails}`]: {
    zIndex: 2,
    position: 'absolute',
    bottom: '10px',
    left: '0px',
    padding: '5px 10px',
  },
  [`& .${classes.cardBody}`]: {
    padding: '10px',
  },
  [`& .${classes.media}`]: {
    height: '50px',
    paddingTop: '56.25%', // 16:9
  },
  [`& .${classes.location}`]: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  [`& .${classes.cardIndex}`]: {
    fontSize: '10px',
    backgroundColor: '#EE6A2A',
    borderRadius: '15px',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    padding: '0 4px',
    marginLeft: '6px',
    lineHeight: 2,
  },
  [`& .${classes.cardIndexMain}`]: {
    paddingTop: '5px',
    color: 'rgba(23, 23, 23, 0.8)',
    display: 'flex',
    '& > span': {
      alignSelf: 'flex-end',
    },
  },
});

const FallbackMobLivability = () => {


  return (
    (<Root>
      <Card className={ClassNames([classes.root, 'mob-property-card'])}>
        <CardContent>
          <div className={classes.cardTop}>
            <Skeleton
              animation="wave"
              variant="rect"
              width={320}
              height={145}
            />
            <Grid
              className={classes.cardTopIcons}
              container
              justifyContent="space-between">
              <Grid size={6}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={100}
                  height={10}
                />
              </Grid>
              <Grid size={6}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid>
                    <a href="#">
                      <img
                        width="22px"
                        height="22px"
                        src="/static/images/awesome-street-view.svg"
                        alt="Street View"
                      />
                    </a>
                  </Grid>
                  <Grid>
                    <a href="#">
                      <img
                        width="22px"
                        height="22px"
                        src="/static/images/material-play-circle-outline.svg"
                        alt="Video"
                      />
                    </a>
                  </Grid>
                  {/* <Grid item>
                    <img
                      width="22px"
                      height="22px"
                      src="/static/images/ionic-ios-heart-empty.svg"
                      alt="Favourite"
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.cardTopDetails}
              justifyContent="space-between">
              <Grid size={9}></Grid>
              <Grid style={{ textAlign: 'right', paddingRight: '10px' }} size={3}>
                <IconButton
                  edge="end"
                  aria-label="menu"
                  style={{
                    backgroundColor: '#F3F3FF',
                    borderRadius: '5px',
                    padding: '5px 15px',
                  }}>
                  <HBImage
                    src="/static/images/mobile/zocial-call.svg"
                    height="15px"
                    width="15px"
                    title="Hamburger Icon"
                    style={{ position: 'relative', objectFit: 'cover' }}
                    alt="zocial-call"
                  />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <div className={classes.cardBody}>
            <Grid container justifyContent="space-between">
              <Grid size={12}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={100}
                  height={10}
                />
              </Grid>
              <Grid size={6}>
                <Grid>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={100}
                    height={10}
                  />
                </Grid>
              </Grid>
              <Grid style={{ textAlign: 'right' }} size={6}>
                <Grid></Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-start">
              <Grid className={classes.location}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={100}
                  height={10}
                />
              </Grid>
            </Grid>
            <Grid
              style={{ marginBottom: '10px' }}
              container
              justifyContent="space-between">
              <Grid className={classes.cardIndexMain}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={100}
                  height={10}
                />
              </Grid>
              <Grid>
                <Grid className={classes.cardIndexMain}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={100}
                    height={10}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </Root>)
  );
};

export default FallbackMobLivability;
