import { React, useState } from 'react';
import { styled } from '@mui/material/styles';
import ClassNames from 'classnames';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { RESET_FORGET_PASSWORD } from '../../../../config/EndpointHelper';
import { API_ROOT } from '../../../../config';
import { useForm } from '../../../../components/Desktop/Common/useForm';
import { headers } from '../../../../config/HeaderHelper';
import DataHelper from '../../../../config/DataHelper';
import axios from 'axios';
import CommonHeaderText from '../../../packages/common-header-text';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PREFIX = 'ResetPassword';

const classes = {
  root: `${PREFIX}-root`,
  textInput: `${PREFIX}-textInput`,
  formSubmit: `${PREFIX}-formSubmit`,
  formSignupText: `${PREFIX}-formSignupText`,
  tabSwitch: `${PREFIX}-tabSwitch`,
  tabRoot: `${PREFIX}-tabRoot`,
  countrySelect: `${PREFIX}-countrySelect`,
  phoneInput: `${PREFIX}-phoneInput`,
  socialMuiButton: `${PREFIX}-socialMuiButton`,
  loginFormpd: `${PREFIX}-loginFormpd`,
  specialText: `${PREFIX}-specialText`,
  specialText1: `${PREFIX}-specialText1`,
  loadingButtonWrapper: `${PREFIX}-loadingButtonWrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  lineContainer: `${PREFIX}-lineContainer`,
  horizontalLine: `${PREFIX}-horizontalLine`,
  lineText: `${PREFIX}-lineText`,
  errorMessage: `${PREFIX}-errorMessage`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    background: '#fff',
    fontFamily: 'Open Sans, sans-serif',
    borderRadius: '10px',
  },

  [`& .${classes.textInput}`]: {
    margin: '0 0 16px 0',
    display: 'block',
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      height: '42px',
      color: '#171717',
    },
    '& .MuiOutlinedInput-input': {
      padding: '20px',
    },
    '& .MuiInputLabel-formControl': {
      top: '-5px',
    },
  },

  [`& .${classes.formSubmit}`]: {
    width: '100%',
    fontSize: '16px',
    backgroundColor: '#0056B2',
    color: '#fff',
    textTransform: 'capitalize',
    // transition: 'all 0.3s ease-in-out',
    margin: '32px 0 20px 0',
    borderRadius: '4px',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#0056B2',
      color: '#FFFFFF',
    },
  },

  [`& .${classes.formSignupText}`]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: '11px',
  },

  [`& .${classes.tabSwitch}`]: {
    border: '1px solid #999999',
    borderRadius: '30px',
    height: '45px',
    margin: '0 0 10px 0',
  },

  [`& .${classes.tabRoot}`]: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#EE6A28',
      color: '#fff',
      boxShadow: 'none',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    '& .MuiButtonBase-root': {
      border: '30px',
      backgroundColor: '#fff',
    },
  },

  [`& .${classes.countrySelect}`]: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px 0 0 10px',
    },
  },

  [`& .${classes.phoneInput}`]: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0 10px 10px 0',
    },
  },

  [`& .${classes.socialMuiButton}`]: {
    '&.MuiButton-contained': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      // backgroundColor: "rgba(186, 200, 255, 0.8)",
      boxShadow: '0px 6px 20px #0056b240 !important',
    },
  },

  [`& .${classes.loginFormpd}`]: {
    padding: '32px 0 0 0',
  },

  [`& .${classes.specialText}`]: {
    cursor: 'pointer',
    color: '#001AC0',
  },

  [`& .${classes.specialText1}`]: {
    cursor: 'pointer',
    color: '#0056B2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.loadingButtonWrapper}`]: {
    position: 'relative',
  },

  [`& .${classes.buttonProgress}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -4,
    marginLeft: -12,
  },

  [`& .${classes.lineContainer}`]: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: '1rem 0',
  },

  [`& .${classes.horizontalLine}`]: {
    height: '1px',
    width: '100%',
    backgroundColor: '#D9D9D9',
    flexGrow: 1,
    flexShrink: 1,
  },

  [`& .${classes.lineText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    fontWeight: '700',
    padding: '0 1rem',
  },

  [`& .${classes.errorMessage}`]: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontFamily: 'sans-serif',
    marginLeft: '14px',
  }
}));

const ResetPassword = props => {
  const { root_style, root_class, showLogin, token } = props;

  const [flag, setFlag] = useState(false);
  const [show_password, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const initialFValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const { values, handleInputChange } = useForm(initialFValues, false);

  const validate = () => {
    let errorMsg = '';
    if (values.newPassword.length < 8) {
      errorMsg = 'Use 8 characters or more for your password';
    } else if (values.newPassword !== values.confirmPassword) {
      errorMsg = 'Those passwords didn’t match. Try again.';
    } else if (
      !values.newPassword.match(/(?=.*[A-Za-z])(?=.*\d)(?=.*[^\w\s])^.{8,}$/)
    ) {
      errorMsg =
        'Use a mix of letters, numbers and symbols to create a stronger password';
    }
    setError(errorMsg);
    return errorMsg.length == 0;
  };

  const onPaswordReset = async e => {
    e.preventDefault();
    if (validate()) {
      let payload = {
        new_password: values.newPassword,
        new_confirm_password: values.confirmPassword,
        token: token,
      };
      await axios
        .post(API_ROOT + RESET_FORGET_PASSWORD, DataHelper(payload), {
          headers: headers,
        })
        .then(async response => {
          if (response.status == 200) {
            setFlag(true);
          } else {
            setError(response.message);
          }
        })
        .catch(resererror => {
          setError(resererror.response.data.message);
          if (resererror.response.status == 409) {
            setError(resererror.response.data.message);
          }
        });
    }
  };

  return (
    (<Root>
      <div
        className={ClassNames([classes.root, root_class])}
        style={root_style}>
        <>
          {flag == false ? (
            <Grid container style={{ padding: '48px' }}>
              <Grid size={12}>
                <CommonHeaderText
                  text={`Reset Password`}
                  variant="h5"
                  style={{
                    color: '#171717',
                    fontSize: '28px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <CommonHeaderText
                  text={
                    <span>
                      Type and confirm a secure new password for the account
                    </span>
                  }
                  variant="h5"
                  style={{
                    paddingTop: '12px',
                    color: '#171717',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <form className={classes.loginFormpd}>
                  <div
                    className={classes.textInput}
                    style={{ marginBottom: '30px' }}>
                    <TextField
                      data-cy="Password"
                      name="newPassword"
                      id="newPassword"
                      label="New Password"
                      type={show_password ? 'text' : 'password'}
                      variant="outlined"
                      value={values.newPassword}
                      onChange={handleInputChange}
                      // error={errors?.email ? true : false}
                      style={{ width: '100%' }}
                      //helperText={errors?.email}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!show_password)}
                              onMouseDown={() =>
                                setShowPassword(!show_password)
                              }>
                              {show_password ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div
                    className={classes.textInput}
                    style={{ marginBottom: '0' }}>
                    <TextField
                      data-cy="Password"
                      name="confirmPassword"
                      id="confirmPassword"
                      label="Confirm Password"
                      type={show_password ? 'text' : 'password'}
                      value={values.confirmPassword}
                      variant="outlined"
                      onChange={handleInputChange}
                      // error={errors?.email ? true : false}
                      style={{ width: '100%' }}
                      //helperText={errors?.email}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!show_password)}
                              onMouseDown={() =>
                                setShowPassword(!show_password)
                              }>
                              {show_password ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <h5
                    className="error-text"
                    style={{ marginTop: '15px', minHeight: '15px' }}>
                    {error}
                  </h5>
                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      className={classes.formSubmit}
                      variant="contained"
                      onClick={onPaswordReset}>
                      Reset Password
                    </Button>
                  </div>
                  <CommonHeaderText
                    text={
                      <span onClick={showLogin}>
                        <a className={classes.specialText1}>
                          <img
                            data-cy="arrow-round-forward"
                            width="18px"
                            height="18px"
                            src="/static/images/ionic-ios-arrow-round-backward-back.svg"
                            alt="arrow-round-backward"
                            style={{ marginRight: '13px' }}
                          />{' '}
                          I remembered the password
                        </a>{' '}
                      </span>
                    }
                    variant="h5"
                    style={{
                      color: '#171717',
                      fontSize: '14px',
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: '400',
                      textAlign: 'center',
                      padding: '3px',
                    }}
                  />
                </form>
              </Grid>
            </Grid>
          ) : (
            <Grid container style={{ padding: '94px 49px' }}>
              <Grid size={12}>
                <CommonHeaderText
                  text={`Successfully updated password`}
                  variant="h5"
                  style={{
                    color: '#171717',
                    fontSize: '28px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <CommonHeaderText
                  text={
                    <span>
                      Your password has been reset. Please use your new password
                      to sign in again
                    </span>
                  }
                  variant="h5"
                  style={{
                    paddingTop: '12px',
                    color: '#171717',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <div
                  className={classes.loadingButtonWrapper}
                  style={
                    {
                      // marginTop: '16px',
                    }
                  }>
                  <Button
                    type="submit"
                    className={classes.formSubmit}
                    variant="contained"
                    // disabled={errors.email ? true : false}
                    onClick={showLogin}>
                    Login Again
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
          {/* End request sent successfully */}
        </>
      </div>
    </Root>)
  );
};

export default ResetPassword;
