/* ########## COMPONENT IMPORTS ########## */
import Header from '@/components/packages/common-header';
import { styled } from '@mui/material/styles';
import Navbar from '@/components/packages/main-navbar';
import HeaderButton from '@/components/packages/header-button';
import CommonHeaderText from '@/components/packages/common-header-text';
import SearchInput from '@/components/packages/search-input';
import HeaderDropdown from '@/components/packages/header-dropdown';
import HeaderButtonIconText from '@/components/packages/header-button-icon-text';
import ScrollToTopButton from '@/components/packages/scroll-to-top-button';
import NavbarDropdown from '@/components/packages/navbar-dropdown';
import CommonButtonIconText from '@/components/packages/common-button-icon-text';
import MobSearchFilterCard from '@/components/Mobile/Common/MobSearchFilter';
import LoginPopover from '@/components/Desktop/Common/LoginPopover';
import WishlistPopover from '@/components/Desktop/Common/Wishlist/WishlistPopover';
import PopupDialogBox from '@/components/packages/popup-dialog-box';
import LoginAndResetPassword from '@/components/Desktop/Common/LoginAndResetPassword';
import useMaterialMediaQuery from '@/components/packages/use-material-media-query';
import useAccessToken from '@/components/packages/use-access-token';
import useUserInfo from '@/components/packages/use-user-info';
import useLoginForm from '@/components/packages/use-login-form';
import { checkBackendLogin } from '@/actions/index';
import { slugify } from '../../util';

/* ########## LIBRARY IMPORTS ########## */
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ClassNames from 'classnames';
import moment from 'moment';

/* ########## MATERIAL UI IMPORTS ########## */
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { getCities, getGlobalSearchData } from '@/redux/actions/main';

import {
  highlightedText,
  emptyAllGlobalSelected,
  searchURLType,
  getSearchPageURL,
} from '@/components/util';
import { gotoPropertyDetailsPage, onRouteChanged } from '@/components/util';
import { logoutUser } from '@/components/util';

/* ########## HOOKS IMPORTS ########## */

/* ########## DATA IMPORTS ########## */

import breakpoints from '@/data/breakpoints.json';
import { site_logo } from '@/data/siteData.json';
import { hb_site_logo_white } from '../../../data/siteData.json';
import { site_logo_title } from '@/data/siteData.json';
import menubar from '@/data/menubar.json';
import sidebar_right_data from '@/data/sidebarRightData.json';
import menu_module_types from '@/data/menuModuleTypes.json';
import module_types_home from '@/data/moduleTypesHome';
import useWindow from '@/hooks/useWindow';
import { MYACCOUNT_HOMEBAZAAR } from '@/config/index';
import styles from './styles.module.scss';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  highlight1: `${PREFIX}-highlight1`,
  inputBox: `${PREFIX}-inputBox`,
  sidebarToggleButton: `${PREFIX}-sidebarToggleButton`,
  sidebarRootClass: `${PREFIX}-sidebarRootClass`,
  searchButtonRoot: `${PREFIX}-searchButtonRoot`,
  searchButtonMobile: `${PREFIX}-searchButtonMobile`,
  searchButtonRootMobile: `${PREFIX}-searchButtonRootMobile`,
  cityDropdownRoot: `${PREFIX}-cityDropdownRoot`,
  activeModule: `${PREFIX}-activeModule`,
  mobileBanner: `${PREFIX}-mobileBanner`,
  mobileBannerSearch: `${PREFIX}-mobileBannerSearch`,
  mobileSearchBar: `${PREFIX}-mobileSearchBar`,
  tourVideo: `${PREFIX}-tourVideo`,
  designMainBox: `${PREFIX}-designMainBox`,
  designExploreMore: `${PREFIX}-designExploreMore`,
  designExploreMoreAlign: `${PREFIX}-designExploreMoreAlign`,
  designMainBoxContent: `${PREFIX}-designMainBoxContent`,
  moduleTypeStyle: `${PREFIX}-moduleTypeStyle`,
  moduleTypeButton: `${PREFIX}-moduleTypeButton`,
  WishlistBtn: `${PREFIX}-WishlistBtn`,
  sellProperty: `${PREFIX}-sellProperty`,
  verticalLine: `${PREFIX}-verticalLine`,
  postDetails: `${PREFIX}-postDetails`,
  WishlistBtnMob: `${PREFIX}-WishlistBtnMob`,
  NavLogin: `${PREFIX}-NavLogin`,
  hamburgerImg: `${PREFIX}-hamburgerImg`,
  IconText: `${PREFIX}-IconText`,
  modal_root: `${PREFIX}-modal_root`,
  triangle: `${PREFIX}-triangle`,
  sitelogo: `${PREFIX}-sitelogo`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.highlight1}`]: {
    color: '#FFFFFF',
    fontSize: '11px',
    fontWeight: '300',
  },

  [`& .${classes.inputBox}`]: {
    '&.MuiPaper-elevation1': {
      boxShadow:
        '0px 0px 1px -1px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%) !important',
    },
    btnstyle: {
      bgColor: '#fff',
      border: '1px solid #D6D6FF',
    },
    searchButton: {
      '& > .MuiButton-root': {
        boxShadow: 'none',
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
      },
    },
  },

  [`& .${classes.sidebarToggleButton}`]: {
    '&.MuiIconButton-root:hover': {
      borderRadius: 0,
    },
  },

  [`& .${classes.sidebarRootClass}`]: {
    fontFamily: 'Open Sans, sans-serif',
  },

  [`& .${classes.searchButtonRoot}`]: {
    padding: 0,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '700',
  },

  [`& .${classes.searchButtonMobile}`]: {},

  [`& .${classes.searchButtonRootMobile}`]: {
    boxShadow: 'none',
    border: 'none',
    padding: '0px',
    position: 'relative',
    top: '-5px',
    left: '12px',
  },

  [`& .${classes.cityDropdownRoot}`]: {
    textTransform: 'capitalize',
    height: '90px!important',
    paddingLeft: '0px!important',
    paddingRight: '0px!important',
    '&::after': {
      borderBottom: '2px solid #171717',
      borderRight: '2px solid #171717',
      content: '""',
      display: 'block',
      height: '8px',
      marginTop: '-5px',
      pointerEvents: 'none',
      position: 'absolute',
      right: '30px!important',
      top: '50%',
      transformOrigin: '66% 66%',
      transform: 'rotate(45deg)',
      transition: 'all 0.15s ease-in-out',
      width: '8px',
      [theme.breakpoints.down('sm')]: {
        right: '5px!important',
      },
      [theme.breakpoints.down('md')]: {
        right: '14px!important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: '47px!important',
      paddingTop: '0px',
        paddingBottom: '0px',
    },
    [theme.breakpoints.down('md')]: {
      height: '47px!important',
    },
    '& span': {
      fontSize: '22px!important',
      fontWeight: '700!important',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px!important',
      },
    },
  },

  [`& .${classes.activeModule}`]: {
    color: '#fff',
    fontWeight: '700 !Important',
    opacity: 1.0,

    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'rgb(238, 106, 41)',
      height: '6px',
      width: '7%',
      bottom: '91px',
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
      marginLeft: '-48px',
      // [theme.breakpoints.down('md')]: {
      //   width: '11%',
      //   bottom: '53px',
      // },
      [theme.breakpoints.down('sm')]: {
        width: '12%',
        bottom: '54px',
        marginLeft: '-111px',
      },
      [theme.breakpoints.down(breakpoints.sm)]: {
        width: '12%',
        bottom: '54px',
        marginLeft: '-37px',
      },
      // [theme.breakpoints.down(breakpoints.sm)]: {
      //   width: '12%',
      //   bottom: '54px',
      //   marginLeft: '-34px',
      // },
    },
  },

  [`& .${classes.mobileBanner}`]: {
    position: 'relative',
    height: '212px !important',
    backgroundColor: '#F9F9FF',
    paddingTop: '5px',
  },

  [`& .${classes.mobileBannerSearch}`]: {
    position: 'absolute',
    top: '82px',
    zIndex: 3,
    width: '100%',
  },

  [`& .${classes.mobileSearchBar}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.tourVideo}`]: {},

  [`& .${classes.designMainBox}`]: {
    background: '#fff',
    position: 'relative',
    height: '125px',
    width: '100%',
  },

  [`& .${classes.designExploreMore}`]: {
    background: '#0056B214',
    color: '#171717',
    padding: '10px',
    top: '0',
    right: '0',
    fontSize: '13px',
  },

  [`& .${classes.designExploreMoreAlign}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'Center',
    height: '18px',
  },

  [`& .${classes.designMainBoxContent}`]: {
    textAlign: 'left',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
  },

  [`& .${classes.moduleTypeStyle}`]: {
    textTransform: 'capitalize',
    '& .MuiButton-root': {
      height: '38px',
      border: '1px solid #DEDEDE',
      borderRadius: 0,
      background: '#fff',
      marginRight: '5px',
      color: '#333333',
    },
    [theme.breakpoints.down('sm')]: {
      display:'none',
    }
  },

  [`& .${classes.moduleTypeButton}`]: {
    color: '#171717',
    borderRadius: 0,
    padding: '4px 15px',
    fontFamily: 'Open Sans !important',
    fontWeight: '700!important',
    textTransform: 'capitalize',
    letterSpacing: 1,
    fontSize: '16px',
    cursor: 'pointer',
    // backgroundColor: 'rgb(238, 106, 41)',
    [theme.breakpoints.down(breakpoints.sm)]: {
      fontSize: '14px',
      marginTop: '2px',
    },
    '&:hover': {
      fontWeight: '500',
      backgroundColor: '#fff!important',
    },
  },

  [`& .${classes.WishlistBtn}`]: {
    color: '#171717!important',
    padding: '18px 0!important',
    border: '1px solid #e2e2e2!important',
    borderRadius: '0!important',
    background: '#fff!important',
    marginRight: '5px!important',
    height: '38px!important',
    maxWidth: '44px!important',
    minWidth: '44px!important',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#fff!important',
    },
  },

  [`& .${classes.sellProperty}`]: {
    '& a': {
      color: '#333333',
      fontFamily: 'Open Sans',
      fontWeight: '500',
    },

    color: '#333333',
    border: '1px solid #DEDEDE',
    borderRadius: 0,
    background: '#fff',
    marginRight: '5px',
    cursor: 'pointer',
    position: 'relative',
  },

  [`& .${classes.verticalLine}`]: {
    height: '100%',
    borderLeft: '1px solid #ddd',
    position: 'absolute',
    left: '34px',
  },

  [`& .${classes.postDetails}`]: {
    '& img': {
      marginRight: '10px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',
  },

  [`& .${classes.WishlistBtnMob}`]: {
    color: '#171717',
    padding: '22px 3px',
    marginRight: '0px',
    marginTop: '-18px',

    '&:hover': {},
  },

  [`& .${classes.NavLogin}`]: {
    color: '#171717!important',
    padding: '6px 6px!important',
    border: '1px solid #e2e2e2!important',
    borderRadius: '0!important',
    background: '#fff!important',
    marginRight: '5px!important',
    height: '38px!important',
    maxWidth: '44px!important',
    minWidth: '44px!important',
    '& .MuiSvgIcon-root': {
      marginTop: '7px!important',
    },

    '&:hover': {
      backgroundColor: '#fff!important',
    },
  },

  [`& .${classes.hamburgerImg}`]: {
    color: '#FFFFFF',
  },

  [`& .${classes.IconText}`]: {
    '&.MuiIconButton-label': {
      fontSize: '12px',
    },
  },

  [`& .${classes.modal_root}`]: {
    borderRadius: '10px',
    backgroundColor: '#f9f9ff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    padding: '10px',
  },

  [`& .${classes.triangle}`]: {
    width: '0',
    height: '0',
    border: '10px solid transparent',
    borderTop: '0',
    borderBottom: '14px solid #ffffff',
    position: 'absolute',
    right: '27px',
    top: '30px',
    zIndex: '9999',
  },

  // menutext: {
  //   display: 'flex',
  //   [theme.breakpoints.down(breakpoints.mdl)]: {
  //     display: 'none',
  //   },
  // },
  [`& .${classes.sitelogo}`]: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '97%',
    },
  }
}));

const HeaderComponent = props => {
  const {
    is_desktop,
    onSearchInputClick,
    banner,
    city,
    searchFilter,
    module_type = 'buy',
    value,
    count,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    addwishlisttrigger,
    wishlistPropertyDeletedCall,
    onCitySelect,
    show_sticky_header
  } = props;

  const {
    cities,
    global_search_data,
    popular_parts_ids = null,
    location_ids,
    sublocation_ids,
    developer_ids,
  } = props.headerApiData;
  let history = [];
  const hasWindow = useWindow();
  const [keyword, setKeyword] = useState('');
  const [is_login_open, openLoginModal, closeLoginModal] = useLoginForm();
  const [small, large] = useMaterialMediaQuery();
  const [access_token] = useAccessToken();
  const [user] = useUserInfo();
  const [isJsload, setisJsload] = useState(false);
  useEffect(() => {
    setisJsload(true);
    emptyAllGlobalSelected();
  }, []);

  const onSelectModule = moduletype => {
    const userId = user?.id;
    let sellBTNurl = null;
    if (hasWindow) {
      if (userId) {
        sellBTNurl =
          MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'properties' + '/add';
      } else {
        sellBTNurl = '/post-property';
      }
    }
    onRouteChanged(
      moduletype,
      (city?.name ?? 'mumbai').toLowerCase(),
      sellBTNurl
    );
  };

  useEffect(() => {
    if (cities.length === 0) {
      props.getCities();
    }
  }, [cities]);

  const [isJsLoad, setisJsLoad] = useState(false);
  useEffect(() => {
    setisJsLoad(true);
  }, []);

  useEffect(() => {
    if (city && isJsLoad) {
      props.getGlobalSearchData({
        selectedCity: city?.id,
        keyword: keyword,
        propertytype: module_type,
        whereNotPopularPart:
          popular_parts_ids && Object.entries(popular_parts_ids).length > 0
            ? popular_parts_ids.map(data => {
                return data.id;
              })
            : [],
        whereNotLocality:
          location_ids && Object.entries(location_ids).length > 0
            ? location_ids.map(data => {
                return data.id;
              })
            : [],
        whereNotSubLocality:
          sublocation_ids && Object.entries(sublocation_ids).length > 0
            ? sublocation_ids.map(data => {
                return data.id;
              })
            : [],
        whereNotDeveloper:
          developer_ids && Object.entries(developer_ids).length > 0
            ? developer_ids.map(data => {
                return data.id;
              })
            : [],
      });
    }
  }, [
    city?.id,
    module_type,
    keyword,
    popular_parts_ids,
    location_ids,
    sublocation_ids,
    developer_ids,
  ]);

  const [open_search_popper, setOpenSearchPopper] = useState(false);
  const [wishlistCount, setwishlistcount] = useState(0);

  const handleOpenSearchInput = (...args) => {
    if (onSearchInputClick && typeof onSearchInputClick === 'function') {
      onSearchInputClick(...args);
      return;
    }
    setOpenSearchPopper(!open_search_popper);
  };

  const handleClickAwaySearchInput = () => {
    setOpenSearchPopper(false);
  };
  const onSearchKeywordChanged = _.debounce(event => {
    setKeyword(event.target.value);
  }, 500);
  // console.log('city?.name', city?.name)
  const onSelectSearch = (data, itemType) => {
    emptyAllGlobalSelected(itemType);
    switch (itemType) {
      case 'popular_part_id':
        searchFilter.settings.popularParts = [data];
        break;
      case 'location_id':
        searchFilter.settings.locations = [data];
        break;
      case 'sublocation_id':
        searchFilter.settings.subLocations = [data];
        break;
      case 'developer_id':
        searchFilter.settings.developers = [data];
    }
    searchFilter.settings.cityId = city?.id;
    searchFilter.settings.cityName = city?.name;
    if (localStorage.getItem('search_history')) {
      history = JSON.parse(localStorage.getItem('search_history'));
      history.push({
        module_type: module_type,
        city_name: city?.name,
        date: moment().format('LL'),
        time: moment().format('LT'),
        dateTime: moment().format('lll'),
        sub_city: data ? data.name : '',
      });
      localStorage.setItem('search_history', JSON.stringify(history));
    } else {
      history.push({
        module_type: module_type,
        city_name: city?.name,
        date: moment().format('LL'),
        time: moment().format('LT'),
        dateTime: moment().format('lll'),
        sub_city: data ? data.name : '',
      });
      localStorage.setItem('search_history', JSON.stringify(history));
    }
    searchFilter.search();
  };


  useEffect(() => {
    // TO-DO : Changed the code for login issue
    let search = new URL(location.href);
    search = search.searchParams;
    let keyIterator = search.keys();
    let key = keyIterator.next();
    let map = {};
    while (key['value'] != undefined) {
      map[key['value']] = search.get(key['value']);
      key = keyIterator.next();
    }
    let queryParams = { search } && { map };

    checkBackendLogin(access_token, _.get(queryParams, 'fromadmin', null)).then(
      check_login => {
        if (check_login == null) {
          //@TO-DO : need to remove this before prod.
          logoutUser();
        }
      }
    );
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mouseOnMenuBtn, setMouseOnMenuBtn] = React.useState(false);

  const closeLoginDrawer = () => {
    if (!mouseOnMenuBtn) setAnchorEl(null);
  };
  const openDrawer = Boolean(anchorEl);

  const propertyDetailsClick = (
    property_id,
    property_name,
    localityName,
    pCount
  ) => {
    if (localStorage.getItem('search_history')) {
      history = JSON.parse(localStorage.getItem('search_history'));
      history.push({
        module_type: module_type,
        city_name: city.name,
        date: moment().format('LL'),
        time: moment().format('LT'),
        dateTime: moment().format('lll'),
        sub_city: property_name,
      });
      localStorage.setItem('search_history', JSON.stringify(history));
    } else {
      history.push({
        module_type: module_type,
        city_name: city.name,
        date: moment().format('LL'),
        time: moment().format('LT'),
        dateTime: moment().format('lll'),
        sub_city: property_name,
      });

      localStorage.setItem('search_history', JSON.stringify(history));
    }
    if (hasWindow) {
      if (pCount > 1) {
        window.open(
          getSearchPageURL(
            searchURLType.propertiesAt,
            module_type,
            property_name
          ),
          '_blank'
        );
      } else {
        gotoPropertyDetailsPage({
          module_type: module_type,
          city_name: city?.name,
          property_name: property_name,
          property_id: property_id,
          localityName: localityName,
        });
      }
    } else {
      gotoPropertyDetailsPage({
        module_type: module_type,
        city_name: city?.name,
        property_name: property_name,
        property_id: property_id,
        localityName: localityName,
      });
    }
  };

  const [anchorElwishlist, setAnchorElwishlist] = React.useState(null);
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
    setAnchorElwishlist(true);
    setTriangle(true);
  };
  const handleClose = () => {
    setTriangle(false);
    setAnchorElwishlist(null);
  };
  const showWishlistCount = countWishlist => {
    setwishlistcount(countWishlist);
  };
  const [triangle, setTriangle] = useState(false);
  const siteLogowidth = is_desktop ? '225px' : '200px';
  const siteLogoheight = is_desktop ? '35px' : '30px';
  const artHamburgerheight = is_desktop ? '35px' : '27px';
  const artHamburgerwidth = is_desktop ? '20px' : '25px';
  const logoWidth = !is_desktop ? '170px' : '200px';
  const logoHeight = !is_desktop ? '27px' : '30px';

  return (
    (<Root>
      {(open_search_popper || show_sticky_header) && small && (
        <MobSearchFilterCard
        root_class="homepagesearchfilter"
          city={city}
          selectedCities={[
            {
              id: searchFilter.settings.cityId,
              name: searchFilter.settings.cityName,
            },
          ]}
          handleOuterClickAwaySearchInput={handleClickAwaySearchInput}
          search_filter_home={true}
          module_type={module_type}
          onClick={onSearchInputClick}
        />
      )}
      {value == 'searchPage' ? (
        <Header
          className={classes.root}
          classes={ClassNames([
            'container-fluid',
            'homepage-header-mob',
            props?.rootClass,
            styles.headerIndex,
          ])}>
          <Fragment>
            <Navbar
              isJsload={isJsload}
              position="static"
              small={small}
              large={large}
              logo={
                <div>
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/">
                    <img
                      className={classes.sitelogo}
                      src={hb_site_logo_white}
                      title={site_logo_title}
                      width={logoWidth}
                      height={logoHeight}
                      alt={site_logo_title}
                    />
                  </a>
                </div>
              }
              menu_items={menubar}
              sidebar_right_icons={sidebar_right_data}
              sidebar_root_class={classes.sidebarRootClass}
              sidebar_button={
                <IconButton aria-label="menu" className={styles.icoBtn}>
                  <img
                    className="cardNotClicked"
                    style={{
                      objectFit: 'cover',
                      objectPosition: '-560px 7px',
                    }}
                    src="/static/images/artboard-img.svg"
                    width={25}
                    height={27}
                    alt="humberger"
                    title="humberger"
                  />
                </IconButton>
              }
            />
          </Fragment>
        </Header>
      ) : (
        <Header
          className={classes.root}
          classes={ClassNames([
            'container-fluid',
            'homepage-header',
            props?.rootClass,
            styles.headerIndex,
          ])}>
          <>
            <Navbar
              position="static"
              isJsload={isJsload}
              small={small}
                is_desktop={is_desktop}
                large={large}
              logo={
                <>
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="/">
                    <img
                      className={classes.sitelogo}
                      src={site_logo}
                      alt="home-bazaar-logo"
                      title={site_logo_title}
                      width={siteLogowidth}
                      height={siteLogoheight}
                    />
                  </a>
                </>
              }
              navbar_buttons={
                <Grid
                  justifyContent="center" // Add it here :)
                  container>
                  {is_desktop && (
                    <>
                      {menu_module_types?.map((data, index) => {
                        const {
                          title,
                          links,
                          classNameHeader,
                          classBuysellrentbtn,
                          image,
                          hideForSmallScrn,
                        } = data;
                        return (
                          (<Grid key={index}>
                            <NavbarDropdown
                              classNameTxt={
                                hideForSmallScrn ? classes.menutext : ''
                              }
                              root_class={classes.moduleTypeStyle}
                              image={image}
                              classBuysellrentbtn={classBuysellrentbtn}
                              classNameHeader={classNameHeader}
                              text={title}
                              color="#333333"
                              bgColor="#fff"
                              options={links}
                            />
                          </Grid>)
                        );
                      })}
                    </>
                  )}

                  {is_desktop && (
                    <Grid>
                      {!small && (
                        <div className={styles.positionR}>
                          <div
                            className={(
                              triangle && classes.triangle
                            ).toString()}></div>{' '}
                        </div>
                      )}
                      <HeaderButton
                        data-cy={'header-profile-btn'}
                        className={classes.WishlistBtn}
                        text={
                          <div className={styles.headerBtn}>
                            <span className={styles.headerSpan}>
                              {wishlistCount}
                            </span>
                            <img
                              src="/static/images/home-icons.svg"
                              alt="Post Property"
                              style={{
                                objectPosition: '-357px 1px',
                                objectFit: 'none',
                                width: '28px',
                                height: '27px',
                              }}
                            />
                          </div>
                        }
                        color="#000"
                        bgColor="#fff"
                        onMouseLeave={() => setMouseOnMenuBtn(false)}
                        onClick={handleClick}
                      />
                    </Grid>
                  )}

                  {is_desktop && (
                    <Grid item>
                      <HeaderButton
                        data-cy={'header-profile-btn'}
                        className={classes.NavLogin}
                        text={
                          access_token !== '' && access_token !== null ? (
                            <CommonHeaderText
                              text={
                                <div>
                                  {highlightedText(
                                    user?.name === undefined ? (
                                      <AccountCircleIcon />
                                    ) : (
                                      user?.name.charAt(0)
                                    )
                                  )}
                                </div>
                              }
                              variant="h4"
                              style={{
                                fontSize: '20px',
                                fontWeight: '700',
                                color: '#000',
                                fontFamily: 'Open Sans, sans-serif',
                              }}
                            />
                          ) : (
                            <AccountCircleIcon />
                          )
                        }
                        color="#000"
                        bgColor="#fff"
                        onMouseOver={
                          access_token !== '' && access_token !== null
                            ? e => {
                                setAnchorEl(e.target);
                                setMouseOnMenuBtn(true);
                              }
                            : () => {} // noop
                        }
                        onMouseLeave={() => setMouseOnMenuBtn(false)}
                        onClick={
                          access_token !== '' && access_token !== null
                            ? () => {} // noop
                            : openLoginModal
                        }
                        // options={rent_menu_links}
                        style={{
                          boxShadow: 'none',
                          textTransform: 'capitalize',
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              }
              menu_items={menubar}
              sidebar_right_icons={sidebar_right_data}
              sidebar_root_class={classes.sidebarRootClass}
              sidebar_button={
                // <IconButton
                //   aria-label="menu"
                //   style={{
                //     padding: '4px 0',
                //     color: '#000',
                //     borderRadius: 0,
                //     marginBottom: '10px',
                //   }}>
                <img
                  src="/static/images/artboard-img.svg"
                  height={artHamburgerheight}
                  width={artHamburgerwidth}
                  title="Hamburger Icon"
                  alt="Hamburger Icon"
                  style={{
                    objectPosition: is_desktop ? '-734px 5px' : '-560px 7px',
                    objectFit: 'cover',
                    cursor: 'pointer'
                    // width: '19px',
                    // height: '33px',
                  }}
                />
                // </IconButton>
              }
            />

            {is_desktop
              ? banner && (
                  <>
                    <div className={styles.homepageHeaderbg}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'center',
                        }}>
                        <Box>
                          <img
                            // src="/static/images/banner-bld.svg"
                            src="https://ik.imagekit.io/sjnshacs8/propertygallery/mobbanner-bg1.webp"
                            alt="Street View"
                            fetchpriority="high"
                            width="auto"
                            height="200px"></img>
                        </Box>
                        <Box
                          sx={{
                            display: 'block ',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <h3 className={styles.bannerMainTitle}>
                            <span className={styles.orangeTitle}>
                              Introducing{' '}
                            </span>
                            Street View
                          </h3>
                          <h2
                            className={styles.subTitle}
                            style={{ marginTop: '0px' }}>
                            Now Get Interactive 360<sup>0</sup> Experience of
                            the Neighbourhood
                          </h2>
                          <List
                            sx={{
                              padding: '0px 0px',
                            }}>
                            <ListItem
                              sx={{
                                minWidth: '0px',
                                padding: '0px',
                                lineHeight: '13px',
                              }}>
                              <ListItemAvatar
                                sx={{
                                  minWidth: '24px',
                                  marginTop: '0px',
                                }}>
                                <CheckIcon
                                  sx={{
                                    color: '#0056B2',
                                    width: '0.8em!important',
                                    height: '0.8em!important',
                                  }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                sx={{
                                  marginTop: '0px',
                                  marginBottom: '0px',
                                  span: {
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    fontFamily: 'open sans',
                                    letterSpacing: '-0.14px',
                                    color: '#171717',
                                  },
                                }}>
                                Realistic Surroundings
                              </ListItemText>
                            </ListItem>
                            <ListItem
                              sx={{
                                minWidth: '0px',
                                padding: '0px',
                                lineHeight: '13px',
                              }}>
                              <ListItemAvatar
                                sx={{
                                  minWidth: '24px',
                                  marginTop: '0px',
                                }}>
                                <CheckIcon
                                  sx={{
                                    color: '#0056B2',
                                    width: '0.8em!important',
                                    height: '0.8em!important',
                                  }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                sx={{
                                  marginTop: '-5px',
                                  marginBottom: '0px',
                                  span: {
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    fontFamily: 'open sans',
                                    letterSpacing: '-0.14px',
                                    color: '#171717',
                                  },
                                }}>
                                Key Directions Highlights
                              </ListItemText>
                            </ListItem>
                            <ListItem
                              sx={{
                                minWidth: '0px',
                                padding: '0px',
                                lineHeight: '13px',
                              }}>
                              <ListItemAvatar
                                sx={{
                                  minWidth: '24px',
                                  marginTop: '0px',
                                }}>
                                <CheckIcon
                                  sx={{
                                    color: '#0056B2',
                                    width: '0.8em!important',
                                    height: '0.8em!important',
                                  }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                sx={{
                                  marginTop: '-5px',
                                  marginBottom: '0px',
                                  span: {
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    fontFamily: 'open sans',
                                    letterSpacing: '-0.14px',
                                    color: '#171717',
                                  },
                                }}>
                                Open Space Area
                              </ListItemText>
                            </ListItem>
                          </List>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: '0%',
                          left: '50%',
                          background: '#fff',
                          padding: '0px',
                          transform: 'translate(-50%, 60%);',
                          borderRadius: '15px',
                          border: '3px solid #D3E0ED',
                          boxShadow: 'rgba(70, 80, 91, 0.2) 0px 7px 20px 0px',
                          zIndex: '1',
                          width: '100%!important',
                          maxWidth: '920px!important',
                        }}>
                        <Box
                          sx={{
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            borderBottom: '1px solid #D3E0ED  ',
                          }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid
                              size={6}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '8px!important',
                              }}>
                              {module_types_home?.map((data, index) => {
                                const { name, title } = data;
                                return (
                                  <Grid
                                    key={index}
                                    sx={{
                                      marginRight: '47px',
                                    }}>
                                    <CommonButtonIconText
                                      data-cy={'button-' + name}
                                      on_click={onSelectModule}
                                      module_type={name}
                                      text={title}
                                      className={ClassNames([
                                        classes.moduleTypeButton,
                                        'moduleTypeButton',
                                        (module_type ? module_type : 'buy') ===
                                        name
                                          ? classes.activeModule
                                          : classes.inactiveModule,
                                      ])}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                            <Grid size={6} alignItems="center">
                              <div
                                className={styles.bkgSearch}
                                style={{ textAlign: 'right', backgroundImage:" url('/static/images/bg-search.svg')" }}>
                                <a
                                  href={`${
                                    access_token
                                      ? MYACCOUNT_HOMEBAZAAR.replace(
                                          /[']/g,
                                          ''
                                        ) +
                                        'properties' +
                                        '/add'
                                      : '/post-property'
                                  }`}
                                  rel="noreferrer"
                                  target="_blank">
                                  <span> Sell </span>- Post your Property
                                </a>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          sx={{
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            height: '90px',
                          }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid 
                              size={3}
                              sx={{
                                maxWidth: '20%',
                                flexBasis: '20%',
                              }}>
                              <HeaderDropdown
                                selected={city}
                                module_type={module_type}
                                onSelectCity={onCitySelect}
                                select_items={cities}
                                width="100%"
                                height="100%"
                                root_class={classes.cityDropdownRoot}
                              />
                            </Grid>
                            <Grid
                              size={9}
                              sx={{
                                maxWidth: '79%',
                                flexBasis: '79%',
                              }}>
                              <Grid container alignItems="center">
                                <Grid
                                  sx={{
                                    marginTop: '5px!important',
                                    textAlign: 'center',
                                  }}
                                  size={1}>
                                  {/* <img src="/static/images/home-search-icon.svg"></img> */}
                                  <img
                                    src="/static/images/home-icons.svg"
                                    alt="search-home"
                                    style={{
                                      objectPosition: '-170px 0px',
                                      objectFit: 'none',
                                      width: '28px',
                                      height: '19px',
                                    }}
                                  />
                                </Grid>
                                <Grid className="search-bar-width"  size={9}>
                                  <SearchInput
                                    data-cy="search-input"
                                    is_open={open_search_popper}
                                    handleOpen={handleOpenSearchInput}
                                    handleClickAway={handleClickAwaySearchInput}
                                    onSelectSearch={onSelectSearch}
                                    propertyDetailsClick={propertyDetailsClick}
                                    handleChange={onSearchKeywordChanged}
                                    name="Keywords"
                                    placeholder="Location, Project, Society, Keywords"
                                    width="100%"
                                    height="42px"
                                    is_mobile={!is_desktop}
                                    selectedCities={[
                                      {
                                        id: searchFilter.settings.cityId,
                                        name: searchFilter.settings.cityName,
                                      },
                                    ]}
                                    homepageSearchBox={global_search_data}
                                    keyword={keyword}
                                    module_type={module_type}
                                    style={{ height:'47px', width:'100%'}}
                                  />
                                </Grid>
                                <Grid
                                  sx={{
                                    textAlign: 'right',
                                    justifyContent: 'end',
                                  }}
                                  size={2}>
                                  {isJsLoad ? (
                                    <a
                                      role="button"
                                      data-cy={'search-btn-' + module_type}
                                      onClick={onSelectSearch}
                                      className={classes.searchButton}>
                                      <HeaderButtonIconText
                                        root_class={classes.searchButtonRoot}
                                        text="Search"
                                        color="#ffffff"
                                        width="100%"
                                        height="50px"
                                        fontSize="16px"
                                        bgColor="rgb(0, 86, 178)"
                                        activeColor="rgb(0, 86, 178)"
                                        inactiveColor="rgb(0, 86, 178)"
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      legacy="true"
                                      role="button"
                                      href={`/properties-in-${slugify(
                                        city?.name.toLowerCase()
                                      )}`}
                                      // href={getSearchPageURL(
                                      //   searchURLType.search,
                                      //   module_type,
                                      //   ''
                                      // )}
                                      className={classes.searchButton}>
                                      <HeaderButtonIconText
                                        root_class={classes.searchButtonRoot}
                                        text="Search"
                                        color="#ffffff"
                                        width="100%"
                                        height="50px"
                                        fontSize="16px"
                                        bgColor="rgb(0, 86, 178)"
                                        activeColor="rgb(0, 86, 178)"
                                        inactiveColor="rgb(0, 86, 178)"
                                      />
                                    </a>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </div>
                  </>
                )
              : banner && (
                  <>
                    <div className={styles.homepageHeaderbg}>
                      <h3 className={styles.bannerMainTitle}>
                        <span className={styles.orangeTitle}>Introducing </span>
                        Street View
                      </h3>

                      <Grid container>
                        <Grid className={styles.imgData}>
                          <img
                            src="https://ik.imagekit.io/sjnshacs8/propertygallery/mobbanner-bg1.webp"
                            alt="street view"
                            fetchpriority="high"
                            height="141px"
                          />
                        </Grid>
                        <p className={styles.subTitle}>
                          Get 360<sup>0</sup> Experience of the Neighbourhood
                        </p>
                      </Grid>
                      <Box
                        className="container-fluid"
                        sx={{
                          position: 'absolute',
                          bottom: '0%',
                          left: '50%',
                          background: '#fff',
                          padding: '0px',
                          transform: 'translate(-50%, 55%);',
                          borderRadius: '15px',
                          border: '3px solid #D3E0ED',
                          boxShadow: 'rgba(70, 80, 91, 0.2) 0px 7px 20px 0px',
                          width: '90%!important',
                          zIndex: '10',
                          height:'100px',
                        }}>
                        <Box
                          sx={{
                            paddingLeft: '0px',
                            paddingRight: '20px',
                            borderBottom: '1px solid #D3E0ED  ',
                          }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '-7px!important',
                              }}
                              size={{
                                xs: 6,
                                sm: 8
                              }}>
                              {module_types_home?.map((data, index) => {
                                const { name, title } = data;
                                return (
                                  (<Grid
                                    key={index}
                                    sx={{
                                      marginRight: '10px',
                                    }}>
                                    <CommonButtonIconText
                                      data-cy={'button-' + name}
                                      on_click={onSelectModule}
                                      module_type={name}
                                      text={title}
                                      className={ClassNames([
                                        classes.moduleTypeButton,
                                        'moduleTypeButton',
                                        (module_type ? module_type : 'buy') ===
                                        name
                                          ? classes.activeModule
                                          : classes.inactiveModule,
                                      ])}
                                    />
                                  </Grid>)
                                );
                              })}
                            </Grid>
                            <Grid
                              alignItems="center"
                              size={{
                                xs: 6,
                                sm: 4
                              }}>
                              <div
                                className={styles.bkgSearch}
                                style={{ textAlign: 'right', backgroundImage: " url('/static/images/bg-search.svg')" }}>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${
                                    access_token
                                      ? MYACCOUNT_HOMEBAZAAR.replace(
                                          /[']/g,
                                          ''
                                        ) +
                                        'properties' +
                                        '/add'
                                      : '/post-property'
                                  }`}>
                                  <> Sell </>
                                </a>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          sx={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            height: '100%',
                          }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid
                              size={{
                                xs: 3,
                                sm: 2
                              }}>
                              <HeaderDropdown
                                selected={city}
                                module_type={module_type}
                                onSelectCity={onCitySelect}
                                select_items={cities}
                                width="100%"
                                height="100%"
                                root_class={classes.cityDropdownRoot}
                                // style={{ height:'47px'}}
                              />
                            </Grid>
                            {/* <Grid > */}
                            <Grid
                              container
                              alignItems="center"
                              sx={{height:'57px'}}
                              size={{
                                xs: 9,
                                sm: 10
                              }}>
                              <Grid
                                className="search-bar-width"
                                sx={{
                                  textAlign: 'center',
                                  paddingLeft: '10px',
                                }}
                                size={10}>
                                <SearchInput
                                  data-cy="search-input"
                                  is_open={open_search_popper}
                                  handleOpen={handleOpenSearchInput}
                                  handleClickAway={handleClickAwaySearchInput}
                                  onSelectSearch={onSelectSearch}
                                  propertyDetailsClick={propertyDetailsClick}
                                  handleChange={onSearchKeywordChanged}
                                  placeholder="Location, Project, Keywords"
                                  width="auto"
                                  height="auto"
                                  is_mobile={!is_desktop}
                                  selectedCities={[
                                    {
                                      id: searchFilter.settings.cityId,
                                      name: searchFilter.settings.cityName,
                                    },
                                  ]}
                                  homepageSearchBox={global_search_data}
                                  keyword={keyword}
                                  module_type={module_type}
                                  style={{ height:'47px', width:'100%', paddingLeft:'10px'}}
                                />
                              </Grid>
                              <Grid
                                sx={{
                                  marginTop: '5px!important',
                                  textAlign: 'center',
                                }}
                                size={2}>
                                {isJsLoad ? (
                                  <a
                                    role="button"
                                    data-cy={'search-btn-' + module_type}
                                    onClick={onSelectSearch}
                                    className={classes.searchButton}
                                    style={{
                                      width: '48px',
                                      height: '48px',
                                      display: 'block',
                                    }}>
                                    {/* <img src="/static/images/home-search-icon.svg"></img> */}
                                    <img
                                      src="/static/images/home-icons.svg"
                                      alt="Search button"
                                      style={{
                                        objectPosition: '-167px 12px',
                                        objectFit: 'none',
                                        width: '48px',
                                        height: '48px',
                                      }}
                                    />
                                  </a>
                                ) : (
                                  <a
                                    role="button"
                                    data-cy={'search-btn-' + module_type}
                                    // onClick={onSelectSearch}
                                    href={getSearchPageURL(
                                      searchURLType.search,
                                      module_type,
                                      ''
                                    )}
                                    className={classes.searchButton}>
                                      <img
                                      src="/static/images/home-icons.svg"
                                      alt="Search button"
                                      style={{
                                        objectPosition: '-167px 12px',
                                        objectFit: 'none',
                                        width: '48px',
                                        height: '48px',
                                      }}
                                    />
                                  </a>
                                )}
                              </Grid>
                            </Grid>
                            {/* </Grid> */}
                          </Grid>
                        </Box>
                      </Box>
                    </div>
                  </>
                )}
          </>
        </Header>
      )}
      <LoginPopover
        openDrawer={openDrawer}
        anchorEl={anchorEl}
        closeLoginDrawer={closeLoginDrawer}
        is_desktop={is_desktop}
        access_token={access_token}
        user={user}
        //@ TO-DO conadition for window undefined "added this ==(hasWindow)"
        logoutUser={() => logoutUser()}
        // logoutUser={logoutUser}
      />
      <WishlistPopover
        openDrawer={openDrawer}
        anchorEl={anchorElwishlist}
        closeLoginDrawer={handleClose}
        is_desktop={is_desktop}
        access_token={access_token}
        user={user}
        showWishlistCount={showWishlistCount}
        city={city}
        count={count}
        setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
        deletecachedpropertytrigger={deletecachedpropertytrigger}
        addwishlisttrigger={addwishlisttrigger}
        wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
      />
      <ScrollToTopButton
        activeColor="#2E72BB"
        inactiveColor="rgba(0, 0, 0, 0.6)"
        showBelow="150"
      />
      <PopupDialogBox
        root_class="custom-Login-modal"
        open={is_login_open}
        close={closeLoginModal}
        modal_content={<LoginAndResetPassword closeModel={closeLoginModal} />}
      />
    </Root>)
  );
};

const mapStateToProps = state => ({
  headerApiData: state.headerReducer,
  searchPagePropsData: state.searchPagePropsReducer,
});

const mapDispatchToProps = {
  getCities,
  getGlobalSearchData,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);

// @TO-DO == abobe all code in of sumit/homepage/performance bramch
