import React from 'react';
import Head from 'next/head';
import '../styles/globals.scss';
import { wrapper } from '../redux/store';
// import GlobalStyles from '../GlobalStyles';
import { ConnectedRouter } from 'connected-next-router';
import { resetCorruptedLocalStorage } from '../components/util';
import { UtmParamsProvider } from '../Hooks/useUtmParams';
import BaseLayout from '@/components/LayoutComponent/BaseLayout';
import { GoogleTagManager } from '@next/third-parties/google';
import {
  NEXT_PUBLIC_GTM_CODE_SHOW,
} from '../config';
import { useEffect } from "react";
import { gtmPageView } from "../lib/gtm";
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
// import '../GlobalStyles/prod.css';

const primaryTheme = createTheme();


function MyApp(props) {

  const {  Component, pageProps } = props;

  resetCorruptedLocalStorage();

  useEffect(() => {
    const props = {
      page_title: pageProps.slug || null,
    };
    gtmPageView(props);
  }, [pageProps]);


  return (
    <AppCacheProvider {...props}>
      <Head>

        <meta charSet="utf-8" />
        <link rel="shortcut icon" href="/favicon.png" type="image/png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#000000" />

        {pageProps.pageName === 'HomePage' && (
          <>
            <link
              rel="preload"
              href="https://ik.imagekit.io/sjnshacs8/propertygallery/mobbanner-bg1.webp"
              as="image"
            />
            <link
              rel="preload"
              href="https://ik.imagekit.io/sjnshacs8/propertygallery/66ac9ec0316c3_home-bazaar-logo.svg"
              as="image"
            />

            <link
              rel="preload"
              href="/static/images/home-icons.svg"
              as="image"
            />

            <link
              rel="preload"
              href="/static/images/artboard-img.svg"
              as="image"
            />

            <link
              rel="preload"
              href="/static/images/bg-search.svg"
              as="image"
            />
          </>
        )}
        {pageProps.showChatbot && (
          <link rel="stylesheet" href="../static/css/chat.module.css"></link>
        )}
      </Head>

   
        <ConnectedRouter>
          <ThemeProvider theme={primaryTheme}>
          <CssBaseline />
            <UtmParamsProvider>
              {!pageProps.restrictCommonHeader ? (
                <BaseLayout
                  city_name={pageProps.city_name}
                  city_id={pageProps.city_id}
                  moduletype={pageProps.module_type}
                  locations={pageProps.locations}>

                  <Component {...pageProps} />
                </BaseLayout>
              ) : (
                <Component {...pageProps} />
              )}
            </UtmParamsProvider>
          </ThemeProvider>
        </ConnectedRouter>
      {['yes', 'true'].includes(
        NEXT_PUBLIC_GTM_CODE_SHOW?.toLowerCase().trim()
      ) ? (
        <GoogleTagManager gtmId="GTM-KJLVH53" />
      )
        : <GoogleTagManager gtmId="GTM-NF58WQ95" />
      }
    </AppCacheProvider>
  );
}

export default wrapper.withRedux(MyApp);

