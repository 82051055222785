/* ########## COMPONENT IMPORTS ########## */
// import PropertyCardStarRatingMaterial from '@/components/packages/property-card-star-rating-material';
import MobPropertyCardStarRatingMaterial from '../mob-property-card-star-rating-material';
import useAccessToken from '@/components/packages/use-access-token';
import CommonCardContainer from '@/components/packages/common-card-container';
import SessionStorage from '@/components/Desktop/Common/Wishlist/SessionStorage';

/* ########## MATERIAL UI IMPORTS ########## */
// import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import React, { useState, useRef, useEffect } from 'react';
import ClassNames from 'classnames';
import _ from 'lodash';
import ImagesSlider from '../images-slider';
import { getPropertyDetailsURL } from '@/components/util';
import classes from './mobbestsellingprojectcard.module.scss';

const MobBestSellingProjectCard = props => {
  const {
    title,
    details,
    bhk,
    priceTag,
    Images,
    street_url,
    video_url,
    star_rating,
    property_id,
    property_name,
    scheduleTourClick,
    propertyDetailsGallery,
    module_type,
    cityName,
    wishlists,
    location,
    livability_index,
    investment_index,
    p_we_recommend_count,
    priceToshow,
    toggleWishlist,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    projectName,
    bhk_types,
    minPrice,
    maxPrice,
    developer_name,
    squarFit,
    minCarpetArea,
    furnishingStatus,
    // maxCarpetArea,
    // isResale,
  } = props;
  // const classes = useStyles();
  const propertyURL = getPropertyDetailsURL({
    module_type,
    city_name: cityName,
    property_name,
    property_id,
    location,
    bhkTypes: '',
    isResale: false,
  });
  const [access_token] = useAccessToken();
  const [wishlist_flag, setWishlistFlag] = useState(wishlists);
  const imgSlider = useRef();
  React.useEffect(() => {
    if (imgSlider.current) {
      let btnElements = imgSlider.current.querySelectorAll('button');
      btnElements.forEach(element => element.classList.add('cardNotClicked'));
    }
  }, []);
  const setWishlistFlagFunc = _.debounce(propertyid => {
    toggleWishlist(propertyid);
    setWishlistFlag(!wishlist_flag);
  });
  const saveDatainSession = (
    investment_index_save,
    cityNameSave,
    property_id_save,
    projectNameSave,
    bhk_types_save,
    developer_name_save,
    livability_index_save,
    location_save,
    module_type_save,
    images,
    priceToshowSave,
    street_url_save,
    video_url_save,
    p_we_recommend_count_save,
    sqft,
    minPrice_save,
    maxCarpetArea,
    isResale,
    maxPrice_save
  ) => {
    SessionStorage({
      investment_index_save,
      cityNameSave,
      property_id_save,
      projectNameSave,
      buildingName: '',
      bhk_types_save,
      furnishStatus: '',
      developer_name_save,
      livability_index_save,
      location_save,
      module_type_save,
      images,
      priceToshowSave,
      street_url_save,
      video_url_save,
      p_we_recommend_count_save,
      sqft,
      minPrice_save,
      maxPrice_save,
      minCarpetArea,
      furnishingStatus,
      maxCarpetArea,
      isResale,
    });
    setWishlistFlag(!wishlist_flag);
    setDeleteCachedPropertyTrigger(!deletecachedpropertytrigger);
  };
  useEffect(() => {
    if (access_token && access_token != '') {
      setWishlistFlag(wishlists);
    } else {
      setWishlistFlag(0);
      cachedProject &&
        cachedProject.map(data => {
          const { project } = data;
          project &&
            project.map(dataWishlist => {
              const { propertyId } = dataWishlist;

              if (propertyId == property_id) {
                setWishlistFlag(1);
              }
            });
        });
    }
  }, [
    wishlists,
    cachedProject,
    access_token,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    property_id,
  ]);
  const croppedImage = Images.map(imageURL => `${imageURL}`);
  return (
    (<CommonCardContainer url={propertyURL} target="_self">
      <Card
        className={ClassNames([classes.root, 'mob-property-card'])}
        // onClick={stopEventBubbling}
      >
        <CardContent>
          <div className={classes.cardBody} style={{ paddingBottom: '10px' }}>
            <Grid container justifyContent="space-between">
              {/* <a> */}
              <Grid className={classes.titleName} size={12}>
                {title}
              </Grid>
              {/* </a> */}
              <Grid className={classes.location} size={12}>
                {/* <img
                  style={{ margin: '0 2px 0 0' }}
                  src="/static/images/metro-location.svg"
                  width="12px"
                  height="12px"
                  alt="metro-location"
                /> */}
                {details}
              </Grid>
            </Grid>
          </div>
          <div ref={imgSlider} className={ClassNames(classes.cardTop)}>
            {/* <SimpleImageSlider
              ClassNames="cardNotClicked"
              style={{
                backgroundColor: 'none!important',
              }}
              navSize={28}
              navMargin={16}
              width={300}
              height={170}
              images={croppedImage}
              // showBullets={true}
              showNavs={true}
            /> */}
            <ImagesSlider
              images={croppedImage}
              projectName={property_name}
              sliderbtnClass="cardNotClicked"
              // sliderbtnClass={sliderbtnClass}
              width={300}
              height={170}
            />
            <Grid
              className={classes.cardTopIcons}
              container
              justifyContent="space-between">
              <Grid size={6}>
                <div
                  style={{
                    marginTop: '20px',
                  }}>
                  <MobPropertyCardStarRatingMaterial
                    rating={star_rating}
                    precision={0.5}
                    root_class={classes.iconFilled}
                    emptyIcon={classes.iconBorder}
                  />
                </div>
              </Grid>
              <Grid size={6}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  {street_url && street_url != '' && (
                    <Grid
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'STREET_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Street View"
                        style={{
                          objectPosition: '-344px 0px',
                          objectFit: 'cover',
                          width: '30px',
                          height: '38px',
                          position: 'relative',
                          top: '14px',
                        }}
                      />
                    </Grid>
                  )}
                  {video_url && video_url != '' && (
                    <Grid
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'VIDEO_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Video"
                        style={{
                          objectPosition: '-316px 0px',
                          objectFit: 'cover',
                          width: '20px',
                          height: '38px',
                          position: 'relative',
                          top: '14px',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      access_token
                        ? setWishlistFlagFunc(property_id)
                        : saveDatainSession(
                            investment_index,
                            cityName,
                            property_id,
                            projectName,
                            bhk_types,
                            developer_name,
                            livability_index,
                            location,
                            module_type,
                            Images,
                            priceToshow,
                            street_url,
                            video_url,
                            p_we_recommend_count,
                            squarFit,
                            minPrice,
                            maxPrice,
                            minCarpetArea,
                            furnishingStatus
                          );
                    }}>
                    {/* <img
                      width="24px"
                      height="23px"
                      src={`/static/images/${
                        wishlist_flag
                          ? 'ionic-ios-heart.svg'
                          : 'ionic-ios-heart-empty.svg'
                      }`}
                      alt="Favourite"
                    /> */}
                    <img
                      src="/static/images/home-icons.svg"
                      alt="Favourite"
                      style={{
                        objectPosition: wishlist_flag
                          ? '-281px 0px'
                          : '-252px 0px',
                        objectFit: 'cover',
                        width: '26px',
                        height: '38px',
                        position: 'relative',
                        top: '12px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.cardBody}>
            <div
              className={classes.phoneIcon}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                scheduleTourClick(property_id, property_name);
              }}>
              <img src="/static/images/home-icons.svg" alt="metro-location" />
            </div>
            <Grid
              container
              size={12}
              justifyContent="space-between"
              // onClick={e => propertyDetailsClick(property_id, property_name)}
              className={classes.cardDetails}>
              {priceTag && (
                <Grid container size={12} className={classes.flatInfo}>
                  <Grid size={1}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="rupee"
                      style={{
                        objectPosition: '-29px 12px',
                        objectFit: 'cover',
                        width: '14px',
                        height: '28px',
                        marginTop: '-11px',
                      }}
                    />
                  </Grid>
                  <Grid className={classes.flatsubhead} size={11}>
                    {priceTag}
                  </Grid>
                </Grid>
              )}
              {bhk && (
                <Grid container className={classes.flatInfo}>
                  <Grid size={2}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="bhk"
                      style={{
                        objectPosition: '0px 0px',
                        objectFit: 'cover',
                        width: '15px',
                        height: '25px',
                        marginBottom: '-10px',
                      }}
                    />
                  </Grid>
                  <Grid size={10}>
                    <span className={classes.flatsubhead}>{bhk}</span>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="flex-start"></Grid>
            <Grid
              style={{ marginBottom: '10px' }}
              container
              justifyContent="space-between">
              <Grid></Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </CommonCardContainer>)
  );
};

export default MobBestSellingProjectCard;
