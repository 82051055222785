import React, { useState, useRef, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import ClassNames from 'classnames';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CommonCardContainer from '@/components/packages/common-card-container';
// import PropertyCardStarRatingMaterial from '@/components/packages/property-card-star-rating-material';
import MobPropertyCardStarRatingMaterial from '../mob-property-card-star-rating-material';
import { Tooltip } from '@mui/material';
// import { withStyles } from '@mui/styles';
import SessionStorage from '@/components/Desktop/Common/Wishlist/SessionStorage';
import _ from 'lodash';
import useAccessToken from '../use-access-token';
import ImagesSlider from '../images-slider';
// import Variables from 'styles/variables.module.scss';
import { getPropertyDetailsURL } from '@/components/util';
// import ImagesSlider from '../images-slider/imageSliderBackup';
import { styled } from '@mui/material/styles';
import classes from './mobNewProjectCard.module.scss';

const PREFIX = 'MobResidentialProjectCard';

const custom_class = {
  tooltip: `${PREFIX}-tooltip`,
  arrow: `${PREFIX}-arrow`
};

const StyledCommonCardContainer = styled(CommonCardContainer)({
  [`& .${custom_class.tooltip}`]: {
    color: '#171717',
    backgroundColor: '#fff',
    border: '1px solid #D3E0ED',
    marginBottom: '10px',
    width: '150px',
    fontSize: '13px!important',
    padding: '10px !important',
    fontWeight: '700!important',
    fontFamily: 'Open Sans, sans-serif !important',
    marginTop: '0!important',
  },
  [`& .${custom_class.arrow}`]: {
    '&:before': {
      border: '1px solid #D3E0ED',
    },
    color: '#fff',
  },
});
const MobNewProjectCard = props => {
  const {
    title,
    details,
    bhk,
    priceTag,
    Images,
    street_url,
    video_url,
    sqft,
    star_rating,
    property_id,
    property_name,
    // propertyDetailsClick,
    scheduleTourClick,
    propertyDetailsGallery,
    module_type,
    cityName,
    wishlists,
    offer,
    dealOffer,
    location,
    livability_index,
    investment_index,
    p_we_recommend_count,
    priceToshow,
    toggleWishlist,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    projectName,
    bhk_types,
    minPrice,
    maxPrice,
    developer_name,
    squarFit,
    minCarpetArea,
    furnishingStatus,
    maxCarpetArea,
    isResale,
    cardIndex
  } = props;
  // const classes = useStyles();
  const propertyURL = getPropertyDetailsURL({
    module_type,
    city_name: cityName,
    property_name,
    property_id,
    location,
    bhkTypes: '',
    isResale: false,
  });

  const [dealPopup, setdealPopup] = useState(false);
  const [offerPopup, setOfferPopup] = useState(false);
  const dealTooltipRef = useRef();
  const [access_token] = useAccessToken();
  const [wishlist_flag, setWishlistFlag] = useState(wishlists);
  const MoreDetailTooltip = Tooltip;

  const imgSlider = useRef();
  React.useEffect(() => {
    if (imgSlider.current) {
      let btnElements = imgSlider.current.querySelectorAll('button');
      btnElements.forEach(element => element.classList.add('cardNotClicked'));
    }
  }, []);
  const setWishlistFlagFunc = _.debounce(propertyid => {
    toggleWishlist(propertyid);
    setWishlistFlag(!wishlist_flag);
  });
  const saveDatainSession = (
    investment_index_save,
    cityNameSave,
    property_id_save,
    projectNameSave,
    bhk_types_save,
    developer_name_save,
    livability_index_save,
    location_save,
    module_type_save,
    images,
    priceToshowSave,
    street_url_save,
    video_url_save,
    p_we_recommend_count_save,
    sqft_save,
    minPrice_save,
    maxPrice_save,
    minCarpetArea_save,
    furnishingStatusSave
  ) => {
    SessionStorage({
      investment_index_save,
      cityNameSave,
      property_id_save,
      projectNameSave,
      buildingName: '',
      bhk_types_save,
      furnishStatus: '',
      developer_name_save,
      livability_index_save,
      location_save,
      module_type_save,
      images,
      priceToshowSave,
      street_url_save,
      video_url_save,
      p_we_recommend_count_save,
      sqft_save,
      minPrice_save,
      maxPrice_save,
      minCarpetArea_save,
      furnishingStatusSave,
      maxCarpetArea,
      isResale,
    });
    setWishlistFlag(!wishlist_flag);
    setDeleteCachedPropertyTrigger(!deletecachedpropertytrigger);
  };
  useEffect(() => {
    if (access_token && access_token != '') {
      setWishlistFlag(wishlists);
    } else {
      setWishlistFlag(0);
      cachedProject &&
        cachedProject.map(data => {
          const { project } = data;
          project &&
            project.map(dataWishlist => {
              const { propertyId } = dataWishlist;

              if (propertyId == property_id) {
                setWishlistFlag(1);
              }
            });
        });
    }

    return;
  }, [
    wishlists,
    cachedProject,
    access_token,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    property_id,
  ]);
  const croppedImage = Images.map(imageURL => `${imageURL}`);

  return (
    (<StyledCommonCardContainer url={propertyURL} target="_self">
      <Card
        className={ClassNames([classes.root, 'mob-property-card'])}
        // onClick={e => e.stopPropagation()}
      >
        <CardContent>
          <div className={classes.newProjectCard}>
            <div
              ref={imgSlider}
              className={ClassNames([classes.cardTop, 'cardNotClicked'])}>
              <ImagesSlider
                images={croppedImage}
                projectName={property_name}
                sliderbtnClass={'cardNotClicked'}
                width={300}
                height={170}
                isFirstSearchCard={cardIndex === 0}
              />
            </div>
            <Grid
              className={classes.cardTopIcons}
              container
              justifyContent="space-between">
              <Grid
                style={{
                      marginTop: '-18px',
                      display: 'flex',
                    }}
                size={6}>
                {/* <div
                  style={{
                    marginTop: '-18px',
                    display: 'flex',
                  }}> */}
                  <MobPropertyCardStarRatingMaterial
                    rating={star_rating}
                    precision={0.5}
                    root_class={classes.iconFilled}
                    emptyIcon={classes.iconBorder}
                  />
                {/* </div> */}
              </Grid>
              <Grid size={6}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  {street_url && street_url != '' && (
                    <Grid
                      style={{
                        cursor: 'pointer',
                        marginTop: '-1px',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'STREET_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Street View"
                        style={{
                          objectPosition: '-344px 0px',
                          objectFit: 'cover',
                          width: '30px',
                          height: '38px',
                        }}
                      />
                    </Grid>
                  )}
                  {video_url && video_url != '' && (
                    <Grid
                      style={{
                        cursor: 'pointer',
                        marginTop: '-1px',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'VIDEO_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Video"
                        style={{
                          objectPosition: '-316px 0px',
                          objectFit: 'cover',
                          width: '20px',
                          height: '38px',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    style={{
                      cursor: 'pointer',
                      marginTop: '-1px',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      access_token
                        ? setWishlistFlagFunc(property_id)
                        : saveDatainSession(
                            investment_index,
                            cityName,
                            property_id,
                            projectName,
                            bhk_types,
                            developer_name,
                            livability_index,
                            location,
                            module_type,
                            Images,
                            priceToshow,
                            street_url,
                            video_url,
                            p_we_recommend_count,
                            squarFit,
                            minPrice,
                            maxPrice,
                            minCarpetArea,
                            furnishingStatus
                          );
                    }}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="Favourite"
                      style={{
                        objectPosition: wishlist_flag
                          ? '-281px 0px'
                          : '-252px 0px',
                        objectFit: 'cover',
                        width: '26px',
                        height: '38px',
                        position: 'relative',
                        top: '0px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className={classes.ProjectCard}>
              <Grid className={classes.ProjectCardBox}>
                <div className={classes.cardBody}>
                  <div
                    className={classes.phoneIcon}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      scheduleTourClick(property_id, property_name);
                    }}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="metro-location"
                      // style={{
                      //   objectPosition: '-437px 5px',
                      //   objectFit: 'cover',
                      //   width: '21px',
                      //   height: '30px',
                      // }}
                    />
                  </div>
                  <Grid container>
                    <>
                      <Grid className={classes.projectText} size={12}>
                        {title}
                        {details}
                      </Grid>
                    </>
                    {/* <Grid xs={12} item className={classes.location}>
                    
                    </Grid> */}
                    <Grid size={12}>
                    {dealOffer && (
                      <MoreDetailTooltip
                        title={dealOffer}
                        open={dealPopup}
                        arrow>
                        <Grid
                          className={classes.mobDealWeek}
                          style={{ marginRight: '6px' }}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setdealPopup(!dealPopup);
                            setTimeout(() => {
                              setdealPopup(false);
                            }, 2000);
                          }}
                          size={3}>
                          {/* <div className={classes.mobDealWeek}> */}
                          <img
                            src="/static/images/home-icons.svg"
                            alt="deal"
                            style={{
                              objectPosition: '-700px 0px',
                              objectFit: 'cover',
                              width: '68px',
                              height: '30px',
                            }}
                          />
                          {/* </div> */}
                        </Grid>
                      </MoreDetailTooltip>
                    )}
                    {offer && (
                      <MoreDetailTooltip
                        ref={dealTooltipRef}
                        title={offer}
                        open={offerPopup}
                        arrow>
                        <Grid
                          style={{ marginBottom: '3px' }}
                          className={classes.mobDealWeek}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOfferPopup(!offerPopup);
                            setTimeout(() => {
                              setOfferPopup(false);
                            }, 2000);
                          }}
                          size={3}>
                          {/* <div className={classes.mobDealWeek}> */}
                          <img
                            src="/static/images/home-icons.svg"
                            alt="offer"
                            style={{
                              objectPosition: '-631px 0px',
                              objectFit: 'cover',
                              width: '70px',
                              height: '30px',
                            }}
                          />
                          {/* </div> */}
                        </Grid>
                      </MoreDetailTooltip>
                    )}
                    </Grid>
                    <div className={classes.cardDetails}>
                      {priceTag && (
                        <Grid container className={classes.flatInfo}>
                          <img
                            src="/static/images/home-icons.svg"
                            alt="rupee"
                            className={classes.rupeeIcon}
                            // style={{
                            //   objectPosition: '-29px 12px',
                            //   objectFit: 'cover',
                            //   width: '14px',
                            //   height: '28px',
                            //   marginTop: '-11px',
                            // }}
                          />
                          {priceTag}
                        </Grid>
                      )}
                      {bhk && (
                        <Grid container size={12} className={classes.flatInfo}>
                          {/* <Grid item xs={1}> */}
                          <img
                            src="/static/images/home-icons.svg"
                            alt="bhk"
                            style={{
                              objectPosition: '0px 0px',
                              objectFit: 'cover',
                              width: '15px',
                              height: '25px',
                              marginBottom: '-5px',
                            }}
                          />
                          {/* </Grid> */}
                          {/* <Grid item xs={11} className={classes.flatsubhead}> */}
                          {bhk}
                          {/* </Grid> */}
                        </Grid>
                      )}
                      {sqft && (
                        <Grid container className={classes.flatInfo1}>
                          <img
                            src="/static/images/home-icons.svg"
                            alt="carpet-area"
                            style={{
                              objectPosition: '-48px 0px',
                              objectFit: 'cover',
                              width: '18px',
                              height: '28px',
                            }}
                          />
                          {/* <span
                            style={{ marginTop: '-10px', marginLeft: '-2px' }}> */}
                            {sqft}
                          {/* </span> */}
                        </Grid>
                      )}
                    </div>
                  </Grid>

                  <Grid
                    style={{ marginBottom: '10px' }}
                    container
                    justifyContent="space-between">
                    <Grid></Grid>
                  </Grid>
                </div>
              </Grid>
            </div>
          </div>
        </CardContent>
      </Card>
    </StyledCommonCardContainer>)
  );
};

export default MobNewProjectCard;