import React from 'react';
import Grid from '@mui/material/Grid2';
// import { makeStyles } from '@mui/styles';
import ClassNames from 'classnames';
import HeaderDropdown from '@/components/packages/header-dropdown';
import SearchInputStickyHeader from '@/components/packages/search-input-sticky-header';
import classes from './citySearch.module.scss';

// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: '0px',
//     border: '1px solid #DEDEDE',
//     background: '#fff',
//     height: '38px',
//     marginTop: '-1px',
//   },

//   searchButton: {
//     color: '#FFFFFF',
//     backgroundColor: 'transparent',
//     '& > button': {
//       boxShadow: 'none !important',
//       backgroundColor: 'transparent !important',
//       padding: '12px 2px !important',
//       marginLeft: '-10px',
//     },
//   },
//   cityDropdownRoot: {
//     textTransform: 'capitalize',
//     height: '36px!important',
//     '&::after': {
//       borderBottom: '2px solid #171717',
//       borderRight: '2px solid #171717',
//       content: '""',
//       display: 'block',
//       height: '8px',
//       marginTop: '-5px',
//       pointerEvents: 'none',
//       position: 'absolute',
//       right: '10px',
//       top: '50%',
//       transformOrigin: '66% 66%',
//       transform: 'rotate(45deg)',
//       transition: 'all 0.15s ease-in-out',
//       width: '8px',
//       [theme.breakpoints.down('sm')]: {
//         right: '10px',
//       },
//     },
//   },
//   cityDropdownInner: {
//     borderTopLeftRadius: 0,
//     borderBottomLeftRadius: 0,
//     height: '36px!important',
//     '&:before': {
//       right: '19px !important',
//     },
//   },
//   infoSection: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   info: {
//     padding: '10px',
//     '& not:(:first-child)': {
//       marginTop: '10px',
//     },
//     '& > h5': {
//       paddingLeft: '5px',
//       color: '#0056B2',
//       fontSize: '22px',
//       fontWeight: '700',
//     },
//     '& > p': {
//       color: '#363636',
//       fontSize: '16px',
//     },
//   },
//   moduleWidth: {
//     width: '100%',
//     maxWidth: '80px',
//   },
//   cityWidth: {
//     width: '100%',
//     maxWidth: '120px',
//   },
//   areaWidth: {
//     width: '100%',
//   },
// }));

const CitySearch = props => {
  const {
    root_class,
    global_selected_city,
    onSelectCity,
    onSelectSearch,
    onSearchKeywordChanged,
    global_search_data,
    global_selected_cities,
    global_selected_developers,
    cities,
    modules,
    onSelectModule,
    module_type,
    is_open,
    handleOpenSearchInput,
    handleClickAwaySearchInput,
    onSelectMultipleCity,
    handleDeleteFilter,
    page_type,
    propertyDetailsClick,
    keyword,
  } = props;

  // const classes = useStyles();

  return (<>
    <div className={ClassNames([classes.root, root_class])}>
      <Grid
        size={{
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
          xl: 12
        }}
        // Add it here :)
        container>
        <Grid
          className={classes.moduleWidth}
          size={{
            xs: 2,
            sm: 3,
            md: 2,
            lg: 2,
            xl: 2
          }}>
          <HeaderDropdown
            selected={module_type}
            onSelectCity={onSelectModule}
            select_items={modules}
            width="100%"
            root_class={classes.cityDropdownRoot}
          />
        </Grid>

        <Grid
          className={classes.cityWidth}
          size={{
            xs: 3,
            sm: 4,
            md: 3,
            lg: 3,
            xl: 3
          }}>
          <HeaderDropdown
            selected={global_selected_city}
            onSelectCity={onSelectCity}
            select_items={cities}
            // width="100%"
            root_class={classes.cityDropdownInner}
          />
        </Grid>

        <Grid
          className={classes.areaWidth}
          size={{
            xs: 7,
            sm: 5,
            md: 7,
            lg: 7,
            xl: 7
          }}>
          <SearchInputStickyHeader
            page_type={page_type}
            is_open={is_open}
            handleOpen={handleOpenSearchInput}
            handleClickAway={handleClickAwaySearchInput}
            onSelectSearch={onSelectSearch}
            handleChange={onSearchKeywordChanged}
            placeholder="Location"
            width="100%"
            style={{
              width: '100%',
              paddingLeft: '15px',
              fontSize: '12px',
              height: '36px',
            }}
            selectedCities={global_selected_cities}
            selectedDevelopers={global_selected_developers}
            homepageSearchBox={global_search_data}
            onSelectMultipleCity={onSelectMultipleCity}
            handleDelete={handleDeleteFilter}
            propertyDetailsClick={propertyDetailsClick}
            keyword={keyword}
          />
        </Grid>
      </Grid>
    </div>
  </>);
};

export default CitySearch;
