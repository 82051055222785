import React, { Fragment, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Chip from '@mui/material/Chip';
import HomepageSearchBox from '@/components/packages/homepage-search-box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ClassNames from 'classnames';
import Grid from '@mui/material/Grid2';
import styles from './searchInput.module.scss';

// const useStyles = makeStyles(() => ({
//   root: {
//     boxShadow: 'none',
//     '& > input': {
//       '&::placeholder': {
//         fontSize: '10px',
//       },
//     },
//   },
//   chipBox: {
//     display: 'flex',
//     justifyContent: 'left',
//     listStyle: 'none',
//     padding: '3px',
//     marginTop: '0px',
//     height: '35px',
//     cusror: 'pointer',
//     alignItems: 'center',

//     '& li:first-child': {
//       '& div': {
//         maxWidth: '200px',
//       },
//     },

//     '& .MuiChip-label': {
//       paddingLeft: '2px!important',
//       paddingRight: '10px!important',
//       width: 'fit-content',
//     },
//   },
//   subchip: {
//     backgroundColor: '#FFFFFF!important',
//     borderRadius: '5px!important',
//     marginLeft: '0px!important',
//     marginRight: '10px!important',
//     fontFamily: 'Open Sans, sans-serif!important',
//     fontSize: '14px!important',
//     color: '#171717!important',
//     fontWeight: '500!important',
//     height: '38px!important',
//     padding: '14px!important',
//     width: '100%!important',
//     border: '1px solid #DEDEDE!important',
//     '&:hover': {
//       backgroundColor: '#fff!important',
//     },
//     '& img': {
//       marginTop: '2px!important',
//       cursor: 'pointer!important',
//     },
//     '& .MuiChip-label': {
//       paddingLeft: '0px!important',
//       paddingRight: '0px!important',
//       marginRight: '10px!important',
//     },
//   },
//   chip: {
//     backgroundColor: '#FFFFFF!important',
//     borderRadius: '5px',
//     marginLeft: '0px',
//     marginRight: '10px',
//     fontFamily: 'Open Sans, sans-serif!important',
//     fontSize: '13px',
//     color: '#171717',
//     fontWeight: '500',
//     height: '30px',
//     padding: '10px 0px',
//     width: '100%',
//     '&:hover': {
//       backgroundColor: '#fff',
//     },
//   },
//   numofcity: {
//     border: '1px solid #C5D9ED',
//     height: '26px',
//     padding: '5px',
//     fontFamily: 'Open Sans, sans-serif!important',
//     fontSize: '14px',
//     color: '#171717',
//     fontWeight: '500',
//     borderRadius: '3px',
//     marginLeft: '5px',
//     backgroundColor: '#fff!important',
//     '& .MuiChip-label': {
//       padding: '0px!important',
//     },
//   },
//   plusButton: {
//     cursor: 'pointer',
//     backgroundColor: '#FFFFFF',
//     border: '1px solid #0056B233',
//     borderRadius: '6px',
//     marginLeft: '3px',
//     marginRight: '3px',
//     marginTop: '2px',
//   },
//   show: {
//     display: 'block',
//   },
//   hide: {
//     display: 'none',
//   },
//   searchfilter: {
//     backgroundColor: '#F2F5FC',
//     padding: '20px 17px',
//   },
//   searchfilterdev: {
//     backgroundColor: '#F2F5FC',
//     padding: '15px',
//   },
// }));

export default function SearchInputStickyHeader(props) {
  // const classes = useStyles();
  const {
    width,
    height,
    style,
    placeholder,
    homepageSearchBox,
    handleChange,
    onSelectSearch,
    selectedCities,
    selectedDevelopers,
    handleOpen,
    handleClickAway,
    is_open,
    onSelectMultipleCity,
    handleDelete,
    page_type,
    propertyDetailsClick,
    module_type,
  } = props;
  const SELECTION_LIMIT = 6;
  const hidePlusIconCondition =
    selectedCities?.length >= SELECTION_LIMIT ||
    // @TO-DO : handled the error by []
    //  selectedDevelopers?.length >= SELECTION_LIMIT ||
    (selectedDevelopers?.length >= SELECTION_LIMIT ?? '') ||
    selectedCities?.length + selectedDevelopers?.length >= SELECTION_LIMIT;
  useEffect(() => {
    return;
  }, [homepageSearchBox, is_open, selectedCities]);

  const searchFilters = selectedCities
    ? selectedCities
    : // @TO-DO : handled the error by []
      // : ''.concat(selectedDevelopers).filter(filter => filter != undefined);
      [].concat(selectedDevelopers ?? []).filter(filter => filter != undefined);

  const onFilterChipDelete = (data, dataType) => {
    if (handleDelete) handleDelete(data, dataType);
  };

  function handleImageClick(data, event) {
    if (event.target.tagName === 'IMG') {
      handleDelete(data, 'location');
      event.stopPropagation();
    }
  }
  function handleImageDeleteClick(data, event) {
    if (event.target.tagName === 'IMG') {
      handleDelete(data, 'location');
      event.stopPropagation();
    }
  }

  //dataType can be location or developer
  const renderFilterChip = (data, dataType, index) => {
    return (
      <ul className={styles.listStyle} key={index}>
        <li key={index}>
          <Chip
            data-cy={'chip'}
            label={data.name}
            deleteIcon={
              <img
                className="cardNotClicked"
                style={{
                  objectFit: 'cover',
                  objectPosition: '-46px 15px',
                }}
                src="/static/images/searchPageIco.svg"
                width={15}
                height={37}
                alt="search-icon"
                title="search-icon"
              />
            }
            onDelete={() => onFilterChipDelete(data, dataType)}
            onClick={event => {
              if (event.target.tagName == 'IMG') {
                onFilterChipDelete(data, dataType);
                event.stopPropagation();
              }
            }}
            className={styles.chip}
          />
        </li>
      </ul>
    );
  };

  return (
    (<Fragment>
      <Paper component="form" className={styles.root} square={true}>
        <div id="search-input-div" data-cy={'sticky-header-search-container'}>
          {is_open ? (
            <InputBase

              autoFocus={true}
              placeholder={placeholder}
              width={width}
              height={height}
              style={style}
              onChange={handleChange}
            />
          ) : (
            <div onClick={handleOpen} className={styles.chipBox}>
              {selectedCities && selectedCities.length > 0
                ? selectedCities.map((data, index) => {
                    return (
                      index == 0 &&
                      data.name !== '' &&
                      renderFilterChip(data, 'location', index)
                    );
                  })
                : selectedDevelopers &&
                  selectedDevelopers.length > 0 &&
                  selectedDevelopers.map((data, index) => {
                    return (
                      index == 0 &&
                      data.name !== '' &&
                      renderFilterChip(data, 'developer', index)
                    );
                  })}
              {searchFilters && searchFilters.length > 1 && (
                <ul className={styles.listStyle}>
                  <li>
                    <Chip
                      label={`+${searchFilters.length - 1}`}
                      className={styles.numofcity}
                    />
                  </li>
                </ul>
              )}
              <ul className={styles.listStyle}>
                <li
                  style={{
                    marginBottom: '-6px',
                    marginLeft: '5px',
                  }}>
                  {hidePlusIconCondition ? null : (
                    <img
                      className="cardNotClicked"
                      style={{
                        objectFit: 'cover',
                        objectPosition: '-62px 5px',
                      }}
                      src="/static/images/searchPageIco.svg"
                      width={27}
                      height={37}
                      alt="search-icon"
                      title="search-icon"
                    />
                  )}
                </li>
              </ul>
            </div>
          )}

          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}>
            <div
              className={ClassNames([is_open ? styles.show : styles.hide])}>
              <HomepageSearchBox
                selectedCities={
                  selectedCities &&
                  selectedCities.length > 0 && (
                    <Grid
                      container
                      spacing={1}
                      className={styles.searchfilter}>
                      {selectedCities.map(data => {
                        return (
                          (<Grid key={`${data.name}-${data.id}`}>
                            <Chip
                              label={data.name}
                              deleteIcon={
                                <img
                                  className="cardNotClicked"
                                  style={{
                                    objectFit: 'cover',
                                    objectPosition: '-46px 15px',
                                  }}
                                  src="/static/images/searchPageIco.svg"
                                  width={15}
                                  height={37}
                                  alt="search-icon"
                                  title="search-icon"
                                />
                              }
                              onDelete={() => handleDelete(data, 'location')}
                              onClick={event =>
                                handleImageDeleteClick(data, event)
                              }
                              className={styles.subchip}
                            />
                          </Grid>)
                        );
                      })}
                    </Grid>
                  )
                }
                selectedDevelopers={
                  selectedDevelopers &&
                  selectedDevelopers.length > 0 && (
                    <Grid
                      container
                      spacing={1}
                      className={styles.searchfilterdev}>
                      {selectedDevelopers.map(data => {
                        return (
                          (<Grid key={`${data.name}-${data.id}`}>
                            <Chip
                              style={{
                                fontFamily: 'open sans !important'
                              }}
                              label={data.name}
                              deleteIcon={
                                <img
                                  className="cardNotClicked"
                                  style={{
                                    objectFit: 'cover',
                                    objectPosition: '-46px 15px',
                                  }}
                                  src="/static/images/searchPageIco.svg"
                                  width={15}
                                  height={37}
                                  alt="search-icon"
                                  title="search-icon"
                                />
                              }
                              onDelete={() => handleDelete(data, 'location')}
                              onClick={event => handleImageClick(data, event)}
                              className={styles.subchip}
                            />
                          </Grid>)
                        );
                      })}
                    </Grid>
                  )
                }
                popularPartData={
                  hidePlusIconCondition ? [] : homepageSearchBox?.popularPart
                }
                localitiesData={
                  hidePlusIconCondition ? [] : homepageSearchBox?.localities
                }
                sublocalitiesData={
                  hidePlusIconCondition ? [] : homepageSearchBox?.sub_localities
                }
                propertiesData={homepageSearchBox?.properties}
                developersData={
                  hidePlusIconCondition ? [] : homepageSearchBox?.developers
                }
                close_search={handleClickAway}
                onSelectSearch={onSelectSearch}
                is_sticky={page_type == 'search' ? true : false}
                onSelectMultipleCity={onSelectMultipleCity}
                propertyDetailsClick={propertyDetailsClick}
                module_type={module_type}
              />
            </div>
          </ClickAwayListener>
        </div>
      </Paper>
    </Fragment>)
  );
}
