import Image from 'next/image';
import { styled } from '@mui/material/styles';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';

import clsx from 'classnames';
const PREFIX = 'MediaPreview';

const classes = {
  iframeContainer: `${PREFIX}-iframeContainer`,
  videoContainer: `${PREFIX}-videoContainer`,
  overlay: `${PREFIX}-overlay`,
};

const Root = styled('div')(() => ({
  [`& .${classes.iframeContainer}`]: {
    border: '1px solid #ccc',
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    '& iframe': {
      position: 'absolute',
      border: 'none',
      borderRadius: 'inherit',
      zIndex: '1',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },

  [`&.${classes.videoContainer}`]: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #ccc',
    position: 'relative',
    '& video': {
      borderRadius: 'inherit',
      objectFit: 'cover',
      width: '100%',
      height: 'auto',
    },
  },

  [`& .${classes.overlay}`]: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'inherit',
    position: 'absolute',
    zIndex: '2',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#000A',
    color: '#aaa',

    '& .icon': {
      fontSize: '2.5rem',
    },
  },
}));

export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_VIDEO = 'video';
export const MEDIA_TYPE_360 = '360';
export const MSG_MEDIA_NOT_SUPPORTED = 'media not supported';

function MediaPreview({ type, url, ...restProps }) {
  const imageLoader = ({ src, width, height }) => {
    const queryValue = [];
    if (width) queryValue.push(`w-${width}`);
    if (height) queryValue.push(`h-${height}`);
    return queryValue.length === 0 ? src : `${src}?tr=${queryValue.join(',')}`;
  };

  switch (type) {
    case MEDIA_TYPE_IMAGE:
      return (
        <Image
          id={restProps.id}
          className={restProps.className}
          src={url}
          layout={restProps.layout}
          height={parseInt(restProps.height)}
          width={parseInt(restProps.width)}
          alt={restProps.alt}
          title={restProps.title}
          unoptimized={restProps.unoptimized}
          loader={imageLoader}
          onClick={restProps.onClick}
          objectFit="scale-down"
        />
      );
    case MEDIA_TYPE_VIDEO:
      return (
        <Root
          id={restProps.id}
          className={clsx(classes.videoContainer, restProps.className)}
          style={restProps.style}>
          <video
            title={restProps.title}
            preload="metadata"
            loop={restProps.loop}
            poster={restProps.poster}
            controls={restProps.controls}
            autoPlay={restProps.autoPlay}
            onClick={restProps.onClick}>
            <source src={url} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
          {restProps.overlay && (
            <span
              onClick={restProps.onOverlayClick}
              className={classes.overlay}>
              <PlayCircleOutline className="icon" />
            </span>
          )}
        </Root>
      );
    case MEDIA_TYPE_360:
      return (
        <Root>
          <div
            id={restProps.id}
            className={clsx(classes.iframeContainer, restProps.className)}
            style={restProps.style}>
            <iframe
              src={url}
              title={restProps.title}
              height={restProps.height}
              width={restProps.width}
              onClick={restProps.onClick}></iframe>
            {restProps.overlay && (
              <span
                onClick={restProps.onOverlayClick}
                className={classes.overlay}>
                <PlayCircleOutline className="icon" />
              </span>
            )}
          </div>
        </Root>
      );
    default:
      // UNKNOWN MEDIA TYPE
      throw new Error(
        `${MSG_MEDIA_NOT_SUPPORTED}: ${type}. Must be one of ${KNOWN_MEDIA_TYPES.join(
          '|'
        )}`
      );
  }
}
export const KNOWN_MEDIA_TYPES = [
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_360,
];
export default MediaPreview;
