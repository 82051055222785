import React from 'react';
import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid2';
import ClassNames from 'classnames';

const PREFIX = 'HBTabRadioButton';

const classes = {
  root: `${PREFIX}-root`,
  switchGridLeft: `${PREFIX}-switchGridLeft`,
  switchGridRight: `${PREFIX}-switchGridRight`,
  selectedRadio: `${PREFIX}-selectedRadio`,
  selectedRadio1: `${PREFIX}-selectedRadio1`,
  selectedRadio2: `${PREFIX}-selectedRadio2`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    '.Mui-focused': {
      backgroundColor: '#000',
    },

    input: {
      '& :selected': {
        backgroundColor: '#fff!important',
      },
    },
  },

  [`& .${classes.switchGridLeft}`]: {
    borderRadius: '10px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: '#EBEBEB',
    textAlign: 'center',
    padding: '8px',
    margin: '10px 0 20px 0',
    cursor: 'pointer',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700',
  },

  [`& .${classes.switchGridRight}`]: {
    borderRadius: '10px',
    backgroundColor: '#EBEBEB',
    textAlign: 'center',
    padding: '8px',
    cursor: 'pointer',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700',
  },

  [`& .${classes.selectedRadio}`]: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #99A2AC',
    color: '#171717',
    borderRadius: '7px',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1',
  },

  [`& .${classes.selectedRadio1}`]: {
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    border: '1px solid #99A2AC',
    padding: '8px',
    margin: '10px 0 20px 0',
    cursor: 'pointer',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  },

  [`& .${classes.selectedRadio2}`]: {
    backgroundColor: 'grey',
    textAlign: 'center',
    padding: '8px',
    margin: '10px 0 20px 0',
    cursor: 'pointer',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '700',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  }
}));

export default function HBTapRadioButtons(props) {

  const { data, onClick, defaultValue } = props;

  const [selected, setSelected] = React.useState(defaultValue || '');

  const onClickHandler = name => {
    setSelected(name);

    if (onClick) onClick(name);
  };

  return (
    (<Root>
      <div
        style={{
          marginTop: '-10px',
          marginBottom: '-20px',
        }}>
        <Grid
          container
          justifyContent="flex-start"
          alignitems="center"
          sx={{
            backgroundColor: '#EBEBEB',
            height: '65px',
            marginTop: '18px',
            marginBottom: '28px',
            borderRadius: '10px',
          }}>
          {data.map(itemData => {
            const { name, image, styles } = itemData;
            return (
              <Grid size={6}>
                <div
                  onClick={() => onClickHandler(name)}
                  key={name}
                  className={ClassNames([
                    classes.switchGridRight,
                    selected === name ? classes.selectedRadio : '',
                  ])}>
                  <img src={image} alt="image" style={styles}></img>
                  <span
                    style={{
                      color: '#171717',
                      fontSize: '16px',
                      fontFamily: 'Open Sans',
                      marginLeft: '10px',
                      fontWeight: '700',
                    }}>
                    {name}
                  </span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Root>)
  );
}
