
import dynamic from 'next/dynamic';
import { styled } from '@mui/material/styles';
const PropertyCardStarRating = dynamic(() => import('../../packages/property-card-star-rating'), { ssr: false });
import Grid from '@mui/material/Grid2';
import CommonHeaderText from '../../packages/common-header-text';
import ClassNames from 'classnames';
import Variables from 'styles/variables.module.scss';

const PREFIX = 'MenuItemViewTestimonials';

const classes = {
  root: `${PREFIX}-root`,
  pkTestimonialGrid: `${PREFIX}-pkTestimonialGrid`,
  pkTestimonialGridPos: `${PREFIX}-pkTestimonialGridPos`,
  heading: `${PREFIX}-heading`,
  headingImg: `${PREFIX}-headingImg`,
  borderRight: `${PREFIX}-borderRight`,
  testimonialTitle: `${PREFIX}-testimonialTitle`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'block',
    position: 'relative',
    border: '1px solid rgba(232, 232, 232, 0.7)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    textAlign: 'center',
    borderRadius: '4px',
    padding: '0px',
    // boxShadow: "4px 4px 7px #0056b217",
    // cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      // boxShadow: "0 10px 35px rgb(0 86 179 / 20%)",
      border: '1px solid rgb(53 94 150 / 18%)',
    },
  },

  [`& .${classes.pkTestimonialGrid}`]: {
    textAlign: 'center',
    position: 'relative',
  },

  [`& .${classes.pkTestimonialGridPos}`]: {
    position: 'relative',
    textAlign: 'center',
    bottom: '-10%',
    left: '0%',
    marginRight: '15px',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      marginRight: '4px',
    },
    '& img': {
      margin: '0 auto',
      backgroundColor: '#ffffff',
      borderRadius: '50%',
      // height: '60px !important',
    },
  },

  [`& .${classes.heading}`]: {
    overflow: 'hidden',
    color: Variables.darkColor,
    whiteSpace: 'break-spaces',
    backgroundImage: 'url(../static/images/quoteimg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '1px 4px',
    paddingLeft: '45px',
    fontSize: '13px',
    textAlign: 'justify',
    lineHeight: '24px !important',
    paddingRight: '2px',
    height: '100px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      height: '123px',
      paddingLeft: '37px',
      lineHeight: '20px !important',
    },
  },

  [`& .${classes.headingImg}`]: {
    '& img': {
      objectPosition: '-768px 1px',
      objectFit: 'cover',
      width: '33px',
      height: '30px',
    },
  },

  [`& .${classes.borderRight}`]: {
    borderRight: '5px solid #0056B2',
    height: '65%',
    marginTop: '2px',
    marginRight: '10px',
  },

  [`& .${classes.testimonialTitle}`]: {
    '& h4': {
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: '500',
      lineHeight: '1.8',
      fontSize: '16px',
      color: Variables.darkColor,
    },
  }
}));

export default function MenuItemViewTestimonials({ data }) {


  return (
    (<Root>
      <Grid
        className={ClassNames([classes.root, 'testimonial-card'])}
        data-cy="testimonial-card">
        {/* <Grid
          spacing={2}
          data-cy="testimonial-inner-card"
          container
          justifyContent="flex-start"
          className="testimonialInnerCard"> */}
        {/* <Grid item className={classes.heading}> */}
        <Grid container justifyContent="flex-start" className={classes.heading}>
          {/* <Grid
              item
              xs={1}
              style={{ textAlign: 'right' }}
              className={classes.headingImg}>
              <img src="/static/images/home-icons.svg" alt="quote" />
              <div className={classes.borderRight}></div>
            </Grid> */}
          {/* <Grid className="desc">  */}
          {data?.description}
          {/* </Grid> */}
        </Grid>
        {/* </Grid> */}
        {/* </Grid> */}
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className="PktestimonialContainer">
          <Grid className={classes.pkTestimonialGridPos}>
            {/* <HBImage
                width="90px"
                height="90px"
                alt={data?.name}
                placeholder={'blur'}
                src={
                  `https://d3of4uxmw6t77n.cloudfront.net/admin/public/testimonials/` +
                  data?.image
                }
                className="img-responsive"
              /> */}
            <img
              width="60px"
              height="60px"
              alt={data?.name}
              placeholder={'blur'}
              src={
                `https://d3of4uxmw6t77n.cloudfront.net/admin/public/testimonials/` +
                data?.image
              }
              className="img-responsive"
            />
          </Grid>
          <Grid className={classes.testimonialTitle} style={{ textAlign: 'left' }}>
            <CommonHeaderText text={data?.name} variant="h4" />
            <PropertyCardStarRating
              rating={data?.rating}
              activeColor="#0056B2"
              numberOfStars={5}
              name="rating"
              size="15px"
            />
          </Grid>
        </Grid>
      </Grid>
    </Root>)
  );
}
